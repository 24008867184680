
import CounterUp from "./CounterUp"
export default function Statistic5_2() {
    return (
        <>


            <div id="statistic-5" className="pt-50 statistic-section division">
                <div className="container">
                    {/* STATISTIC-1 WRAPPER */}
                    <div className="statistic-5-wrapper">
                        <div className="row row-cols-1 row-cols-md-4">
                            {/* STATISTIC BLOCK #1 */}
                            <div className="col">
                                <div id="sb-5-1" className="wow fadeInUp">
                                    <div className="statistic-block">
                                        {/* Digit */}
                                        <div className="statistic-digit">
                                            <h2 className="s-44 w-700">
                                                ~<CounterUp end={75} /><span style={{color:'#1680FB'}}>% </span>
                                                {/* <CounterUp end={62} />k */}
                                            </h2>
                                        </div>
                                        {/* Text */}
                                        <div className="statistic-txt">
                                            <h5 className="s-19 w-700">Pick Up Food</h5>
                                            <p>Steering clear of the food delivery apps!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>	{/* END STATISTIC BLOCK #1 */}
                            {/* STATISTIC BLOCK #2 */}
                            <div className="col">
                                <div id="sb-5-2" className="wow fadeInUp">
                                    <div className="statistic-block">
                                        {/* Digit */}
                                        <div className="statistic-digit">
                                            <h2 className="s-44 w-700">
                                                &gt; <CounterUp end={90} /><span style={{color:'#1680FB'}}>% </span>
                                            </h2>
                                        </div>


                                        <div className="statistic-txt">
                                            <h5 className="s-19 w-700">Delight in Consistency</h5>
                                            <p>Eat from same  top 8 favorite restaurants!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>	{/* END STATISTIC BLOCK #2 */}
                            {/* STATISTIC BLOCK #3 */}
                            <div className="col">
                                <div id="sb-5-2" className="wow fadeInUp">
                                    <div className="statistic-block">
                                        {/* Digit */}
                                        <div className="statistic-digit">
                                            <h2 className="s-44 w-700">
                                                &gt;<CounterUp end={30} /><span style={{color:'#1680FB'}}>% </span>
                                                {/* <CounterUp end={87} />/5 */}
                                            </h2>
                                        </div>
                                        {/* Text */}
                                        <div className="statistic-txt">
                                            <h5 className="s-19 w-700">Extra Charges</h5>
                                            <p>By food delivery apps</p>
                                        </div>
                                    </div>
                                </div>
                            </div>	{/* END STATISTIC BLOCK #3 */}

                            <div className="col">
                                <div id="sb-5-4" className="wow fadeInUp">
                                    <div className="statistic-block">
                                        {/* Digit */}
                                        <div className="statistic-digit">
                                            <h2 className="s-44 w-700">
                                                &gt;<CounterUp end={28} /><span style={{color:'#1680FB'}}>% </span>
                                                {/* <CounterUp end={87} />/5 */}
                                            </h2>
                                        </div>
                                        {/* Text */}
                                        <div className="statistic-txt">
                                            <h5 className="s-19 w-700">Not Informed</h5>
                                            <p>Of hidden extra charges</p>
                                        </div>
                                    </div>
                                </div>
                            </div>	{/* END STATISTIC BLOCK #3 */}
                        </div>  {/* End row */}
                    </div>	{/* END STATISTIC-5 WRAPPER */}
                </div>	 {/* End container */}
            </div>
        </>
    )
}
