
import React from 'react';
import './ContactUs.css';
// import Footer from './footer';
// import WebsiteHeader from '../Navbars/WebsiteNavBar'
import { CssBaseline, Paper } from '@material-ui/core';
import { TextField } from '@mui/material'
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import { } from '@material-ui/core';
import UFlag from './Images/UFlag.png';
import IFlag from './Images/IFlag.png';
import Button from "@material-ui/core/Button";
//import img1 from '../../assets/img/contact-us/contact-us-img1.png';
//import { event } from 'jquery';
import { useState, useEffect } from 'react';
//import { ErrorSharp } from '@material-ui/icons';
//import { color } from '@mui/system';
//import { useForm } from "react-hook-form";
import Axios from 'axios';
import Header from './Header';
import { Container, Row, Col } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
    head: {
    },
    '& .MuiOutlinedInput-multiline': {
        padding: '0 !important'
    },
    footer: {
        backgroundColor: '#212121 !important',
    },
    imgOne: {
        width: '110%',
        margin: '2vh auto',
    }
}))

const ContactUs = () => {
    const classes = useStyles();

    const [formError, setFormError] = useState({});
    const [msg, setmsg] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);



    const Url = "https://zi42w30094.execute-api.us-west-2.amazonaws.com/prod/sendbest8eatscontactinfotosupport";


    const [formValue, setformValue] = useState({
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        phone: "",
        websiteURL: "",
        subject: "",
        Message: ""
    });

    function handleValidation(e) {
        const { id, value } = e.target;
        let newValue = value;
    
        // If the field is phone, only allow numeric input
        if (id === 'phone') {
            // Remove any non-numeric characters
            newValue = value.replace(/\D/g, '');
            newValue = newValue.slice(0, 12);

        }
    
        // Update the form value with the new data
        setformValue(prevState => ({
            ...prevState,
            [id]: newValue
        }));
    }
    

    /* const handleValidation=(e)=>{
         const {name,value}=e.target;
         setFormValue({...formValue,[name]:value});
     }*/


    const handleSubmit = (e) => {

        e.preventDefault();


        let x = document.forms["contactForm"]["firstName"].value;
        if (x == "") {

            setmsg("Details must be filled inorder to submit the form");

            return false;
        }
        else {


            Axios.post(Url, {
                firstName: formValue.firstName,
                lastName: formValue.lastName,
                email: formValue.email,
                companyName: formValue.companyName,
                phone: formValue.phone,
                websiteURL: formValue.websiteURL,
                subject: formValue.subject,
                Message: formValue.Message
            })
                .then(res => {
                    console.log("VALUE:", res.value);
                }
                )
        }


        setFormError(validationForm(formValue));
        setIsSubmit(true);


    };

    useEffect(() => {
        console.log(formError);

        if (Object.keys(formError).length === 0 && isSubmit) {
            console.log(formValue);
        }

    }, [formError]);




    const validationForm = (value) => {
        const error = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!value.firstName) {
            error.firstName = "FirstName is required ";
        }
        if (!value.lastName) {
            error.lastName = "LastName is required ";
        }
        if (!value.email) {
            error.email = "Email is required ";
        }
        else if (!regex.test(value.email)) {
            error.email = "this is not a valid email format";
        }
        if (!value.subject) {
            error.subject = "Subject is required ";
        }
        if (!value.Message) {
            error.Message = "Message is required ";
        }



        return error;
    }




    return (
        <div >
            <CssBaseline />
            {/* <WebsiteHeader /> */}
            {/* <div style={{backgroundImage:`url(${img1})`,height:'800px',backgroundSize:"contain",backgroundRepeat:'repeat'}}> */}
            <div>
                <Header />

                <Container className={classes.head}>

                    <Grid
                        container
                        justify="space-evenly"
                        style={{ marginTop: '10px', marginBottom: '50px', position: 'relative' }}
                    >
                        <Grid items xs={12} sm={12} md={6}>
                            {/* <Paper elevation={3} style={{padding: '36px'}}> */}
                            <Typography className="MuiTypography-root MuiTypography-h4 " variant="h4" component="h4" style={{ textAlign: 'center', color: 'black', fontWeight: '700' }}>
                                Contact Us
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '22px', textAlign: "justify" }}>
                                Always humans, never bots. The hands-down, sharpest and friendliest support team in the restaurant industry. Contact us and we’ll get back to you within 48 hours.
                                Whether you have a question about sales, customer support, or just how to get started, we are here for you!
                            </Typography>

                            <Typography variant="body1" style={{ textAlign: "justify" }}>
                                Please take a look at our FAQs, it provides instant answers to the most frequently asked customer questions OR Send us a message :).
                            </Typography>
                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '2vh', width: '91%', marginBottom: '2vh' }}>
                                <Grid container spacing={3} className={classes.imgOne}>
                                    <img src={require('./Images/contact.png')} alt="Contactus" />

                                </Grid>

                            </Grid>
                            {/* </Paper> */}
                        </Grid>
                        <Grid items xs={12} sm={8} md={5} spacing={1} style={{ position: 'relative' }}>
                            <Paper elevation={3}>
                                <div className="contact-us container" >

                                    <div className="et_pb_row et_pb_row_1" id="contact-us" >
                                        <div className="et_pb_column et_pb_column_3_5 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough" >
                                            <div className="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light et_had_animation">
                                                <div className="et_pb_text_inner" >
                                                    <Typography variant="h5" component="h5" style={{ paddingBottom: '25px' }}>Let’s Get in Touch</Typography>
                                                </div>
                                            </div>
                                            <div className="et_pb_module et_pb_contact_form_0 et_pb_contact_form_container clearfix et_had_animation" id="et_pb_contact_form_0" data-form_unique_num={0}>
                                                <div className="et-pb-contact-message" />
                                                <div className="et_pb_contact">


                                                    <form className="et_pb_contact_form clearfix"
                                                        name="contactForm"
                                                        onSubmit={e => handleSubmit(e)} method="post" >
                                                        <p className="et_pb_contact_field et_pb_contact_field_0 et_pb_contact_field_half" >
                                                            {/* <label className="et_pb_contact_form_label" htmlFor="et_pb_contact_fname_0">First Name*</label>
                                <input name="et_pb_contact_fname_0" className="input" id="et_pb_contact_fname_0" type="text" placeholder="First Name*"/> */}
                                                            <TextField
                                                                id="firstName"
                                                                label="First Name*"
                                                                name="firstName"
                                                                variant='outlined'
                                                                // id="firstName"

                                                                value={formValue.firstName} onChange={e => handleValidation(e)} />
                                                            <span className='textError' style={{ color: '#f44336' }}>{formError.firstName}</span>
                                                        </p>

                                                        <p className="et_pb_contact_field et_pb_contact_field_1 et_pb_contact_field_half et_pb_contact_field_last" >
                                                            {/* <label className="et_pb_contact_form_label" htmlFor="et_pb_contact_lname_0">Last Name*</label>
                                <input name="et_pb_contact_lname_0" className="input" id="et_pb_contact_lname_0" type="text" placeholder="Last Name*"/> */}
                                                            <TextField
                                                                label="Last Name*"
                                                                name="lastName"
                                                                id="lastName"

                                                                value={formValue.laststName} onChange={e => handleValidation(e)}

                                                            /><span className='textError' style={{ color: '#f44336' }}>{formError.lastName}</span> </p>
                                                        <p className="et_pb_contact_field et_pb_contact_field_5 et_pb_contact_field_last">
                                                            {/* <label className="et_pb_contact_form_label" htmlFor="et_pb_contact_email_0">Email Address*</label>
                                                        <input name="et_pb_contact_email_0" className="input" id="et_pb_contact_email_0" type="text" placeholder="Email Address*" /> */}
                                                            <TextField


                                                                // required
                                                                fullWidth
                                                                id="email"
                                                                label="Email Address*"
                                                                name="email"
                                                                value={formValue.email} onChange={e => handleValidation(e)}

                                                            // value={email}
                                                            // onChange={event => setEmail(event.target.value)}
                                                            // error={errorState.email}
                                                            // helperText={errorText.email}
                                                            // autoComplete="email"
                                                            // autoFocus
                                                            />
                                                            <span className='textError' style={{ color: '#f44336' }}>{formError.email}</span>


                                                        </p>
                                                        <p className="et_pb_contact_field et_pb_contact_field_3 et_pb_contact_field_half" >
                                                            {/* <label className="et_pb_contact_form_label" htmlFor="et_pb_contact_companyname_0">Company
                    Name</label> <input name="et_pb_contact_companyname_0" className="input" id="et_pb_contact_companyname_0" type="text" placeholder="Company Name" /> */}
                                                            <TextField
                                                                label="Company Name"
                                                                name="companyName"
                                                                id="companyName"

                                                                value={formValue.companyName} onChange={e => handleValidation(e)}


                                                            />

                                                        </p>
                                                        <p className="et_pb_contact_field et_pb_contact_field_4 et_pb_contact_field_half et_pb_contact_field_last" >
                                                            {/* <label className="et_pb_contact_form_label" htmlFor="et_pb_contact_phone_0">Your Phone
                    Number</label> <input name="et_pb_contact_phone_0" className="input" id="et_pb_contact_phone_0" type="text" placeholder="Your Phone Number" /> */}
                                                            <TextField
                                                                label=" Phone Number"
                                                                name="phone"
                                                                id="phone"

                                                                value={formValue.phone} 
                                                                onChange={e => handleValidation(e)}
                                                              
                                                            />

                                                        </p>
                                                        <p className="et_pb_contact_field et_pb_contact_field_5 et_pb_contact_field_last">
                                                            {/* <label className="et_pb_contact_form_label" htmlFor="et_pb_contact_webisiteurl_0">Website
                    URL</label> <input name="et_pb_contact_webisiteurl_0" className="input" id="et_pb_contact_webisiteurl_0" type="text" placeholder="Website URL" /> */}
                                                            <TextField
                                                                fullWidth
                                                                label="Website URL"
                                                                name="websiteURL"
                                                                id="websiteURL"

                                                                value={formValue.websiteURL} onChange={e => handleValidation(e)}

                                                            />

                                                        </p>
                                                        <p className="et_pb_contact_field et_pb_contact_field_6 et_pb_contact_field_last" >
                                                            {/* <label className="et_pb_contact_form_label" htmlFor="et_pb_contact_subject_0">Subject*</label>
                                                        <input name="et_pb_contact_subject_0" className="input" id="et_pb_contact_subject_0" type="text" placeholder="Subject*" /> */}
                                                            <TextField
                                                                fullWidth
                                                                label="Subject*"
                                                                name="subject"
                                                                id="subject"

                                                                value={formValue.subject} onChange={e => handleValidation(e)}

                                                            />
                                                            <span className='textError' style={{ color: '#f44336' }}>{formError.subject}</span>
                                                        </p>
                                                        <p className="et_pb_contact_field et_pb_contact_field_7 et_pb_contact_field_last">
                                                            {/* <textarea name="et_pb_contact_message_0" className="et_pb_contact_message input" id="et_pb_contact_message_0" placeholder="Message*" /> */}
                                                            <TextField
                                                                name="Message"
                                                                fullWidth
                                                                id="Message"
                                                                label="Message*"
                                                                multiline
                                                                maxRows={2}

                                                                value={formValue.Message} onChange={e => handleValidation(e)}
                                                                style={{ padding: '0', backgroundColor: '#eee' }}
                                                            />
                                                            <span className='textError' style={{ color: '#f44336' }}>{formError.Message}</span>
                                                        </p>
                                                        <div className="et_contact_bottom_container" style={{ margin: "2vh 0" }}>
                                                            <div className="et_pb_contact_right">
                                                                {/* <p class="clearfix"> <span
                                                                            class="et_pb_contact_captcha_question">4 +
                                                                            2</span> = <input
                                                                            name="et_pb_contact_captcha_0"
                                                                            class="input et_pb_contact_captcha"
                                                                            type="text" size="2" value=""                                                                                                                                                
                                                                            
                                                                    </p> */}

                                                            </div>

                                                            <span id='BColor' onClick={handleSubmit} className="" style={{ minWidth: '200px', textAlign: 'center', borderRadius: '8px', cursor: 'pointer', padding: '10px 20px' }}>

                                                                Send
                                                            </span>


                                                            {Object.keys(formError).length === 0 && isSubmit ? (<div className='ui msg success' style={{ color: 'green', textAlign: 'justify', paddingLeft: '13px', marginTop: '5%', marginBottom: '5%' }} >Thank you for your email. Our support team will get back to you as soon as possible</div>) : (

                                                                <p id="MessageText" style={{ color: '#f44336', textAlign: 'justify', paddingLeft: '13px', marginTop: '5%', marginBottom: '5%' }}>{msg}</p>

                                                            )}
                                                        </div>




                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>


                    </Grid>


                </Container>
            </div>
            <div>


                <Container style={{ paddingBottom: '50px' }}>
                    <Typography variant="h4" component="h4" style={{ textAlign: 'left', marginBottom: '10px' }}>Our Offices</Typography>
                    <Row style={{ paddingTop: '4vh', marginBottom: '6vh', position: 'relative', paddingRight: '15px' }}>
                        <Col xs={12} sm={12} md={6} style={{ position: 'relative' }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} style={{ float: 'left', height: '115px', display: 'flex', flexDirection: 'row', position: 'relative', top: '6px', marginLeft: '0', marginRight: '-22px' }}>
                                    <div style={{ textAlign: 'right', marginRight: '1rem', width: '75px', height: '75px' }}>
                                        <img style={{ width: '75px', height: '75px' }} src={UFlag} alt="usLocation" />
                                    </div>
                                    <div style={{ textAlign: 'left' }} >
                                        <Typography variant="h5" component="h5">Ikabit Inc.</Typography>
                                        <Typography variant="body1">907 Embarcadero Dr, Ste #4,<br />
                                            El Dorado Hills, CA 95762, USA</Typography>
                                    </div>
                                </Col>
                                {/* <Col xs={8} sm={8} md={10} style={{ textAlign: 'left' }}>   
            
            </Col> */}
                            </Row>
                        </Col>

                        <Col xs={12} sm={12} md={6} style={{ position: 'relative' }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} style={{ float: 'left', height: '115px', display: 'flex', flexDirection: 'row', position: 'relative', top: '6px', marginLeft: '0', marginRight: '-22px' }}>
                                    <div style={{ textAlign: 'right', marginRight: '1rem', width: '75px', height: '75px' }}>
                                        <img style={{ minWidth: '75px', height: '75px' }} src={IFlag} alt="India" />
                                    </div>
                                    <div style={{ textAlign: 'left' }} >
                                        <Typography variant="h5" component="h5">Ikabit Software Systems Pvt. Ltd.</Typography>
                                        <Typography variant="body1">#455, 80 Feet Road,<br />
                                            Dr. Shivaram Karanth Nagar,<br />Bengaluru 560077, India</Typography>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}

export default ContactUs