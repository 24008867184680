// import React, { useEffect, useState } from "react";
// import { Navbar, Container, Nav, NavDropdown, Image, Offcanvas,Button } from "react-bootstrap";
// import { NavLink } from "react-router-dom"; // Import NavLink from react-router-dom
// import './App.css'


// const Header = () => {
//  const [isOpen, setIsOpen] = useState(false);

//  useEffect(() => {
//     document.body.style.overflow = isOpen ? 'hidden' : 'auto';
//  }, [isOpen]);

//  const toggle = () => {
//     setIsOpen(!isOpen);
//  };

//  const handleClose = () => {
//     setIsOpen(false);
//  };

//  return (
//     <>
//       <Navbar collapseOnSelect expand="lg"  bg="dark" data-bs-theme="dark" fixed id="NavColor" className="justify-content-between" style={{zIndex:'99999999',fontSize:'1em',background:'transparant'}}>
//         <Container>
//           <Navbar.Brand href={`${process.env.PUBLIC_URL}/`}>
//             <Image src={'./img/b8eLogo.png'} style={{ height: '70px' }} />
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={toggle} />
//           <Navbar.Collapse id="offcanvasNavbar" in={isOpen}>
//             <Nav className="me-auto">
//               <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/`}>Home</Nav.Link>
//               <NavDropdown title="Our Products" id="collapsible-nav-dropdown">
//                 <NavDropdown.Item as={NavLink} to={`${process.env.PUBLIC_URL}/restaurant-owner`}>Restaurant Owner</NavDropdown.Item>
//                 <NavDropdown.Item as={NavLink} to={`${process.env.PUBLIC_URL}/customer`}>Customer</NavDropdown.Item>
//               </NavDropdown>
//               <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/pricing`}>Pricing</Nav.Link>
//               <Nav.Link as={NavLink} to="/contact-us" >Contact Us</Nav.Link>
//             </Nav>
//             <Nav>
//               <Navbar.Text style={{ marginRight: '10px' }}> 
//                 <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/login`} >Sign In</Nav.Link>
//               </Navbar.Text>
//               <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/sign-up`} className="btn-warning" style={{padding:'15px 20px', minWidth:'150px',fontWeight: '500',borderRadius:'20px', border:'black',background:'#Feb300',color:'black' }} >Sign Up For Free</Nav.Link>
//             </Nav>
//           </Navbar.Collapse>
//           <Offcanvas show={isOpen} onHide={handleClose} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
//             <Offcanvas.Header closeButton>
//               <Image src={'./img/b8eLogo.png'} style={{ height: '50px', marginRight: '20px' }} />
//               <Offcanvas.Title id="offcanvasNavbarLabel">Best8Eats</Offcanvas.Title>
//             </Offcanvas.Header>
//             <Offcanvas.Body>
//               <Nav className="justify-content-end flex-grow-1 pe-3">
//                 <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/`}>Home</Nav.Link>
//                 <NavDropdown title="Our Products" id="collapsible-nav-dropdown">
//                  <NavDropdown.Item as={NavLink} to={`${process.env.PUBLIC_URL}/restaurant-owner`}>Restaurant Owner</NavDropdown.Item>
//                  <NavDropdown.Item as={NavLink} to={`${process.env.PUBLIC_URL}/customer`}>Customer</NavDropdown.Item>
//                 </NavDropdown>
//                 <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/pricing`}>Pricing</Nav.Link>
//                 <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/contact-us`} >Contact Us</Nav.Link>
//               </Nav>
//               <Nav>
//                 <Navbar.Text style={{ marginRight: '10px' }}>
//                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/login`}>Sign In</Nav.Link>
//                 </Navbar.Text>
//                 {/* <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/sign-up`} ></Nav.Link> */}

//                 <Button className="btn-primary"  variant="warning" style={{ minWidth:'150px',borderRadius: '20px', fontWeight: '400',padding:'20px 30px'}}>Sign Up For Free</Button>

//               </Nav>
//             </Offcanvas.Body>
//           </Offcanvas>
//         </Container>
//       </Navbar>
//     </>
//  );
// };

// export default Header;


import React from "react";
import { Navbar, Container, Nav, NavDropdown, Image, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom"; // Import NavLink from react-router-dom
import './App.css'

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark" fixed id="NavColor" className="justify-content-between" style={{ zIndex: '99999999', fontSize: '1em', background: 'transparent' }}>
      <Container>
        <Navbar.Brand href={`${process.env.PUBLIC_URL}/`}>
          <Image src={'./img/b8eLogo.png'} style={{ height: '70px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ms-auto" />
        <Navbar.Collapse id="responsive-navbar-nav" className="custom-navbar-collapse">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/`}>Home</Nav.Link>
            <NavDropdown title="Our Products" id="collapsible-nav-dropdown">
              <NavDropdown.Item as={NavLink} to={`${process.env.PUBLIC_URL}/restaurant-owner`}>Restaurant Owner</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={`${process.env.PUBLIC_URL}/customer`}>Customer</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/pricing`}>Pricing</Nav.Link>
            <Nav.Link as={NavLink} to="/contact-us" >Contact Us</Nav.Link>
          </Nav>
          <Nav>
            <Navbar.Text style={{ marginRight: '10px' }}>
              <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/login`} >Sign In</Nav.Link>
            </Navbar.Text>
            {
              window.innerWidth <= 768 ?
                (
                  <Nav.Link as={NavLink} className="btn-warning" to={`${process.env.PUBLIC_URL}/sign-up`}>
                    Sign Up For Free
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    as={NavLink}
                    to={`${process.env.PUBLIC_URL}/sign-up`}
                    className="btn-warning"
                    style={{
                      padding: '15px 20px',
                      minWidth: '150px',
                      fontWeight: '500',
                      borderRadius: '20px',
                      border: '1px solid black', // Added 'px' to the border width
                      backgroundColor: '#Feb300',
                      color: 'black'
                    }}
                  >
                    Sign Up For Free
                  </Nav.Link>
                )}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
