import { Button, Image } from 'react-bootstrap';
import './Rest.css'
import './bootstrap.min.css'
import './download.css'
import './css/responsive.css'
import './css/animate.css'
import './css/demo.css'
import './css/lunar.css'
import './css/blue-theme.css'
import Stat from './Stat';
import Features11_14 from './Features11_14';
import VideoPopup from './VidepPopupRestOwner';
import { Link, useNavigate } from 'react-router-dom';
import Statistic5_2 from './Statistic5_2';
import Features7_1 from './Features7_1';
import Ct02_21 from './Ct02_21';
import Features5_5 from './Features5_5';
import Banner3_4 from './Banner3_4';
import Header from './Header';

export  const Customer = () => {
    const navigate = useNavigate()
    const handleSvgClick1 = () => {
        // alert('Will redirect to App Store');
        navigate('/App-Store-Best8Eats')
        // Add your logic here for SVG 1 click
      };

      const handleSvgClick2 = () => {
        navigate('/Play-Store-Best8Eats')
        // Add your logic here for SVG 1 click
      };
    
    return(
        <div>

{/* gr--whitesmoke */}
{/* <section id="hero-27" className="pb-100  hero-section" style={{background:'rgb(244,244,249,1)'}}> */}
<section id="hero-27" className="pb-100   custHB" style={{height:'100vh'}}>
<Header/>

                <div className="hero-overlay bg--fixed">
                    <div className="container text-center">
                        {/* HERO TEXT */}
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-9">
                                <div className="hero-27-txt wow fadeInUp">
                                    {/* Title */}
                                    <h2 className="s-35 w-700">Welcome, Savvy Food Pickup Enthusiasts!</h2>
                                    {/* Text */}
                                    <p className="s-20">Our Customer Phone App – Your Shortcut To Time And Money Savings On Food Pickup Orders!
                                    </p>
                                    {/* Buttons */}
                                    <div className="btns-group">
                                        {/* <Link href="#banner-3" className="btn r-04 btn--theme hover--theme">Get started for free</Link>
                                        <VideoPopup style={5}/> */}

                                    <div>
                                    <img
                                        src={'./img/apple.svg'}
                                        alt="SVG 1"
                                        onClick={handleSvgClick1}
                                        style={{ cursor: 'pointer', marginRight: '20px',margin:'-50px 15px ',
                                    height:'150px' }}
                                    />
                                
                                    <img
                                        src={'./img/android.svg'}
                                        alt="SVG 2"
                                        onClick={handleSvgClick2}
                                        style={{ cursor: 'pointer',margin:'-50px 15px',
                                        height:'150px' }}
                                    />
                                    </div>      


                                    </div>
                                    {/* Advantages List */}
                                    <ul className="advantages ico-15  clearfix">
                                        <li><p>Savor the flavors from your favorite restaurants, $0 extra cost!</p></li>
                                        {/* <li className="advantages-links-divider"><p><span className="flaticon-minus" /></p></li>
                                        <li><p>Cancel at anytime</p></li>
                                        <li className="advantages-links-divider"><p><span className="flaticon-minus" /></p></li>
                                        <li><p>Free 14 days trial</p></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>	{/* END HERO TEXT */}
                    </div>	   {/* End container */}
                </div>	   {/* End hero-overlay */}
            </section>
            <div style={{margin:'0px 0 50px 0'}}>                
                    <hr className="divider" />
            </div>


            <section>
                <Features5_5/>
            </section>

            <div style={{marginTop:'100px'}}>                
                    <hr className="divider" />
            </div>

            <section>
                <Statistic5_2/>
            </section>

            {/* <div style={{marginTop:'50px 0'}}>                
                    <hr className="divider" />
            </div> */}
            {/* <div style={{margin:'50px 0'}}>                
                    <hr className="divider" />
                </div> */}

            <section style={{background:'#F6F6FA',marginTop:'4rem'}}>
                <Features7_1/>
            </section>

            {/* <div style={{margin:'50px 0'}}>                
                    <hr className="divider" />
                </div> */}

                <section>
                    <Ct02_21/>
                </section>

                <section>
                    <Banner3_4/>
                </section>


                {/* <section >
                    <div className='container text-center'>
                    <div className='row justify-content-center'>

                    <div className='footer-info'>

                            <div className='col-xl-12'>
                     <h2 className='s-38 w-900'> <Image className='footer-logo'  src={'./img/b8eLogo.png'} style={{marginRight:'15px',marginBottom:'10px',minHeight:'2.1em'}} /> BEST8EATS</h2>
                    </div>
                    </div>
                    </div> 
                    </div>
                </section> */}

             

        </div>
    )
}

