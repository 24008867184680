// App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import RestOwner from './RestOwner';
// import './css/responsive.css'
// import About from './About';
// import './bootstrap.min.css'
import { Customer } from './Customer';
import Header from './Header';
import Pricing1 from './Pricing1';
import Price from './pricing-1';
import Footer from './Footer';
import ContactUs from './ContactUs';
import SignUpComp from './Signup/SignupComp';
import PhoneNumberVerification from './phoneNumberVerfication';
import EmailVerification from './EmailVerification';
import Login from './Login';
import Welcome from './Welcome';
import HomePage from './HomePage/HomePage';
import './App.css'
import PrivacyPolicy from './PrivacyPolicy';
import ScrollToTop from './ScrollToTop';
import NotFoundPage from './NotFoundPage';
import PackageSelection from './PackageSelection';


const App = () => {
  return (
    <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/restaurant-owner" element={<RestOwner />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/pricing" element={<Price />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/sign-up" element={<SignUpComp />} />
          <Route path="/phone-number-verification" element={<PhoneNumberVerification />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/package-selection" element={<PackageSelection />} />
          {/* <Route path="/payment-and-billing" element={<PaymentAndBilling />} /> */}

          
          <Route path="/login" element={<Login />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/x" element={<HomePage />} />
          <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
    </Router>

  );
};

export default App;
