

export default function Features7_1() {
    return (
        <>
            <section id="features-7 page font--jakarta" className="pt-90 pb-100 features-section division">
                <div className="container">
                    {/* SECTION TITLE */}
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-70">
                                {/* Title */}
                                <h2 className="s-35 w-700 page font--jakarta">Exciting Features on Our Customer Phone App!</h2>
                                {/* Text */}
                                <p className="s-20 color--grey">Experience the Swift, Efficient, and Effortless Route for Your Food Orders!</p>
                            </div>
                        </div>
                    </div>
                    {/* FEATURES-7 WRAPPER */}
                    <div className="fbox-wrapper text-center">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                            {/* FEATURE BOX #1 */}
                            <div className="col">
                                <div className="fbox-7 fb-1 r-12 wow fadeInUp" style={{boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',height:'18rem'}}>
                                    {/* Icon */}
                                    <div className="fbox-ico ico-50">
                                        <div className="shape-ico color--theme">
                                            {/* Vector Icon */}
                                            {/* <span className="flaticon-prioritize" /> */}
                                            <span><img  src={require('./Images//icons/1_1.png')} style={{height:'60px',width:'60px'}} /></span>

                                            {/* Shape */}
                                            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                            </svg>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt">
                                        <h6 className="s-20 w-700">$0 Extra Charges, Always!</h6>
                                        <p className="p-sm">Savor Delightful Cuisine at Wallet-Friendly Restaurant Menu Prices!</p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #1 */}
                            {/* FEATURE BOX #2 */}
                            <div className="col">
                                <div className="fbox-7 fb-2 r-12 wow fadeInUp" style={{boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',height:'18rem'}}>
                                    {/* Icon */}
                                    <div className="fbox-ico ico-50">
                                        <div className="shape-ico color--theme">
                                            {/* Vector Icon */}
                                            {/* <span className="flaticon-click-1" /> */}
                                            <span><img  src={require('./Images/icons/2_3.png')} style={{height:'60px',width:'60px'}} /></span>

                                            {/* Shape */}
                                            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                            </svg>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt">
                                        <h6 className="s-20 w-700">1- Click Orders</h6>
                                        <p className="p-sm">Craft your flavor masterpiece once, relish it repeatedly with a single click!</p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #2 */}
                            {/* FEATURE BOX #3 */}
                            <div className="col">
                                <div className="fbox-7 fb-3 r-12 wow fadeInUp" style={{boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',height:'18rem'}}>
                                    {/* Icon */}
                                    <div className="fbox-ico ico-50">
                                        <div className="shape-ico color--theme">
                                            {/* Vector Icon */}
                                            {/* <span className="flaticon-diamond" /> */}
                                            <span><img  src={require('./Images/icons/3_2.png')} style={{height:'60px',width:'60px'}} /></span>

                                            {/* Shape */}
                                            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                            </svg>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt">
                                        <h6 className="s-20 w-700">Promotions</h6>
                                        <p className="p-sm">Unlock Exclusive Deals: Your favorite restaurants' promotions delivered directly to you.</p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #3 */}
                           
                        </div>  {/* End row */}
                    </div>	{/* END FEATURES-7 WRAPPER */}
                </div>     {/* End container */}
            </section>
        </>
    )
}
