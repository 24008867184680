import { Image } from "@material-ui/icons";
import Footer from "./Footer";
import Header from "./Header";
import { useNavigate,useLocation } from "react-router-dom";
import { useEffect } from "react";


export default function NotFoundPage() {

    const naviagte = useNavigate();

    const location = useLocation(); // Use the useLocation hook to get the current location

    useEffect(() => {
        // Scroll to the top of the page whenever the component mounts
        window.scrollTo(0, 0);
    }, [location]); // Depend on the location to re-run the effect when navigating


    const handleSubmit = () => {
        naviagte('/')
    }
    return (
        <div style={{}}>

            <Header />
            <div className="container">
                <div className="row justify-content-center align-items-center" style={{ height: '80vh',marginBottom:'50px' }}>
                    <div className="col-md-6 text-center">
                        <img src={'./img/404Img.png'} style={{ maxWidth: '100%', height: 'auto' }} alt="404Page" />
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <button className="btn-dark mx-auto" onClick={handleSubmit} id="BBColor" style={{ borderRadius: '8px' }}>Goback To HomePage</button>
                    </div>
                </div>
            </div>






            {/* <Footer/> */}
        </div>
    )
}

// import React, { useState, useEffect } from 'react';
// import { Typography } from '@mui/material';
// import Header from './Header';

// const Welcome = () => {
//     const [username, setUsername] = useState('');

//     useEffect(() => {
//         const Uname = localStorage.getItem('Username');
//         const extractedUsername = Uname.split('@')[0];

//         setUsername(extractedUsername);
//     }, []);

//     return (
//         <div style={{  backgroundColor: '#feb300' }}>
//             <Header />


//             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:'70vh'}}>

//                 {username &&
//                     <Typography variant="h4" style={{ color: 'black', textAlign: 'center' }}>Hi!! {username}</Typography>
//                 }
//                 <Typography variant="h5" style={{ color: 'black' }}>Coming Soon</Typography>
//             </div>

//         </div>
//     );
// };

// export default Welcome;
