import { Link } from "react-router-dom";

export default function Banner3_4() {

    const handleSvgClick1 = () => {
        alert('Will redirect to App Store');
        // Add your logic here for SVG 1 click
    };

    const handleSvgClick2 = () => {
        alert('Will redirect to Google Play Store');
        // Add your logic here for SVG 1 click
    };

    return (
        <>
            <section id="banner-3" className="b-30 banner-section spaceTop1">
                <div className="container collageSec" >
                    {/* BANNER-3 WRAPPER */}
                    <div className="banner-3-wrapper bg--03 bg--scroll r-16 ">
                        <div className="banner-overlay" id="custCTA" style={{}}>
                            <div className="row">
                                {/* BANNER-3 TEXT */}
                                <div className="col">
                                    <div className="banner-3-txt color--white" id="ColorBlack">
                                        {/* Title */}
                                        <h2 className="s-40 w-700" id="ColorBlack" style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>Embrace The 1-Click Food Pickup</h2>
                                        {/* Text */}
                                        <p className="p-l" id="ColorBlack" style={{ fontWeight: '500', fontSize: '1.1rem' }}>Experience the new pinnacle of ease when ordering food for pickup – it's never been this simple!</p>
                                        {/* Button */}
                                        {/* <Link href="/signup-1" className="btn r-04 btn--theme hover--tra-white">Get srarted - it's free</Link> */}
                                        <div className="btns-group">
                                            {/* <Link href="#banner-3" className="btn r-04 btn--theme hover--theme">Get started for free</Link>
                                        <VideoPopup style={5}/> */}

                                            <div>
                                                <a href={`${process.env.PUBLIC_URL}/App-Store-Best8eats`} className='links' id="FooterLink" style={{ textDecoration: 'none' }}>
                                                    <img
                                                        src={'./img/apple.svg'}
                                                        alt="SVG 1"
                                                        // onClick={handleSvgClick1}
                                                        style={{
                                                            cursor: 'pointer', marginRight: '20px', margin: '-50px 15px ',
                                                            height: '150px'
                                                        }}
                                                    />
                                                </a>

                                                <a href={`${process.env.PUBLIC_URL}/Play-Store-Best8eats`} className='links' id="FooterLink" style={{ textDecoration: 'none' }}>

                                                <img
                                                    src={'./img/android.svg'}
                                                    alt="SVG 2"
                                                    // onClick={handleSvgClick2}
                                                    style={{
                                                        cursor: 'pointer', margin: '-50px 15px',
                                                        height: '150px'
                                                    }}
                                                />
                                                </a>
                                            </div>


                                        </div>

                                        {/* Button Text */}
                                        <p className="p-sm btn-txt ico-15" id="ColorBlack" style={{ fontWeight: '500' }}>
                                            {/* <span className="flaticon-check" style={{color:'black'}} />  */}
                                            Effortless delights await!
                                        </p>
                                    </div>
                                </div>	{/* END BANNER-3 TEXT */}
                            </div>   {/* End row */}
                        </div>   {/* End banner overlay */}
                    </div>    {/* END BANNER-3 WRAPPER */}
                </div>     {/* End container */}
            </section>
        </>
    )
}
