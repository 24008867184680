import './download.css'
import './bootstrap.min.css'
import { Image } from 'react-bootstrap'

export default function Features11_14() {
    return (
        <>
            <section id="features-11" className="pt-100 pb-100 features-section division spacetopbottom" style={{background:'#F5F4FA'}}>
                <div className="container">
                    {/* SECTION TITLE */}
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-70">
                                {/* Title */}
                                <h2 className="s-35 w-700">Optimize Your Restaurant's Operations With Best8Eats. Save Both Time And Money.</h2>
                                {/* Text */}
                                <p className="s-21 color--grey" >Take a look at all the fantastic features right at your fingertips.</p>
                            </div>
                        </div>
                    </div>
                    {/* FEATURES-11 WRAPPER */}
                    <div className="fbox-wrapper container"  >
                        <div className="row row-cols-1 row-cols-md-2 rows-3">
                            {/* FEATURE BOX #1 */}
                            <div className="col">
                                <div className="fbox-11 fb-1 wow fadeInUp">
                                    {/* Icon */}
                                    <div className="fbox-ico-wrap">
                                        <div className="fbox-ico ico-50" >
                                            <div className="shape-ico color--theme" >
                                                {/* Vector Icon */}
                                                {/* <span className="flaticon-graphics" /> */}
                                                <span><img  src={'./img/test1/restFeatures/f1_2.png'} style={{height:'60px',width:'60px'}} /></span>
                                                {/* Shape */}
                                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" >
                                                    <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt fbox-txt1">
                                        <h6 className="s-22 w-700 c-black">Subscription Based</h6>
                                        <p>
                                            <ul style={{listStyleType:'disc',  paddingLeft: '10px' }}>
                                            <li>Small monthly subscriptions</li>
                                            <li>Diverse packages for individual, chain, or multiple restaurants.</li>
                                            <li>We manage menu creation for minimal setup time.</li>
                                            <li>Curbside/pickup options for customers.</li>
                                        </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #1 */}
                            {/* FEATURE BOX #2 */}
                            <div className="col">
                                <div className="fbox-11 fb-2 wow fadeInUp">
                                    {/* Icon */}
                                    <div className="fbox-ico-wrap">
                                        <div className="fbox-ico ico-50">
                                            <div className="shape-ico color--theme">
                                                {/* Vector Icon */}
                                                {/* <span className="flaticon-idea" /> */}
                                                <span><img  src={'./img/test1/restFeatures/f2_2.png'} style={{height:'60px',width:'60px'}} /></span>
                                                {/* Shape */}
                                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt fbox-txt1">
                                        <h6 className="s-22 w-700 c-black">Real-time</h6>
                                        <p>
                                        <ul style={{ listStyleType: 'disc', paddingLeft: '10px' }}>
                                            <li>Instantaneous menu updates.</li>
                                            <li>Live updates on order status for customers.</li>
                                            <li>Stay informed about today's order status: new, reviewed, ready for pickup, complete, and cancelled orders.</li>
                                        </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #2 */}
                            {/* FEATURE BOX #3 */}
                            <div className="col">
                                <div className="fbox-11 fb-3 wow fadeInUp">
                                    {/* Icon */}
                                    <div className="fbox-ico-wrap">
                                        <div className="fbox-ico ico-50">
                                            <div className="shape-ico color--theme">
                                                {/* Vector Icon */}
                                                {/* <span className="flaticon-graphic" /> */}
                                                <span><img  src={'./img/test1/restFeatures/f3_3.png'} style={{height:'60px',width:'60px'}} /></span>
                                                {/* Shape */}
                                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt fbox-txt1">
                                        <h6 className="s-22 w-700 c-black">Accessible Via Mobile App</h6>
                                        <p>
                                            <ul style={{ listStyleType: 'disc', paddingLeft: '10px' }}>
                                                <li>Free for up to 4 mobile users!</li>
                                                <li>Effortless staff access with comprehensive permission control.</li>
                                                <li>Mobile app for individual staff enables swift order processing.</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #3 */}
                            {/* FEATURE BOX #4 */}
                            <div className="col">
                                <div className="fbox-11 fb-4 wow fadeInUp">
                                    {/* Icon */}
                                    <div className="fbox-ico-wrap">
                                        <div className="fbox-ico ico-50">
                                            <div className="shape-ico color--theme">
                                                {/* Vector Icon */}
                                                {/* <span className="flaticon-wireframe" /> */}
                                                <span><img  src={'./img/test1/restFeatures/f4_3.png'} style={{height:'60px',width:'60px'}} /></span>
                                                {/* Shape */}
                                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt fbox-txt1">
                                        <h6 className="s-22 w-700 c-black">Versatile </h6>
                                        <p>
                                        <ul style={{ listStyleType: 'disc', paddingLeft: '10px' }}>
                                            <li>Complete support for order customization.</li>
                                            <li>Personalize payment options, cancellation policy, and holiday calendar.</li>
                                            <li>Flexible food ordering hours.</li>
                                        </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #4 */}
                            {/* FEATURE BOX #5 */}
                            <div className="col">
                                <div className="fbox-11 fb-5 wow fadeInUp">
                                    {/* Icon */}
                                    <div className="fbox-ico-wrap">
                                        <div className="fbox-ico ico-50">
                                            <div className="shape-ico color--theme">
                                                {/* Vector Icon */}
                                                {/* <span className="flaticon-trophy" /> */}
                                                <span><img  src={'./img/test1/restFeatures/f5_1.png'} style={{height:'60px',width:'60px'}} /></span>

                                                {/* <span><img  src={'./img/crm.png'} style={{height:'60px',width:'60px'}}  /></span> */}
                                                {/* Shape */}
                                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt fbox-txt1">
                                        <h6 className="s-22 w-700">Cloud-Based CRM</h6>
                                        
                                        <p>
                                        <ul style={{ listStyleType: 'disc', paddingLeft: '10px' }}>
                                            <li>Comprehensive sales information with various timelines.</li>
                                            <li>Discover your best-selling dishes.</li>
                                            <li>Thorough documentation/logging for future reference.</li>
                                        </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #5 */}
                            {/* FEATURE BOX #6 */}
                            <div className="col">
                                <div className="fbox-11 fb-6 wow fadeInUp">
                                    {/* Icon */}
                                    <div className="fbox-ico-wrap">
                                        <div className="fbox-ico ico-50">
                                            <div className="shape-ico color--theme">
                                                {/* Vector Icon */}
                                                {/* <span className="flaticon-search-engine-1" /> */}
                                                <span><img  src={'./img/test1/restFeatures/f6_1.png'} style={{height:'60px',width:'60px'}} /></span>

                                                {/* Shape */}
                                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>	{/* End Icon */}
                                    {/* Text */}
                                    <div className="fbox-txt fbox-txt1">
                                        <h6 className="s-22 w-700">Automated Marketing</h6>
                                        <p>
                                        <ul style={{ listStyleType: 'disc', paddingLeft: '10px' }}>
                                            <li>Effortless creation and management of promo codes.</li>
                                            <li>Automated email campaigns for restaurant promotions.</li>
                                            <li>Targeted advertising to enhance customer engagement and drive business growth.</li>
                                        </ul>

                                        </p>
                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #6 */}
                        </div>  {/* End row */}
                    </div>	{/* END FEATURES-11 WRAPPER */}
                </div>     {/* End container */}
            </section>
        </>
    )
}
