import { Button, ButtonGroup, Image } from 'react-bootstrap';
import './Rest.css'
import './bootstrap.min.css'
import './download.css'
import './css/responsive.css'
import './css/animate.css'
import './css/demo.css'
import './css/lunar.css'
import Stat from './Stat';
import Features11_14 from './Features11_14';
import VideoPopup from './VidepPopupRestOwner';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';
import VidepPopupRestOwner from './VidepPopupRestOwner';

const RestOwner = () => {


    const Navigate = useNavigate()
    const handleSubmit = () => {
        Navigate('/pricing')
    }

    const handleSubmitSignup = () => {
        Navigate('/sign-up')
    }

    return (
        <div>
            <section id="hero-5" className=" bg--scroll  page font--jakarta" style={{ paddingTop: '0px' }}>
                <Header />
                <div className="container">
                    <div className="row d-flex " style={{ marginTop: '15px' }}>
                        {/* <div className="row d-flex align-items-center"> */}
                        {/* HERO TEXT */}
                        <div className="col-md-6" style={{ color: 'black' }}>
                            <div className="hero-5-txt wow fadeInRight " >
                                {/* Title */}
                                <span className="section-id aboveHead" style={{ marginBottom: '10px' }}>Restaurant Owner</span>

                                <h3 className="s-35 w-700" >We understand your passion for food. Pursue what you love, and let us handle the rest</h3>
                                {/* Text */}
                                <p className="p-lg"> <ul style={{ listStyle: 'disc' }}>
                                    <li>Affordable monthly subscriptions allow you to retain the complete check amount.</li>
                                    <li>Cutting-edge cloud-based CRM platform offering a comprehensive ecosystem for your sales data.</li>
                                    <li>Integrated marketing automation to retain and expand your customer base.</li>


                                </ul>

                                </p>
                                {/* <ul className="simple-list">
                                    <li className="">
                                    <p className="mb-0 p-lg">Affordable monthly subscriptions allow you to retain the complete check amount.
                                        </p>

                                    </li>
                                    
                                    <li className="list-item">
                                        <p className="mb-0">Cutting-edge cloud-based CRM platform offering a comprehensive ecosystem for your sales data.</p>
                                    </li>
                                    <li className="list-item">
                                        <p className="mb-0">Integrated marketing automation to retain and expand your customer base.</p>
                                    </li>
                                </ul>
                                </p> */}
                                {/* Button */}
                                <button id='BColor' className="btn--theme hover--tra-grey" href="#banner-7" style={{ borderRadius: '12px' }} onClick={handleSubmit}>Get Pricing</button>
                            </div>
                        </div>	{/* END HERO TEXT */}
                        {/* HERO IMAGE */}
                        <div className="col-md-6 spaceTop" style={{ textAlign: 'center' }}>
                            <div className="hero-5-img wow fadeInLeft">
                                {/* <img className="img-fluid" style={{height:'32em',maxWidth:'23em', borderRadius:'20px',objectFit:'cover'}} src="/img/Rest/7.jpeg" alt="hero-image" /> */}
                                <img className="img-fluid" style={{ height: '90vh', borderRadius: '10%' }} src="/img/ResHero2.png" alt="hero-image" />
                            </div>
                        </div>
                    </div>    {/* End row */}
                </div>	   {/* End container */}
                {/* WAVE SHAPE BOTTOM */}
                <div className="wave-shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 170"><path fillOpacity={1} d="M0,160L120,160C240,160,480,160,720,138.7C960,117,1200,75,1320,53.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z" /></svg>
                </div>
            </section>

            <section>
                <Stat />

            </section>

            <section id="lnk-1" className=" ws-wrapper ">
                <div className="container">
                    <div className="bc-5-wrapper bg--02 hidd bg--scroll">
                        <div className="section-overlay">
                            {/* SECTION TITLE */}
                            <div className="row justify-content-center">
                                <div className="col-md-11 col-lg-9">
                                    <div className="section-title wow fadeInUp mb-60">
                                        {/* Title */}
                                        <h2 className="s-35 w-700" style={{ color: 'black' }}>
                                            {/* <Image src={'./img/b8eLogo.png'} height={50}  style={{marginBottom:'15px'}} /> */}
                                            What sets Best8Eats apart and makes it unique?</h2>
                                        {/* Text */}
                                        <p className="p-xl ">Unleash your restaurant's full potential with our comprehensive all-in-one solution, featuring the same robust tools as major chains- all at affordable monthly subscriptions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* IMAGE BLOCK */}
                            <div className="row justify-content-center">
                                <div className="col">
                                    <div className="bc-5-img bc-5-tablet     video-preview wow fadeInUp">
                                        {/* Play Icon */}
                                        {/* Preview Image */}
                                        <div>
                                            <VidepPopupRestOwner style={1} />

                                            <img className="img-fluid" src="/img/tabScreen.png" style={{ width: '100%', borderRadius: '18px' }} alt="content-image" />

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>   {/* End section overlay */}
                    </div>    {/* End content wrapper */}
                </div>     {/* End container */}
            </section>



            <section>
                <Features11_14 />
            </section>
            {/* <div style={{margin:'50px 50px 0'}}>                
                    <hr className="divider" />
                </div> */}

            <section>

                <section id="banner-13" className="banner-section">
                    <div className="container collageSec" >
                        {/* BANNER-13 WRAPPER */}
                        <div className="banner-13-wrapper bg--05 bg--scroll r-16 block-shadow">
                            <div className="restLastCard" style={{ backgroundColor: '#FEB300' }}>
                                <div className="row d-flex align-items-center">
                                    {/* BANNER-5 TEXT */}
                                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                        <div className="banner-13-txt color--white">
                                            {/* Title */}
                                            <h2 className="s-35 w-700 c-black">Enroll for your complete restaurant business solution today.</h2>
                                            {/* Text */}
                                            <p className="p-lg  c-black">Embark on your culinary adventure with a subscription. Join now for instant access, and let the orders start flowing in!
                                            </p>
                                            {/* Button */}
                                            <a href={`${process.env.PUBLIC_URL}/sign-up`}>
                                                <button className="btn-dark" id="BBColor" style={{ marginLeft: '0px', borderRadius: '8px' }}>Get Started - It's Free</button>
                                            </a> </div>
                                    </div>	{/* END BANNER-13 TEXT */}
                                    {/* BANNER-13 IMAGE */}
                                    <div className="col-md-5">
                                        <div className="banner-13-img text-center">
                                            <img className="img-fluid" src="/img/test1/restCTA/final1.png" style={{ maxHeight: '500px' }} alt="Collage-Image" />
                                        </div>
                                    </div>
                                </div>   {/* End row */}
                            </div>   {/* End banner overlay */}
                        </div>    {/* END BANNER-13 WRAPPER */}
                    </div>     {/* End container */}
                </section>
            </section>


        </div>
    )
}

export default RestOwner;