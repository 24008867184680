import { Image } from "react-bootstrap";
import VideoPopup from "./VidepPopupRestOwner";
import VidepPopupCustomer from "./VidepPopupCustomer";


export default function Features5_5() {
    return (
        <>
            <section id="features-5" className=" features-section division">
                <div className="container">
                    {/* SECTION TITLE */}
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-70">
                                {/* Title */}
                                <h2 className="s-35 w-700">Master the Art of Savings on Your Pickup Orders with Our App!</h2>
                                {/* Text */}
                                {/* <p className="s-21 color--grey">Why us? Elevate your experience with what makes us exceptional!</p> */}
                                <p className="s-21 color--grey">Know Your Facts!</p>
                                
                                {/* <div className="fbox-txt order-last order-md-2">
                                        <h5 className="s-26 w-700">Know Your Facts!</h5>
                                        <p>Discover intriguing insights into the world of ordering food from restaurants!</p>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    {/* FEATURES-5 WRAPPER */}
                    <div className="fbox-wrapper text-center">
                        <div className="row d-flex align-items-center">
                            {/* FEATURE BOX #1 */}
                            <div className="col-md-6">
                                <div className="fbox-5 fb-1 bg--white-400 r-16 wow fadeInUp">
                                    {/* Text */}
                                    {/* <div className="fbox-txt order-last order-md-2">
                                        <h5 className="s-26 w-700">Know Your Facts!</h5>
                                        <p>Discover intriguing insights into the world of ordering food from restaurants!</p>
                                    </div> */}
                                    {/* Image */}
                                    <div className="fbox-5-img order-first order-md-2">
                                        {/* <img className="img-fluid light-theme-img" src="/images/f_06.png" alt="feature-image" />
                                        <img className="img-fluid dark-theme-img" src="/images/f_06_dark.png" alt="feature-image" />
                                     */}
                                    <ul style={{textAlign:'left',paddingLeft:'0rem'}}>
                                        <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px'}}><span style={{fontWeight:'700',color:'#1680FB'}}>75%</span> of customers opt for pickup instead of using delivery apps!</li>
                                        <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px',margin:'10px 0'}}>Food delivery apps consistently add a minimum of <span style={{fontWeight:'700',color:'#1680FB'}}>30%</span> extra to your food orders!</li>
                                        <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px',margin:'10px 0'}}><span style={{fontWeight:'700',color:'#1680FB'}}>&gt; 28%</span> of customers are unaware of the extra charges on their orders!</li>
                                        <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px',margin:'10px 0'}}><span style={{fontWeight:'700',color:'#1680FB'}}>&gt; 90%</span> of people stick to their top 8 favorite restaurants for their dining adventures!</li>
                                        {/* <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px',margin:'10px 0'}}>The population density of your neighborhood won't alter these numbers.</li> */}
                                        {/* <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px',margin:'10px 0'}}>Currently, the majority of customers prefer the traditional approach of calling a restaurant to pick up their food orders</li> */}
                                        <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px',margin:'10px 0'}}>Restaurants make mistakes in <span style={{fontWeight:'700',color:'#1680FB'}}>&gt; 30%</span> of orders on a regular basis.</li>

                                    </ul>



                                    </div>
                                </div>
                            </div>	{/* END FEATURE BOX #1 */}
                            {/* FEATURE BOX #2 */}
                            <div className="col-md-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ position: 'relative' }}>
                                    <VidepPopupCustomer style={1} />
                                    <img
                                    className="img-fluid light-theme-img"
                                    // src="/img/test/ResPickup.png"
                                    src={require("./Images/videoSec/3.jpeg")}
                                    style={{ height: '35.5rem', objectFit: 'cover', borderRadius: '12px' }}
                                    alt="Image of a customer looking happy on saving money"
                                    />
                                </div>




                                {/* <div className="fbox-5 fb-2 bg--white-400 r-16 wow fadeInUp">
                                <img className="img-fluid light-theme-img" src="/img/test/ResPickup.png" style={{height:'100%',objectFit:'fill',borderRadius:'12px'}} alt="Image of a customer looking happy on saving money" /> */}




                                    {/* <ul>
                                        <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px',margin:'10px 0'}}>Say goodbye to wrong orders! Digitized orders, always accurate. Save time by ordering food with just 1-click</li>
                                        <li style={{background:'white',padding:'10px 15px',border:'2px solid gray',borderRadius:'12px',margin:'10px 0'}}><span style={{fontWeight:'700',color:'#1680FB'}}>$0</span> Extra Fees. Menu Prices Always! Only with  <Image src={'./img/b8eLogo.png'} height={35}  style={{marginBottom:'12px'}} /> Best8Eats.</li>
                                    </ul> */}
                                    {/* Image */}
                                    {/* <div className="fbox-5-img">
                                        <img className="img-fluid light-theme-img" src="/img/test/ResPickup.png" style={{height:'33rem',objectFit:'contain',borderRadius:'12px'}} alt="Image of a customer looking happy on saving money" />
                                    </div> */}
                                    {/* Text */}
                                    {/* <div className="fbox-txt">
                                        <h5 className="s-26 w-700">Video Title text</h5>
                                        <p>Video Subtext</p>
                                    </div> */}



                                {/* </div> */}
                            </div>	{/* END FEATURE BOX #2 */}
                        </div>  {/* End row */}
                    </div>	{/* END FEATURES-5 WRAPPER */}
                </div>     {/* End container */}
            </section>
        </>
    )
}
