// import Layout from "@/components/layout/Layout"
// import BrandSlider2 from "@/components/slider/BrandSlider2"
// import Link from "next/link"
import { useState, useEffect } from "react"
import * as React from 'react';
// import Box from '@mui/material/Box';

// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Form, Container, Row, Col, Button, Nav } from 'react-bootstrap';

import { Link, NavLink, useNavigate } from "react-router-dom"
import Ct03_2 from "./Ct03_2"
import Ct03_1 from "./Ct03_1"
import Reviews1_1 from "./Reviews1_1"
import CounterUp from "./CounterUpPricing";
import Header from "./Header";


export default function Price() {
    // const [isPricing, setPricing] = useState(false)
    // const [locations, setLocations] = useState(2);
    // const [package1Base, setPackage1Base] = useState(25)
    // const [package2Base, setPackage2Base] = useState(35)
    // const [package3Base, setPackage3Base] = useState(45)

    // const [package1Cost, setPackage1Cost] = useState();
    // const [package2Cost, setPackage2Cost] = useState();
    // const [package3Cost, setPackage3Cost] = useState();

    // const [package2Monthly, setpackage2Monthly] = useState();
    // const [package3Monthly, setpackage3Monthly] = useState();





    // const package1 = (package1Base * 12);
    // const package1Discount = package1 * 0.2;
    // const package1Yearly = package1 - package1Discount;

    // const handlePricing = () => {
    //     setPricing(!isPricing)
    //     setPackage1Cost(package1Yearly)
    // }



    // const calculateCost = (selectedLocations) => {
    //     // Calculation based on the selected location and package prices
    //     const Basediscount = 0.2;

    //     if (selectedLocations == 2) {
    //         // setPackage1Base(25)
    //         setPackage2Base(35)
    //         setPackage3Base(45)
    //         const package2 = (package2Base * 12);
    //         const package3 = (package3Base * 12);
    //         const package2Discount = package2 * 0.2;
    //         const package3Discount = package3 * 0.2;
    //         const package2Yearly = package2 - package2Discount;
    //         const package3Yearly = package3 - package3Discount;
    //         setPackage2Cost(package2Yearly)
    //         setPackage3Cost(package3Yearly)

    //     } else if (selectedLocations > 2) {
    //         // Calculate the adjusted base costs for package2 and package3
    //         const adjustedPackage2Base = 35 + ((selectedLocations - 2) * 25);
    //         const adjustedPackage3Base = 45 + ((selectedLocations - 2) * 25);
    //         setpackage2Monthly(adjustedPackage2Base)
    //         setpackage3Monthly(adjustedPackage3Base)

    //         // Calculate the yearly costs for package2 and package3
    //         const package2Yearly = adjustedPackage2Base * 12;
    //         const package3Yearly = adjustedPackage3Base * 12;

    //         // Apply the 20% discount to the yearly costs
    //         const package2Discount = package2Yearly * 0.2;
    //         const package3Discount = package3Yearly * 0.2;

    //         // Calculate the final costs for package2 and package3 after applying the discount
    //         const package2CostAfterDiscount = package2Yearly - package2Discount;
    //         const package3CostAfterDiscount = package3Yearly - package3Discount;

    //         // Update the state with the final costs
    //         setPackage2Cost(package2CostAfterDiscount);
    //         setPackage3Cost(package3CostAfterDiscount);
    //     }

    //     //   const package1 = 25 * 12 +  (selectedLocations * 25);
    //     // const package2 = 35 * 12 + (selectedLocations * 25);

    //     // const package3 = 45 * 12 + (selectedLocations * 25);


    //     // Calculate total cost and apply 20% discount
    //     const discount = 0.2;
    //     //   const package1CostWithoutDiscount = package1;
    //     // const package2CostWithoutDiscount = package2;
    //     // const package3CostWithoutDiscount = package3;
    //     //   const package1DiscountedAmount = package1CostWithoutDiscount * discount;
    //     // const package2DiscountedAmount = package2CostWithoutDiscount * discount;
    //     // const package3DiscountedAmount = package3CostWithoutDiscount * discount;
    //     //   const package1CostAfterDiscount = package1CostWithoutDiscount - package1DiscountedAmount;
    //     // const package2CostAfterDiscount = package2CostWithoutDiscount - package2DiscountedAmount;
    //     // const package3CostAfterDiscount = package3CostWithoutDiscount - package3DiscountedAmount;

    //     //   setPackage1Cost(package1CostAfterDiscount);
    //     // setPackage2Cost(package2CostAfterDiscount);
    //     // setPackage3Cost(package3CostAfterDiscount);
    // };

    // useEffect(() => {
    //     // Calculate initial cost when the component mounts
    //     calculateCost(locations);
    // }, [locations]);



    // const handleChange = (event) => {
    //     //   const selectedLocations = parseInt(event.target.value, 10);
    //     setLocations(event.target.value);
    // };

    // const options = [];
    // for (let i = 2; i <= 40; i += 1) {
    //     options.push(<option key={i} value={i}>{i}</option>);
    // }



    // const Navigate = useNavigate()

    // const handleSubmit = () => {

    //     Navigate('/sign-up')

    // }

    // const handleSubmitContact = () => {

    //     Navigate('/contact-us')

    // }

    const [isPricing, setPricing] = useState(false);
    const [locations, setLocations] = useState(2);
    const [package1Base, setPackage1Base] = useState(25);
    const [package2Base, setPackage2Base] = useState(40);
    const [package3Base, setPackage3Base] = useState(25);

    const [package1Cost, setPackage1Cost] = useState();
    const [package2Cost, setPackage2Cost] = useState();
    const [package3Cost, setPackage3Cost] = useState();

    const [package1CostM, setPackage1CostM] = useState();
    const [package2CostM, setPackage2CostM] = useState();
    const [package3CostM, setPackage3CostM] = useState();

    const calculateCost = (selectedLocations) => {
        // Monthly charges
        const package1Monthly = package1Base;
        const package2Monthly = package2Base + ((selectedLocations - 1) * 10);
        const package3Monthly = package3Base * selectedLocations;

        setPackage1CostM(package1Monthly)
        setPackage2CostM(package2Monthly)
        setPackage3CostM(package3Monthly)

        // Yearly charges
        const package1Yearly = (package1Base * 12) - ((package1Base * 12) * 0.2); // 20% discount
        const package2Yearly = ((package2Base + ((selectedLocations - 1) * 10)) * 12) - (((package2Base + ((selectedLocations - 1) * 10)) * 12) * 0.2); // 20% discount
        const package3Yearly = (package3Base * selectedLocations * 12) - ((package3Base * selectedLocations * 12) * 0.2); // 20% discount

        // setPackage1Cost(package1Monthly);
        // setPackage2Cost(selectedLocations === 1 ? package2Yearly : package2Monthly);
        setPackage1Cost(package1Yearly);
        setPackage2Cost(package2Yearly);
        setPackage3Cost(package3Yearly);
    };


    const options = [];
    for (let i = 2; i <= 40; i += 1) {
        options.push(<option key={i} value={i}>{i}</option>);
    }

    useEffect(() => {
        calculateCost(locations);
    }, [locations, isPricing]);

    const handlePricing = () => {
        setPricing(!isPricing);
    };

    const handleChange = (event) => {
        setLocations(parseInt(event.target.value, 10));
    };
    return (
        <>
            {/* <Layout headerStyle={1} footerStyle={3} headerCls="navbar-dark inner-page-header"> */}
            <div>
                <section id="pricing-1" className="gr--whitesmoke  pricing-section" >
                    <Header />
                    <div className="container" style={{ marginTop: '15px' }}>
                        {/* SECTION TITLE */}
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8">
                                <div className="section-title mb-25">
                                    {/* Title */}
                                    <h2 className="s35 w-700">Simple, Flexible Pricing</h2>
                                    {/* TOGGLE BUTTON */}
                                    <div className="toggle-btn ext-toggle-btn toggle-btn-md mt-30">
                                        <span className="toggler-txt">Billed monthly</span>
                                        <label className="switch-wrap">
                                            <input type="checkbox" id="checbox" onClick={handlePricing} />
                                            <span className="switcher bg--grey switcher--theme">
                                                <span className="show-annual" />
                                                <span className="show-monthly" />
                                            </span>
                                        </label>
                                        <span className="toggler-txt">Billed yearly</span>
                                        {/* Text */}
                                        <p className="color--theme">Save up to 20% with yearly billing</p>
                                        <section>

                                            <Container className="mt-10 d-flex justify-content-center align-items-center" style={{ height: '9vh' }}>
                                                <Row>
                                                    <Col lg={{ span: 4 }} md={{ span: 4 }} sm={{ span: 6 }} xs={{ span: 6 }}>
                                                        <Form className="d-flex align-items-center justify-content-center">
                                                            <Form.Group controlId="exampleForm.ControlSelect1" className="d-flex gap-3 align-items-center">
                                                                <Form.Label className="" style={{ minWidth: '100px', justifyContent: 'center', alignItems: 'center', marginBottom: '0' }}># Locations</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    value={locations}
                                                                    onChange={handleChange}
                                                                    style={{ textAlign: 'center' }}
                                                                    id="pricingInput"
                                                                >
                                                                    {options}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Container>


                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>	{/* END SECTION TITLE */}
                        {/* PRICING TABLES */}
                        <div className="pricing-1-wrapper">
                            <div className="row row-cols-1 row-cols-md-3">
                                {/* STARTER PLAN */}
                                <div className="col">
                                    <div id="pt-1-1" className="p-table pricing-1-table bg--white-100 block-shadow r-12 wow fadeInUp" style={{ height: '91%' }}>
                                        {/* TABLE HEADER */}
                                        <div className="pricing-table-header">
                                            {/* Title */}
                                            <h5 className="s-24 w-700">Package 1</h5>
                                            {/* Price */}
                                            <div className="price">
                                                {isPricing ? <div className="price1">
                                                    <sup className="color--black">$</sup>
                                                    <span className="color--black">{package1Cost}</span>
                                                    <sup className="validity color--grey">/ year</sup>
                                                    {/* Discount Badge */}
                                                    <div className="pricing-discount bg--yellow-400 color--black r-36">
                                                        <h6 className="s-17">Save 20%</h6>
                                                    </div>
                                                </div> : <div className="price2">
                                                    <sup className="color--black">$</sup>
                                                    <span className="color--black">{package1CostM}</span>
                                                    <sup className="validity color--grey">/ month</sup>
                                                </div>}

                                            </div>
                                            <ul className="pricing-features color--black ico-10 ico--green mt-25">
                                                <li><p><span className="flaticon-check" />Restaurant With A Single Location</p></li>
                                                <li><p><span className="flaticon-check" />Upto 4 phone app logins free*</p></li>
                                                <li><p><span className="flaticon-check" style={{ display: 'none' }} /></p></li>
                                                <li><p><span className="flaticon-check" style={{ display: 'none' }} /></p></li>
                                                <li><p><span className="flaticon-check" style={{ display: 'none' }} /></p></li>
                                                {/* <li><p><span className="flaticon-check" /> For personal use</p></li>
                                                <li className="disabled-option"><p><span className="flaticon-check" /> Weekly data backup</p></li>
                                                <li className="disabled-option"><p><span className="flaticon-check" /> No Ads. No trackers</p></li>
                                                <li><p><span className="flaticon-check" /> 12/5 email support</p></li> */}
                                            </ul>
                                            {/* Button */}
                                            <a href={`${process.env.PUBLIC_URL}/sign-up`} style={{ textDecoration: 'none' }}>
                                                <div id='BColor' className="" style={{ minWidth: '100%', textAlign: 'center', borderRadius: '12px', cursor: 'pointer' }}>Get Started - It's Free</div></a>
                                            <p className="p-sm btn-txt text-center color--grey">No credit card required</p>
                                        </div>	{/* END TABLE HEADER */}
                                        {/* PRICING FEATURES */}

                                    </div>
                                </div>	{/* END STARTER PLAN */}
                                {/* BASIC PLAN */}
                                <div className="col">
                                    <div id="pt-1-2" className="p-table pricing-1-table bg--white-100 block-shadow r-12 wow fadeInUp" style={{ height: '91%' }}>
                                        {/* TABLE HEADER */}
                                        <div className="pricing-table-header">
                                            {/* Title */}
                                            <h5 className="s-24">Package 2</h5>
                                            {/* Price */}
                                            <div className="price">
                                                {/* Monthly Price */}
                                                {isPricing ? <div className="price1">
                                                    <sup className="color--black">$</sup>
                                                    <span className="color--black">{package2Cost}</span>
                                                    <sup className="validity color--grey">/ year</sup>
                                                    {/* Discount Badge */}
                                                    <div className="pricing-discount bg--yellow-400 color--black r-36">
                                                        <h6 className="s-17">Save 20%</h6>
                                                    </div>
                                                </div> : <div className="price2">
                                                    <sup className="color--black">$</sup>
                                                    <span className="color--black">{locations > 1 ? package2CostM : package2Base}</span>
                                                    <sup className="validity color--grey">/ month</sup>
                                                </div>}

                                                {/* Text */}
                                                {/* <p className="color--grey">For personal use or small teams with simple workflows.</p> */}
                                            </div>	{/* End Price */}

                                            <ul className="pricing-features color--black ico-10 ico--green mt-25">
                                                <li><p><span className="flaticon-check" />Restaurant with multiple locations but same menu</p></li>
                                                <li><p><span className="flaticon-check" />Pick the number of locations below to see pricing</p></li>
                                                <li><p><span className="flaticon-check" />Upto 4 phone app logins free per location*</p></li>
                                                {/* <li><p><span className="flaticon-check" /> Daily data backup</p></li>
                                                <li><p><span className="flaticon-check" /> No Ads. No trackers</p></li>
                                                <li><p><span className="flaticon-check" /> 12/7 email support</p></li> */}
                                            </ul>
                                            {/* Button */}
                                            <a href={`${process.env.PUBLIC_URL}/sign-up`} style={{ textDecoration: 'none' }}>

                                                <div id='BColor' className="" style={{ minWidth: '100%', textAlign: 'center', borderRadius: '12px', cursor: 'pointer' }}>Get Started - It's Free</div>
                                            </a>
                                            <p className="p-sm btn-txt text-center color--grey">No credit card required</p>
                                        </div>	{/* END TABLE HEADER */}
                                        {/* PRICING FEATURES */}

                                    </div>
                                </div>	{/* END BASIC PLAN */}
                                {/* ADVANCED PLAN */}
                                <div className="col">
                                    <div id="pt-1-3" className="p-table pricing-1-table bg--white-100 block-shadow r-12 wow fadeInUp" style={{ height: '91%' }}>
                                        {/* TABLE HEADER */}
                                        <div className="pricing-table-header">
                                            {/* Title */}
                                            <h5 className="s-24">Package 3</h5>
                                            {/* Price */}
                                            <div className="price">
                                                {/* Monthly Price */}
                                                {isPricing ? <div className="price1">
                                                    <sup className="color--black">$</sup>
                                                    <span className="color--black">{package3Cost}</span>
                                                    <sup className="validity color--grey">/year</sup>
                                                    {/* Discount Badge */}
                                                    <div className="pricing-discount bg--yellow-400 color--black r-36">
                                                        <h6 className="s-17">Save 20%</h6>
                                                    </div>
                                                </div> : <div className="price2">
                                                    <sup className="color--black">$</sup>
                                                    <span className="color--black">{locations > 1 ? package3CostM : package3Base}</span>
                                                    <sup className="validity color--grey">/ month</sup>
                                                </div>}
                                                {/* Text */}
                                                {/* <p className="color--grey">For growing teams that need more services and flexibility.</p> */}

                                            </div>	{/* End Price */}

                                            <ul className="pricing-features color--black ico-10 ico--green mt-25">
                                                <li><p><span className="flaticon-check" /> Restaurant with multiple locations and different menus</p></li>
                                                <li><p><span className="flaticon-check" /> Pick the number of restaurants below to see pricing</p></li>
                                                <li><p><span className="flaticon-check" /> Upto 4 phone app logins free per location*</p></li>
                                                {/* <li><p><span className="flaticon-check" /> Advanced user permissions</p></li>
                                                <li><p><span className="flaticon-check" /> Multi-team management</p></li>
                                                <li><p><span className="flaticon-check" /> 24/7 Email Support</p></li> */}

                                            </ul>
                                            {/* Button */}
                                            <a href={`${process.env.PUBLIC_URL}/sign-up`} style={{ textDecoration: 'none' }}>

                                                <div id='BColor' className="" style={{ minWidth: '100%', textAlign: 'center', borderRadius: '12px', cursor: 'pointer' }}>Get Started - It's Free</div>
                                            </a>
                                            <p className="p-sm btn-txt text-center color--grey">No credit card required</p>
                                        </div>	{/* END TABLE HEADER */}
                                        {/* PRICING FEATURES */}

                                    </div>
                                </div>	{/* END ADVANCED PLAN */}
                            </div>
                        </div>	{/* PRICING TABLES */}
                    </div>	   {/* End container */}
                </section>	{/* END PRICING-1 */}


                {/* BRANDS-1
			============================================= */}



                {/* PRICING COMPARE
			============================================= */}
                <section id="comp-table" className=" pb-60 pricing-section division">
                    <div className="container">
                        {/* SECTION TITLE */}

                        {/* PRICING COMPARE */}
                        {/* END PRICING COMPARE */}
                        {/* PRICING COMPARE PAYMENT */}
                        <div className="comp-table-payment pricing-section1">
                            <div className="row row-cols-1 row-cols-md-3">
                                {/* Payment Methods */}
                                <div className="col col-lg-5">
                                    <div id="pbox-1" className="pbox mb-40 wow fadeInUp">
                                        {/* Title */}
                                        <h6 className="s-18 w-700">Accepted Payment Methods</h6>
                                        {/* Payment Icons */}
                                        <ul className="payment-icons ico-45 mt-25" style={{ paddingLeft: '0px' }}>
                                            <li><img src="/img/png_icons/visa.png" alt="payment-icon" /></li>
                                            <li><img src="/img/png_icons/am.png" alt="payment-icon" /></li>
                                            <li><img src="/img/png_icons/discover.png" alt="payment-icon" /></li>
                                            <li><img src="/img/png_icons/paypal.png" alt="payment-icon" /></li>
                                            <li><img src="/img/png_icons/jcb.png" alt="payment-icon" /></li>
                                            {/* <li><img src="/img/png_icons/shopify.png" alt="payment-icon" /></li> */}
                                        </ul>
                                    </div>
                                </div>
                                {/* Payment Guarantee */}
                                <div className="col col-lg-4">
                                    <div id="pbox-2" className="pbox mb-40 wow fadeInUp">
                                        {/* Title */}
                                        <h6 className="s-18 w-700">Try It Free For 60 Days!</h6>
                                        {/* Text */}
                                        <p>Explore a hassle-free trial for 60 days, no credit card needed to begin.</p>
                                    </div>
                                </div>
                                {/* Payment Encrypted */}
                                <div className="col col-lg-3">
                                    <div id="pbox-3" className="pbox mb-40 wow fadeInUp">
                                        {/* Title */}
                                        <h6 className="s-18 w-700">SSL Encrypted Payment</h6>
                                        {/* Text */}
                                        <p>Your information is protected by 256-bit SSL encryption.</p>
                                    </div>
                                </div>
                            </div>
                        </div>	{/* END PRICING COMPARE PAYMENT */}
                    </div>	   {/* End container */}
                    <hr className="divider" />

                </section>	{/* END PRICING COMPARE */}
                {/* FAQs-3
			============================================= */}

                <div id="statistic-1" className="pb-40 pt-40 statistic-section division" style={{ background: 'black' }}>

                    <div className="container">
                        {/* STATISTIC-1 WRAPPER */}
                        <div className="statistic-1-wrapper">
                            <div className="row justify-content-md-center row-cols-1 row-cols-md-3">
                                {/* STATISTIC BLOCK #1 */}
                                <div className="col">
                                    <div id="sb-1-1" className="wow fadeInUp">
                                        <div className="statistic-block">
                                            {/* Digit */}
                                            <div className="statistic-block-digit text-center">
                                                <h2 className="s-46 statistic-number" style={{ color: '#FEB300' }}><CounterUp end={90} />%</h2>
                                            </div>
                                            {/* Text */}
                                            <div className="statistic-block-txt color--white">
                                                <p className="p-md">Regularly choose to dine from a curated list of 8 preferred restaurants.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>	{/* END STATISTIC BLOCK #1 */}
                                {/* STATISTIC BLOCK #2 */}
                                <div className="col">
                                    <div id="sb-1-2" className="wow fadeInUp">
                                        <div className="statistic-block">
                                            {/* Digit */}
                                            <div className="statistic-block-digit text-center">
                                                <h2 className="s-46 statistic-number" style={{ color: '#FEB300' }}><CounterUp end={75} />%</h2>
                                            </div>
                                            {/* Text */}
                                            <div className="statistic-block-txt color--white">
                                                <p className="p-md">Opt to personally pick up their food orders.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>	{/* END STATISTIC BLOCK #2 */}
                                {/* STATISTIC BLOCK #3 */}
                                <div className="col">
                                    <div id="sb-1-3" className="wow fadeInUp">
                                        <div className="statistic-block">
                                            {/* Digit */}
                                            <div className="statistic-block-digit text-center">
                                                <h2 className="s-46 statistic-number" style={{ color: '#FEB300' }}>
                                                    <CounterUp end={93} />
                                                    %
                                                </h2>
                                            </div>
                                            {/* Text */}
                                            <div className="statistic-block-txt color--white">
                                                <p className="p-md">Restaurants provide takeout services.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>	{/* END STATISTIC BLOCK #3 */}
                            </div>  {/* End row */}
                        </div>	{/* END STATISTIC-1 WRAPPER */}
                    </div>	 {/* End container */}
                </div>	 {/* END STATISTIC-1 */}
                {/* DIVIDER LINE */}
                <hr className="divider" />


                {/* <div style={{background:'#F6F6FA'}}>
                            <Ct03_2/>
                            </div>

                            <div style={{background:'#fff'}}>
                            <Ct03_1/>
                            </div> */}


                <div style={{ background: '#F6F6FA' }}>
                    <section>
                        <Reviews1_1 />
                    </section>
                </div>

                <section>

                    <div >


                        <Container className="strip" style={{ marginTop: '0px', padding: '50px 20px', background: 'url(https://img.freepik.com/free-vector/abstract-background-with-squares_23-2148995948.jpg) repeat', marginTop: '30px', color: 'black', position: 'relative', zIndex: '9999999999999' }}>
                            <Row >
                                <h2 style={{ marginBottom: '15px', display: 'inline-block' }}>Not Yet Convinced ?</h2>

                                <Col>
                                    <p style={{ color: 'gray', fontSize: '1.2rem', textJustify: 'justify', textAlign: 'justify' }}>Now, small to medium-sized restaurants can access the same benefits that were once exclusive to large chain establishments with the resources to create custom apps. Experience these advantages at a fraction of the cost – no sign-up fees, no deductions from customer checks, just affordable monthly subscriptions.</p>


                                </Col>

                                <Col xl={4} lg={4} md={6} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                        <p className="p-xl" style={{ color: 'gray', fontWeight: '400', marginBottom: '1rem', color: 'black' }}>Curious To Learn More ?</p>
                                        <a href={`${process.env.PUBLIC_URL}/sign-up`} style={{ textDecoration: 'none' }}>

                                            <span id='BColor' className="" style={{ minWidth: '50%', margin: '0 auto', borderRadius: '12px', cursor: 'pointer' }}>Click Here</span>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                        {/* <div style={{textAlign: 'center', alignItems: 'center', color: 'rgb(0,0,0)', fontWeight: '700' }}>
                        </div> */}
                        {/* <Row style={{margin:'0 20px',textAlign:'left',paddingBottom:'30px'}}>
                <Col  xl={8} lg={8} md={12} sm={12} xs={12} style={{margin:'0 30px',textAlign:'center'}}><p style={{textAlign:'left',color:'gray',fontSize:'1.4rem'}}>Now, small to medium-sized restaurants can access the same benefits that were once exclusive to large chain establishments with the resources to create custom apps. Experience these advantages at a fraction of the cost – no sign-up fees, no deductions from customer checks, just affordable monthly subscriptions.</p>
              </Col>
                
                <Col style={{textAlign:'center',marginTop:'4rem'}}>
                <span id='BColor' onClick={handleSubmit} className="" style={{maxWidth:'50%',textAlign:'center',borderRadius:'12px',cursor:'pointer' }}>Click here</span>
                       
                    
                </Col>
                
                </Row>               */}


                    </div>
                </section>



                <div class="priceLastSec" style={{ position: 'relative', margin: '2rem 3rem' }}>
                    <div className="priceCTABg" style={{ position: 'relative', width: '100%' }}>
                        <section id="banner-3" className="py-50 banner-section">
                            <div className="container">
                                {/* BANNER-1 WRAPPER */}
                                <div className="banner-3-wrapper bg--03 bg--scroll r-16">
                                    <div className="banner-overlay" id="priceCTA">
                                        <div className="row">
                                            {/* BANNER-1 TEXT */}
                                            <div className="col">
                                                <div className="banner-3-txt color--white" id="ColorBlack">
                                                    {/* Title */}
                                                    <h3 className="s-40 w-700" id="ColorBlack">Feel free to give it a go – no cost involved!</h3>
                                                    {/* Text */}
                                                    <p className="p-xl" id="ColorBlack" style={{ fontWeight: '600' }}>It only takes a few clicks to get started</p>
                                                    {/* Button */}
                                                    <a href={`${process.env.PUBLIC_URL}/sign-up`} style={{ textDecoration: 'none' }} className='links' id="FooterLink">
                                                        <button id='BBColor' className="" style={{ textAlign: 'center', borderRadius: '12px', cursor: 'pointer' }}>
                                                            Give It a Try And See For Yourself.
                                                            {/* Button Text */}
                                                        </button>
                                                    </a>
                                                    <p className="p-sm btn-txt ico-15 o-85" id="ColorBlack" style={{ fontWeight: '600' }}>
                                                        <span style={{ color: 'black', fontWeight: '600' }} /> Try it for free for 60 days, no credit card needed.
                                                    </p>
                                                </div>
                                            </div>	{/* END BANNER-1 TEXT */}
                                        </div>   {/* End row */}
                                    </div>   {/* End banner overlay */}
                                </div>    {/* END BANNER-1 WRAPPER */}
                            </div>     {/* End container */}
                        </section>

                    </div>
                </div>

                <div className=" pb-60" >
                    <div className="col">
                        <div className="more-questions mt-40">
                            <div className="more-questions-txt bg--white-400 r-100">
                                <p className="p-lg">Have any questions?<span style={{ margin: '0 3px' }}></span>
                                    {/* <NavLink to="/contact-us" className="color--theme">Get in Touch</Link> */}
                                    <a href={`${process.env.PUBLIC_URL}/contact-us`} style={{ textDecoration: 'none', display: 'inline' }}
                                        className="color--theme" >Get In Touch</a>

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* </Layout> */}
        </>
    )
}