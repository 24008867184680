//Library import section
import { RadioButtonChecked, RadioButtonUnchecked, ShoppingCart } from '@material-ui/icons';
// import './App.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React , {useState, useEffect}from 'react';
import {makeStyles} from '@material-ui/core/styles'
import {Paper,Container, Button , Grid, Card , CardContent , Typography , Divider ,InputLabel, Select , MenuItem , FormHelperText , Box, CssBaseline, AppBar} from '@material-ui/core'
import ReactDOM from 'react-dom';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { globalclasses } from './globalcsss';
import * as APIinterfaces from "./ApiInterface";
import Header from './Header';

const useStyles = makeStyles(theme =>({

  header:{
    background:'white',
    border:'none',
    color:'#FEB300',
  },
  packagebutton:{
    
    whiteSpace:'nowrap',
    [theme.breakpoints.up('sm')]: {
      width:'100%',
      fontSize:"13px"
 },
 [theme.breakpoints.up('md')]: {
  width:'115%'
},

[theme.breakpoints.up('lg')]: {
  width:'100%'
}
  },

  // packagecard:{

  //   background:'white',
  //   border:'none',
  //   color:'#000',
  //   boxShadow:'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  //   borderRadius:'15px',
  //   height:'410px',
  //   width:'auto-50px',
  // },
  package1:{
    background:'white',
   border:'none',
   color:'#000',
   boxShadow:'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
   borderRadius:'15px',
   height:'410px',
   width:'365px',
   [theme.breakpoints.up('sm')]: {
     marginLeft:'0px',
     marginRight:'0px',
     marginBottom:'30px'
},
[theme.breakpoints.up('md')]: {
marginLeft:'0px',
marginRight:'15px',
marginBottom:'30px'
},
[theme.breakpoints.up('lg')]: {
marginLeft:'15px',
marginRight:'15px',
marginBottom:'15px'
},

 },
  package2:{
     background:'white',
    border:'none',
    color:'#000',
    boxShadow:'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    borderRadius:'15px',
    height:'410px',
    width:'365px',
    [theme.breakpoints.up('sm')]: {
      marginLeft:'0px',
      marginRight:'0px',
      marginBottom:'30px'
},
[theme.breakpoints.up('md')]: {
marginLeft:'15px',
marginRight:'0px',
marginBottom:'30px'
},
[theme.breakpoints.up('lg')]: {
marginLeft:'15px',
marginRight:'15px',
marginBottom:'15px'
}
  },
  package3:{
    background:'white',
   border:'none',
   color:'#000',
   boxShadow:'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
   borderRadius:'15px',
   height:'410px',
   width:'365px',
   [theme.breakpoints.up('sm')]: {
     marginLeft:'0px',
     marginRight:'0px',
     marginBottom:'30px'
},
[theme.breakpoints.up('md')]: {
marginLeft:'0px',
marginRight:'15px',
marginBottom:'30px'
},
[theme.breakpoints.up('lg')]: {
marginLeft:'15px',
marginRight:'15px',
marginBottom:'15px'
}
 },
  button:{
    background:'#feb300',
    border:'none',
    color:'#000',
  },
  footer: {
    backgroundColor: '#212121 !important',
    // marginTop:'10vh'
  }
}))
var price1,price2,price3;
var basePrice2,additionalPrice2,basePrice3,additionalPrice3;


function PackageSelection() {
  const classes= useStyles();
  const globalclass =globalclasses()
  const [radio, setRadio] = React.useState("Pkg1");
  const [locations, setLocations] = React.useState(0);
  const [locations1, setlocations1]=React.useState(1);
  const [locations2, setlocations2]=React.useState(0);
  const [locations3, setlocations3]=React.useState(0);
  const [disabled2, setDisabled2]=React.useState(true);
  const [disabled3, setDisabled3]=React.useState(true);
  const [price, setPrice]=React.useState(price1);


  const handnum2 = (event) =>{
    setlocations2(event.target.value)
    // setPrice(Number(5)+locations2)
  }

  const handnum3 = (event) =>{ 
    setlocations3(event.target.value)
    // setPrice(Number(5)*locations3)
  }

  useEffect(() => {
    fetchPriceDetails()
  },[]);

  function fetchPriceDetails(){
        fetch(APIinterfaces.resturantsubscpkginfoview,{
            method: 'get',
            mode: 'cors',
            headers: {
              "Content-type": "application/json",
            },
          }).then(response=>{
            if(response.status === 200){
              console.log("response: 200")
            }
            else if(response.status === 400){
              console.log("response 400")
              }
              else if(response.status === 401){
                console.log("response 401")
                }
                else if(response.status === 404){
                  console.log("response 404")
                  }
            // console.log("response.body: "+response)
            return response.json()
          })
          .then((json) => {
            console.log(json)
            let packageInfo=json.subscriptionPackageInfo
            price1=packageInfo[0].pkgCost;
            setPrice(price1);

            basePrice2=packageInfo[1].basePrice;
            additionalPrice2=packageInfo[1].additionalPriceperLocation
            basePrice3=packageInfo[2].basePrice;
            additionalPrice3=packageInfo[2].additionalPriceperLocation;
          })
  }

//Calcularing the Price based on the selection of package and number of locations
  useEffect(() => {
      if(radio==="Pkg1")
      {
          setPrice(price1)
          setLocations(1);
          localStorage.setItem('basePrice',price1)
      }
      else if(radio==="Pkg2")
      {
          price2=basePrice2+(additionalPrice2*locations2);
          setPrice(price2)
          setLocations(locations2);
          localStorage.setItem('basePrice',basePrice2)
      }
      else if(radio==="Pkg3")
      {
        price3=basePrice3+(additionalPrice3*locations3);
        setPrice(price3)
        setLocations(locations3);
        localStorage.setItem('basePrice',basePrice3)
      }
    }, [radio,locations2,locations3]
  );

  const handlePackageChange1 = (event) => {
    setRadio(event.target.value);
    setlocations2(0)
    setlocations3(0)
    setlocations1(1)
    setDisabled3(true)
    setDisabled2(true)
    setPrice(5)
  };

  const handlePackageChange2 = (event) => {
    setRadio(event.target.value);
    setDisabled3(true)
    setDisabled2(false)
    setlocations1(0)
    setlocations3(0)
    setlocations2(2)
    setPrice(7)
  };

  const handlePackageChange3 = (event) => {
    setRadio(event.target.value);
    setDisabled2(true)
    setDisabled3(false)
    setlocations1(0)
    setlocations2(0)
    setlocations3(2)
    setPrice(10)
  };

  const handleNext = (event) => {
      if(validate()){
        localStorage.setItem("package", radio)
        localStorage.setItem("price", price)
        localStorage.setItem("locations", locations)
        localStorage.setItem("cartAmount", price)
        window.location.assign("payment-and-billing")
      }
      else
      alert("didn't validate");
  }

//Form Validation
  const validate = () => {
        if(radio==="Pkg1" || radio==="Pkg2" || radio==="Pkg3")
        if(locations1===1 || locations2>=2||locations3>=2)
          return true;
        else
          return false;
  }

//Front end design
  return (
    <div style={{backgroundColor:'#fafafa'}}
    >
     <Header/>
                  <Container className={classes.head} style={{margin:'50px 0px 100px 4%',padding:'0 50px'}}>
    <form style={{backgroundColor:'white',width:'auto'}}>
    <div className={`${'description-wrap'} ${globalclass.body}`} style={{backgroundColor:'#fafafa',height:'auto'}}>
            <Paper elevation={3} className={globalclass.paperwidth} style={{margin:'0px'}}>
    <Grid container spacing={1} style={{padding:'0px',}}>
          <Grid item xs={12} style={{marginLeft:'8%',marginBottom:'10px',paddingTop:'0px',paddingBottom:'0px'}}>
                {/* <Card  className={classes.header}> */}{/* <CardContent> */}
                                <Typography variant="h4" className={classes.textCenter} style={{textAlign:'center'}}>
                                          Select a Package - Get 60 Days Free!
                                </Typography>
                              <Typography variant="h6" component="h2" style={{textAlign:"center",color:'#212121', marginTop: "10px"}}>
                                (Take a look at some of our best offers)
                              </Typography>
                        {/* </CardContent> */}
              {/* </Card> */}
          </Grid>
       
          <Grid item xs={2} style={{alignContent:'center'}}>
            <Typography variant="h5" component="h2" style={{textAlign:"left" , color:"black",fontSize:'20px',marginLeft:'20px',whiteSpace:'nowrap'}}>
            Total Cost: ${price}
            </Typography>
          </Grid>
          <Grid item xs={11} style={{alignContent:'center'}}> 
          </Grid>
          <Grid container style={{textAlign:'center'}}>
        <Grid item sm={12} md={6} lg={4} xl={4} align="center">
        <Card className={classes.package1} variant="outlined"  style={{boxShadow:'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',marginBottom:'30px',}}>
        <CardContent style={{backgroundColor:'#feb300', color:'white'}}>
          <Typography variant="h5" component="h2" style={{textAlign:"center" , fontWeight:"bold" ,fontSize:'25px', padding: "0px"}}>
            PACKAGE 1
          </Typography>
          </CardContent>
          <CardContent style={{backgroundColor:'#516AD3' , padding: "5px"}}>
              <Typography variant="h6" component="h2" style={{textAlign:"center" ,color:'white', fontWeight:"bold",fontSize:'15px',padding:'0px'}}>
                MOST POPULAR
              </Typography>
          </CardContent>
        <CardContent>
        <Typography component="h2" style={{textAlign:"center"  , fontWeight:"bold", fontSize:'15px'}}>
          Best suited for restaurant with a single location
        </Typography>
        <Typography  component="h2" style={{textAlign:"center" , marginTop: "60px", fontSize:'15px'}}>
          Upto 4 phone app logins free*
        </Typography>
      </CardContent>
      <div style={{textAlign:"center",marginTop:'46px'}}> 
      {/* <label ><input type="radio" checked={radio === "Package 1"} value="Package 1" onChange={handlePackageChange}/>Select Package</label> */}
      
      <FormControl component="fieldset">
            <RadioGroup aria-label="package1" name="package" id="package1" onChange={handlePackageChange1}>
              <FormControlLabel
                    checked={radio === "Pkg1"}
                    value="Pkg1"
                    id="pkg1"
                    control={<Radio id="pkg1Radio"/>}
                    label="Select Package 1"
              />
            </RadioGroup>
      </FormControl>
      </div>
    </Card>
        </Grid>


        <Grid item sm={12} md={6} lg={4} xl={4} align='center'>
        <Card className={classes.package2} variant="outlined" style={{boxShadow:'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',marginBottom:'30px'}}>

      <CardContent style={{backgroundColor:'#feb300', color:'white'}}>
        <Typography variant="h5" component="h2" style={{textAlign:"center" , fontWeight:"bold",fontSize:'25px', padding: "0px"}}>
          PACKAGE 2
        </Typography>
      </CardContent>
      <CardContent>
        <Typography component="h2" style={{textAlign:"center"  , fontWeight:"bold", fontSize:'15px'}}>
              Restaurant With Multiple Locations (Same Menus, Different Locations)
        </Typography>
        <Typography component="h2" style={{textAlign:"center", marginTop: "25px"  ,marginBottom: "14px", fontSize:'15px' }}>
              Pick The Number Of Locations Below To See Pricing
        </Typography>
        <Typography  component="h2" style={{textAlign:"center", marginTop: "10px", fontSize:'15px' }}>
        Upto 4 phone app logins free per location*
        </Typography>

        <InputLabel
                  disabled={disabled2}
                  style={{marginBottom:'10px',display:'inline',marginRight:'30px', fontSize:'15px'}}
        >
                  #Locations:
        </InputLabel>
        <Select
            disabled={disabled2}
            style={{width:"75px",height:'45px', marginBottom:'10px', marginTop:'10px'}}
              variant='outlined'
              id='locations2'
              name='locations2'
              onChange={handnum2}
              value={locations2}
        >
      
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={11}>11</MenuItem>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={13}>13</MenuItem>
          <MenuItem value={14}>14</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={16}>16</MenuItem>
          <MenuItem value={17}>17</MenuItem>
          <MenuItem value={18}>18</MenuItem>
          <MenuItem value={19}>19</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={21}>21</MenuItem>
          <MenuItem value={22}>22</MenuItem>
          <MenuItem value={23}>23</MenuItem>
          <MenuItem value={24}>24</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={26}>26</MenuItem>
          <MenuItem value={27}>27</MenuItem>
          <MenuItem value={28}>28</MenuItem>
          <MenuItem value={29}>29</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={31}>31</MenuItem>
          <MenuItem value={32}>32</MenuItem>
          <MenuItem value={33}>33</MenuItem>
          <MenuItem value={34}>34</MenuItem>
          <MenuItem value={35}>35</MenuItem>
          <MenuItem value={36}>36</MenuItem>
          <MenuItem value={37}>37</MenuItem>
          <MenuItem value={38}>38</MenuItem>
          <MenuItem value={39}>39</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={41}>41</MenuItem>
          <MenuItem value={42}>42</MenuItem>
          <MenuItem value={43}>43</MenuItem>
          <MenuItem value={44}>44</MenuItem>
          <MenuItem value={45}>45</MenuItem>
          <MenuItem value={46}>46</MenuItem>
          <MenuItem value={47}>47</MenuItem>
          <MenuItem value={48}>48</MenuItem>
          <MenuItem value={49}>49</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
        <div style={{textAlign:"center"}}> 
        {/* <label><input type="radio" checked={radio === "Package 2"} value="Package 2" onChange={handlePackageChange1}/>Select Package</label> */}
        <FormControl component="fieldset">
              <RadioGroup
                    aria-label="package2"
                    name="package"
                    id="package2"
                    onChange={handlePackageChange2}
              >
                    <FormControlLabel
                          checked={radio === "Pkg2"}
                          value="Pkg2"
                          id="pkg2"
                          control={<Radio id="pkg2Radio"/>}
                          label="Select Package 2"
                    />
              </RadioGroup>
        </FormControl>
      </div>
      </CardContent>
    </Card>
        </Grid>


        <Grid item sm={12} md={6} lg={4} xl={4}  align="center">
        <Card className={classes.package3} variant="outlined" style={{boxShadow:'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',marginBottom:'30px'}}>
      <CardContent style={{backgroundColor:'#feb300', color:'white'}}>
      <Typography variant="h5" component="h2" style={{textAlign:"center" ,paddingTop:'8px', fontWeight:"bold",fontSize:'25px', padding: "0px"}}>
          PACKAGE 3
        </Typography>
        </CardContent>
        <CardContent>
        <Typography component="h2" style={{textAlign:"center"  , fontWeight:"bold", fontSize:'15px'}}>
        Group Of Restaurants - Multiple Restaurants Under One Ownership (Different Locations, Different Menus)
        </Typography>
        <Typography component="h2" style={{textAlign:"center" , marginTop: "3px" ,marginBottom: "10px", fontSize:'15px'}}>
        Pick The Number Of Locations Below To See Pricing
        </Typography>
        <Typography  component="h2" style={{textAlign:"center", marginTop: "14px", fontSize:'15px'}}>
        Upto 4 phone app logins free per location*
        </Typography>
        <div style={{textAlign:"center"}}>
        <InputLabel disabled={disabled3} style={{marginLeft:'30px',marginBottom:'10px' , display:'inline',marginRight:'30px', fontSize:'15px'}}>#Locations:</InputLabel>
        <Select
              disabled={disabled3}
                style={{width:"75px",height:'45px', marginBottom:'10px', marginTop:'10px'}}
                variant='outlined'
                id='locations3'
                name='locations3'
                value={locations3}
                onChange={handnum3}
        >
        
        <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={11}>11</MenuItem>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={13}>13</MenuItem>
          <MenuItem value={14}>14</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={16}>16</MenuItem>
          <MenuItem value={17}>17</MenuItem>
          <MenuItem value={18}>18</MenuItem>
          <MenuItem value={19}>19</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={21}>21</MenuItem>
          <MenuItem value={22}>22</MenuItem>
          <MenuItem value={23}>23</MenuItem>
          <MenuItem value={24}>24</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={26}>26</MenuItem>
          <MenuItem value={27}>27</MenuItem>
          <MenuItem value={28}>28</MenuItem>
          <MenuItem value={29}>29</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={31}>31</MenuItem>
          <MenuItem value={32}>32</MenuItem>
          <MenuItem value={33}>33</MenuItem>
          <MenuItem value={34}>34</MenuItem>
          <MenuItem value={35}>35</MenuItem>
          <MenuItem value={36}>36</MenuItem>
          <MenuItem value={37}>37</MenuItem>
          <MenuItem value={38}>38</MenuItem>
          <MenuItem value={39}>39</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={41}>41</MenuItem>
          <MenuItem value={42}>42</MenuItem>
          <MenuItem value={43}>43</MenuItem>
          <MenuItem value={44}>44</MenuItem>
          <MenuItem value={45}>45</MenuItem>
          <MenuItem value={46}>46</MenuItem>
          <MenuItem value={47}>47</MenuItem>
          <MenuItem value={48}>48</MenuItem>
          <MenuItem value={49}>49</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
        </div>
        <div style={{textAlign:"center"}}> 
        {/* <label><input type="radio" checked={radio === "Package 3"} value="Package 3" onChange={handlePackageChange2}/>Select Package</label> */}
            <FormControl component="fieldset">
                  <RadioGroup aria-label="package3" name="package" id="package3" onChange={handlePackageChange3}>
                        <FormControlLabel checked={radio === "Pkg3"} value="Pkg3" id="pkg3" control={<Radio id="pkg3Radio"/>} label="Select Package 3"/>
                  </RadioGroup>
            </FormControl>
      </div>
      </CardContent> 
    </Card>
        </Grid>
        </Grid>


        {/* <Grid item xs={12} md={6} style={{ margin:"20px auto 30px auto"}}>
              <Button
                      // startIcon={<ShoppingCart/>}
                      className={classes.button}
                      variant="contained"
                      fullWidth
                      style={{height:'50px',fontWeight:'bold'}}
                      onClick={handleNext}
              >
              Click Next To Continue
              </Button>
              
        </Grid> */}
        <Grid item xs={4}
        ></Grid>
        <Grid item xs={4} sm={4} md={4} style={{ textAlign: "right", marginRight: "5vh" }}>
          <Button
                variant="contained"
                id="next"
                className = {classes.packagebutton} color="primary" onClick={handleNext}>Click Next To Continue <ArrowForwardIcon style={{fontSize:'20px',marginLeft:'5px'}}/> </Button>
        </Grid>
    </Grid>
    </Paper>
    </div>
    </form>
    </Container>
    

    </div>
  );
}

export default PackageSelection;