//Library import section
import React, {useState , useEffect} from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import {Paper,Button , Grid, Card , Checkbox,CardContent,FormControlLabel , Divider ,InputLabel, Select , MenuItem , FormHelperText , Radio , TextField, Icon, Container} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ReactDOM from 'react-dom';
import {globalclasses} from './globalcsss'
// import WebsiteHeader from '../../components/Navbars/WebsiteNavBar';
import { makeStyles, styled } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Component } from 'react';
import { param } from 'jquery';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import {cognitoUser} from './CognitoVariables';
import Header from './Header';
// import Footer from '../../components/Website/footer';



const useStyles = makeStyles({
  button:{
      background:'#feb300',
      border:'none',
      color:'#000',
      fontWeight:'bold'
  },
  footer: {
    backgroundColor: '#212121 !important',
    marginTop:'7vh'
  }
})


function PhoneNumberVerification() {

  const StyledTextField = styled(TextField)({
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
       '-webkit-appearance': 'none',
       margin: 0,
    },
    '& input[type="number"]': {
       '-moz-appearance': 'textfield',
    },
   });
  
//  Declaring constants
  var idToken="";
  var accessToken="";
  var refreshToken="";
  const globalclass = globalclasses();

  const classes= useStyles();
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);

  // const [phoneno , setPhoneno] = React.useState(localStorage.getItem('phone'));
  // const [username , setUsername] = React.useState(localStorage.getItem('email'));
  // const [password,setPassword] = React.useState(localStorage.getItem('password'));
  var phoneno=localStorage.getItem('phone');
  var username=localStorage.getItem('email');
  var password =localStorage.getItem('password')
  var maskedPhoneNumber="******"+(phoneno%10000);

  const [PhoneNumberVerification, setPhoneNumberVerification] = useState({
    dig1:'',
    dig2:'',
    dig3:'',
    dig4:'',
    dig5:'',
    dig6:''
    })

    //Routing to sign-up page
    const handleBack = e =>{
      window.location.assign("sign-up")
    }

    //Handling input
    const handleInputChange = e => {
      const { name, value } = e.target
      
      setPhoneNumberVerification({
        ...PhoneNumberVerification,
        [name]: value
      })
    }

    //On input move to next textbox
    useEffect(() => {
      if(PhoneNumberVerification.dig1.length >= 1){
        document.getElementById("dig2").focus()
      }
      if(PhoneNumberVerification.dig2.length >= 1){
        document.getElementById("dig3").focus()
      }
      if(PhoneNumberVerification.dig3.length >= 1){
        document.getElementById("dig4").focus()
      }
      if(PhoneNumberVerification.dig4.length >= 1){
        document.getElementById("dig5").focus()
      }
      if(PhoneNumberVerification.dig5.length >= 1){
        document.getElementById("dig6").focus()
      }

      if(PhoneNumberVerification.dig1.length >= 1 && PhoneNumberVerification.dig2.length >= 1 && PhoneNumberVerification.dig3.length >= 1 && PhoneNumberVerification.dig4.length >= 1 && PhoneNumberVerification.dig5.length >= 1 && PhoneNumberVerification.dig6.length >= 1){
        document.getElementById("verifyPhoneNumber").focus()
      }
    });


    // const Username = emailID;
    // var userData= {
    //   Username: emailID,
    //   Pool:userPool
    // }
    
    // var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

// Resending the OTP
  const handleResendOTP = (event) => {
      cognitoUser.resendConfirmationCode(function(err, result) {
        if (err) {
          let errorVerify=(err.message || JSON.stringify(err));
          document.getElementById("errorVerify").innerHTML=errorVerify
          return;
        }
        let resendMsg="OTP successfully resent to your phone number"
        document.getElementById("errorVerify").innerHTML=resendMsg
        // console.log('call result: ' + result);
        
      });
  }

    var otp="";

    const handleVerify= (event) => {

      let errorVerify=""
      document.getElementById("errorVerify").innerHTML=errorVerify
    
      if(PhoneNumberVerification.dig1==='' || PhoneNumberVerification.dig2==='' || PhoneNumberVerification.dig3==='' || PhoneNumberVerification.dig4==='' || PhoneNumberVerification.dig5==='' || PhoneNumberVerification.dig6===''){
        let errorVerify="Invalid OTP"
        document.getElementById("errorVerify").innerHTML=errorVerify
      }
      else{
        otp=PhoneNumberVerification.dig1+""+PhoneNumberVerification.dig2+""+PhoneNumberVerification.dig3+""+PhoneNumberVerification.dig4+""+PhoneNumberVerification.dig5+""+PhoneNumberVerification.dig6
        console.log("otp1",otp)
        //const Username=localStorage.getItem('email');
        verifyCode(otp);
      }
    }
   

     const verifyCode = (otp) => { 
      //verify otp in cognito
      cognitoUser.confirmRegistration (otp, true,function(err, result) {
        if (err) {
          
          console.log("this is confirm reg. error",err)
          let errorVerify=(err.message || JSON.stringify(err));
          if(errorVerify==="User cannot be confirmed. Current status is CONFIRMED"){
              //autoLogin();
          }
          else{
            document.getElementById("errorVerify").innerHTML=errorVerify
            return;
          }
        }
        else{
        console.log("result",result)
        autoLogin();
        }
      });
    }
  
    
//verify UserCode ends


//Automatic login to verify email
const autoLogin = () =>{
  // Automatic log in starts
  var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username,
    Password: password,
});

cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function(result) {
      console.log("bcud",result)
      accessToken=result.getAccessToken().getJwtToken();
      idToken=result.getIdToken().getJwtToken();
      refreshToken=result.getRefreshToken().getToken();
        // console.log('access token + ' + accessToken);
        // console.log('id token + ' + idToken);
        // console.log('refresh token + ' + refreshToken);
        // window.location.href = '/user/dashboard';
        localStorage.setItem('accessToken',accessToken)
        localStorage.setItem('idToken',idToken)
        localStorage.setItem('refreshToken',refreshToken)
        // cognitoUser.getSession((e)=> console.log(e || 'session acquired' ))
        // //alert("signin success")

//sending otp for email verifn
        var params = {
          AccessToken: accessToken, /* required */
          AttributeName: 'email', /* required */
        };
        var AWS=require("aws-sdk");
        AWS.config.region = 'us-west-2';
        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

        cognitoidentityserviceprovider.getUserAttributeVerificationCode(params, function(err, data) {
                if (err){// an error occurred
                    // console.log(err, err.stack);
                    let errorVerify=err.message;
                    document.getElementById("errorVerify").innerHTML=errorVerify
                    // //alert("Error occurred")
                }
                else{// successful response, hence routing to email verification
                    // console.log(data);
                    window.location.assign("email-verification")
                }
        });
//sending otp for email verifn    ends        
        
      },
    onFailure: function(err) {
        // console.log(err);
        let errorVerify="Invalid OTP"
        document.getElementById("errorVerify").innerHTML=errorVerify
    },
}); 
//Automatic log in ends
}
//Front end design
    return (
    
        <div>
          {/* <WebsiteHeader/> */}
          <Header/>
                  <Container className={classes.head} style={{margin:'50px 50px 100px'}}>
                  <div className={`${'description-wrap'} ${globalclass.body}`} style={{backgroundColor:'#fafafa',height:'auto'}}>
                        <Paper elevation={3} >
                              
                                <Grid item xs={12} className={globalclass.paperalign}>
                              <form autoComplete="off">
                                <Grid container spacing={0} style={{paddingBottom:'20px'}} >
                                  <Grid item xs={1}>
                                    <Button
                                            onClick={handleBack}
                                            style={{backgroundColor:'white'}}
                                    >
                                            <ArrowBackIcon
                                                style={{fontSize:'30px',marginLeft:'5px'}}
                                            />

                                    </Button>
                                  </Grid>
                                  <Grid item xs={10}>
                                  <Typography variant="h4" className={classes.textCenter} style={{textAlign:'center'}}>
                                          Phone Number Verification
                                  </Typography>
                                  <Typography style={{textAlign:'center', width:'auto'}}>
                                          (Please Verify Your Phone Number)
                                  </Typography>


                                  </Grid>  
                                </Grid>
                                    <Divider variant='middle' style={{padding:'1px',marginBottom:'20px'}}/>
                                
                                    <Typography style={{textAlign:'center'}}>
                                              Enter One Time Password Sent To: <br></br>{maskedPhoneNumber}
                                    </Typography><br></br>
                                <Grid  style={{textAlign:'center'}}>
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig1"
                                              error
                                              name='dig1'
                                              value={PhoneNumberVerification.dig1}
                                              onChange={handleInputChange}
                                              className={globalclass.responsiveotp}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                      
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig2"
                                              error={true}
                                              name='dig2'
                                              value={PhoneNumberVerification.dig2}
                                              onChange={handleInputChange}
                                              className={globalclass.responsiveotp}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig3"
                                              name='dig3'
                                              value={PhoneNumberVerification.dig3}
                                              onChange={handleInputChange}
                                              className={globalclass.responsiveotp}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig4"
                                              name='dig4'
                                              value={PhoneNumberVerification.dig4}
                                              onChange={handleInputChange}
                                              className={globalclass.responsiveotp}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig5"
                                              name='dig5'
                                              value={PhoneNumberVerification.dig5}
                                              onChange={handleInputChange}
                                              className={globalclass.responsiveotp}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                     <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig6"
                                              name='dig6'
                                              value={PhoneNumberVerification.dig6}
                                              onChange={handleInputChange}
                                              className={globalclass.responsiveotp}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                    </Grid>
                                <Grid item xs={12} style={{textAlign:'center'}}>
                                    <FormHelperText id="errorVerify" style={{ color: '#f44336',textAlign:'center',margin:'1%',marginBottom:'1%'}}> </FormHelperText>
                                </Grid>
                                <Grid item xs={12} style={{textAlign:'center'}}
                                >

                                <Button
                                      variant="contained"
                                      id="verifyPhoneNumber"
                                      style={{width:'200px',background:'#Feb300'}}
                                      onClick={handleVerify}
                                >
                                      Verify
                                      <ArrowForwardIcon style={{fontSize:'20px',marginLeft:'5px'}}
                                      />
                                      
                                </Button>
                                </Grid>
                                <Grid item xs={12} style={{textAlign:'center'}}>
                                        <Button
                                              variant="outlined"
                                              style={{marginTop:'10px',backgroundColor:'white',color:'#302bc3'}}
                                              onClick={handleResendOTP}
                                              id="resendOTP"
                                        >Resend OTP</Button>
                                </Grid>
                              </form>
                              </Grid>
                        
                        </Paper>
                    </div>
                  </Container>
                  {/* <div className={globalclass.footer}>
                    <Container>
                      <Footer />
                    </Container>
                  </div> */}
        </div>    
      );
    }

export default PhoneNumberVerification