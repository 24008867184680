//Library import section
import React, {useState , useEffect} from 'react';
import {Paper,Button , Grid, Card , Checkbox,CardContent,FormControlLabel , Divider ,InputLabel, Select , MenuItem , FormHelperText , Radio , TextField, Icon, Container} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ReactDOM from 'react-dom';
import { makeStyles, styled } from '@material-ui/core/styles';
import {globalclasses} from './globalcsss'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import {userPool,cognitoUser} from './CognitoVariables';
import Header from './Header';


const useStyles = makeStyles({
  button:{
      background:'#feb300',
      border:'none',
      color:'#000',
      fontWeight:'bold'
  },
  footer: {
    backgroundColor: '#212121 !important',
    marginTop:'7vh'
  }
})


const StyledTextField = styled(TextField)({
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
       '-webkit-appearance': 'none',
       margin: 0,
    },
    '& input[type="number"]': {
       '-moz-appearance': 'textfield',
    },
   });

function EmailVerification() {

  //  Declaring constants
  const globalclass = globalclasses();
  const classes= useStyles();
  const [emailID , setEmailID] = React.useState(localStorage.getItem('email'));
  const [phoneno , setPhoneno] = React.useState(localStorage.getItem('phone'));
  const [accessToken,setAccessToken] = React.useState(localStorage.getItem('accessToken'));
  
  const [EmailVerification, setEmailVerification] = useState({
    dig1:'',
    dig2:'',
    dig3:'',
    dig4:'',
    dig5:'',
    dig6:''
    })

    //Routing to previous/phone-number-verification page
    const handleBack = e =>{
      window.location.assign("phone-number-verification")
    }

    const handleInputChange = e => {
      const { name, value } = e.target
      
      setEmailVerification({
        ...EmailVerification,
        [name]: value
      })
    }

//On input move to next textbox
    useEffect(() => {
      if(EmailVerification.dig1.length >= 1){
        document.getElementById("dig2").focus()
      }
      if(EmailVerification.dig2.length >= 1){
        document.getElementById("dig3").focus()
      }
      if(EmailVerification.dig3.length >= 1){
        document.getElementById("dig4").focus()
      }
      if(EmailVerification.dig4.length >= 1){
        document.getElementById("dig5").focus()
      }
      if(EmailVerification.dig5.length >= 1){
        document.getElementById("dig6").focus()
      }
      if(EmailVerification.dig1.length >= 1 && EmailVerification.dig2.length >= 1 && EmailVerification.dig3.length >= 1 && EmailVerification.dig4.length >= 1 && EmailVerification.dig5.length >= 1 && EmailVerification.dig6.length >= 1){
        document.getElementById("verifyEmail").focus()
      }
    });

//Resend Verification code ends    
    const handleResendOTP = (event) => {
          var params = {
            AccessToken: accessToken, /* required */
            AttributeName: 'email', /* required */
          };
          var AWS=require("aws-sdk");
          AWS.config.region = 'us-west-2';
          var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

          cognitoidentityserviceprovider.getUserAttributeVerificationCode(params, function(err, data) {
                  if (err){// an error occurred
                      let resendMsg=err.message;
                      document.getElementById("errorVerify").innerHTML=resendMsg
                    }
                  else{// successful response
                      // console.log(data);
                      let resendMsg="OTP successfully resent to your email ID"
                      document.getElementById("errorVerify").innerHTML=resendMsg
                  }
          });
//Resend Verification code ends
    }

  var otp="";
    const handleVerify= (event) => {
      let errorVerify=""
      document.getElementById("errorVerify").innerHTML=errorVerify

      if(EmailVerification.dig1==='' || EmailVerification.dig2==='' || EmailVerification.dig3==='' || EmailVerification.dig4==='' || EmailVerification.dig5==='' || EmailVerification.dig6===''){
        // alert("Invalid OTP")
          let errorVerify="Invalid OTP"
          document.getElementById("errorVerify").innerHTML=errorVerify
      }
      else{
      otp=EmailVerification.dig1+""+EmailVerification.dig2+""+EmailVerification.dig3+""+EmailVerification.dig4+""+EmailVerification.dig5+""+EmailVerification.dig6
        localStorage.setItem('OTP', otp)
        verifyCode(otp);
      }
    }

    // var userData= {
    //   Username: emailID,
    //   Pool:userPool
    // }
    
    // var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    
//verify UserCode starts(using aws cognito)
    const verifyCode = (otp) => {
          var params = {
            AccessToken: accessToken, /* required */
            AttributeName: 'email', /* required */
            Code:otp
          };

          var AWS=require("aws-sdk");
          AWS.config.region = 'us-west-2';
          var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

          cognitoidentityserviceprovider.verifyUserAttribute(params, function(err, data) {
            if (err){// an error occurred
                let errorVerify=err.message;
                document.getElementById("errorVerify").innerHTML=errorVerify      
              }
            else{// successful response
              console.log("dat",data)
              window.location.assign("package-selection");
              function signUp(){

              const attributeList = [];
      
              const userName=signUp.email;
              const password=signUp.password;
        
              const dataGivenName = {
                Name: 'given_name',
                Value: signUp.firstName
              }
              const dataFamilyName = {
                Name: 'family_name',
                Value: signUp.lastName
              }
              const dataPhoneNumber ={
                Name: 'phone_number',
                Value: "+"+signUp.phone
              }
              const dataEmail = {
                Name: 'email',
                Value: signUp.email
              }
        
              const dataUserID = {
                Name: 'custom:userID',
                Value: "dummy"
              }
              const dataRestaurantID = {
                Name: 'custom:restaurantID',
                Value: "dummy"
              }
              const dataBuildStageID = {
                Name: 'custom:buildStageID',
                Value: "dev"
              }
              const dataUserTypeID = {
                Name: 'custom:userTypeID',
                Value: "dummy"
              }
        
              const attributeGivenName = new AmazonCognitoIdentity.CognitoUserAttribute(dataGivenName);
              const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
              const attributeFamilyName = new AmazonCognitoIdentity.CognitoUserAttribute(dataFamilyName);
              const attributePhoneNumber = new AmazonCognitoIdentity.CognitoUserAttribute(dataPhoneNumber);
              const attributeUserID = new AmazonCognitoIdentity.CognitoUserAttribute(dataUserID);
              const attributeRestaurantID = new AmazonCognitoIdentity.CognitoUserAttribute(dataRestaurantID);
              const attributeBuildStageID = new AmazonCognitoIdentity.CognitoUserAttribute(dataBuildStageID);
              const attributeUserTypeID = new AmazonCognitoIdentity.CognitoUserAttribute(dataUserTypeID);
              attributeList.push(attributeGivenName);
              attributeList.push(attributeFamilyName);
              attributeList.push(attributeEmail);
              attributeList.push(attributePhoneNumber);
              attributeList.push(attributeUserID);
              attributeList.push(attributeRestaurantID);
              attributeList.push(attributeBuildStageID);
              attributeList.push(attributeUserTypeID);
        
              //Signing up the user using aws cognito
              userPool.signUp(userName,password, attributeList, null, (err, data) => {
                if (err){
                  console.error(err);
                  let errorSubmit=err.message;
                  document.getElementById("errorSubmit").innerHTML=errorSubmit
                }
                else{
                  window.location.assign("package-selection");
                }
                // console.log(data);
              });
            }
              }
          });
//verifyUserCode ends
    }

//Front end design
    return (
    
        <div>
           <Header/>
                  <Container className={classes.head} style={{margin:'50px 50px 100px'}}>
                  <div className={`${'description-wrap'} ${globalclass.body}`} style={{backgroundColor:'#fafafa',height:'auto'}}>
                         <Paper elevation={3} >
                         <Grid item xs={12} className={globalclass.paperalign} >
                              <form autoComplete="off">
                                <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                                  <Grid item xs={1}>
                                    <Button
                                            onClick={handleBack}
                                            style={{backgroundColor:'white'}}
                                    >
                                            <ArrowBackIcon
                                                style={{fontSize:'30px',marginLeft:'5px'}}
                                            />
                                    </Button>
                                  </Grid>
                                  <Grid item xs={10}>
                                  <Typography variant="h4"  style={{textAlign:'center'}}>
                                          Email Verification
                                  </Typography>
                                  <Typography style={{textAlign:'center', width:'auto'}}>
                                          (Please Verify Your Email Address)
                                  </Typography>

                                  </Grid>  
                                </Grid>
                                    <Divider variant='middle' style={{padding:'1px',marginBottom:'20px'}}/>
                                
                                    <Typography style={{textAlign:'center'}}>
                                              Enter One Time Password Sent To: <br></br>{emailID}
                                    </Typography><br></br>
                                    <Grid item xs={12} style={{textAlign:'center'}}>
                                    <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig1"
                                              name='dig1'
                                              value={EmailVerification.dig1}
                                              className={globalclass.responsiveotp}
                                              onChange={handleInputChange}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig2"
                                              name='dig2'
                                              value={EmailVerification.dig2}
                                              className={globalclass.responsiveotp}
                                              onChange={handleInputChange}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig3"
                                              name='dig3'
                                              value={EmailVerification.dig3}
                                              className={globalclass.responsiveotp}
                                              onChange={handleInputChange}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                     <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig4"
                                              name='dig4'
                                              value={EmailVerification.dig4}
                                              className={globalclass.responsiveotp}
                                              onChange={handleInputChange}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig5"
                                              name='dig5'
                                              value={EmailVerification.dig5}
                                              className={globalclass.responsiveotp}
                                              onChange={handleInputChange}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                      <StyledTextField 
                                              type="number"
                                              variant='outlined'
                                              id="dig6"
                                              name='dig6'
                                              value={EmailVerification.dig6}
                                              className={globalclass.responsiveotp}
                                              onChange={handleInputChange}
                                              onInput={e => e.target.value = (e.target.value > 0) ? e.target.value = e.target.value.slice(0, 1) : e.target.value}
                                      />
                                    </Grid>
                                
                                <Grid item xs={12} style={{textAlign:'center'}}>
                                    <FormHelperText id="errorVerify" style={{ color: '#f44336',textAlign:'center',margin:'1%',marginBottom:'1%'}}> </FormHelperText>
                                </Grid>
                                <Grid item xs={12} style={{textAlign:'center'}}
                                >

                                <Button
                                      variant="contained"
                                      id="verifyEmail"
                                      style={{width:'200px',background:'#feb300'}}
                                      color="primary"
                                      onClick={handleVerify}
                                >
                                      Verify
                                      <ArrowForwardIcon style={{fontSize:'20px',marginLeft:'5px'}}
                                      />
                                </Button>
                                </Grid>

                                <Grid item xs={12} style={{textAlign:'center'}}>
                                        <Button
                                              variant="outlined"
                                              style={{marginTop:'10px',backgroundColor:'white',color:'#302bc3'}}
                                              onClick={handleResendOTP}
                                        >Resend OTP</Button>
                                </Grid>
                                
                              </form>
                              </Grid>
                              </Paper>
                              
                       
                      
                    </div>
                  </Container>
                 
        </div>    
      );          
} 
        

export default EmailVerification