export let BASE_URL = "https://0b7157tua4.execute-api.us-west-2.amazonaws.com/";
export let RELEASE_TYPE = "dev/"
export let restuserlogout = BASE_URL + RELEASE_TYPE + "restuserlogout";
export let restholidayscalendar = BASE_URL + RELEASE_TYPE + "restholidayscalendar";
export let restholidayscalendarview = BASE_URL + RELEASE_TYPE + "restholidayscalendarview";
export let restpoliciesnpayments = BASE_URL + RELEASE_TYPE + "restpoliciesnpayments";
export let restaccountinfoupdate = BASE_URL + RELEASE_TYPE + "restaccountinfoupdate";
export let restmenusubcategoriesalcobevtogo=BASE_URL + RELEASE_TYPE +"restmenusubcategoriesalcobevtogo";
export let restmenusubcategoriesnonalcobevtogo=BASE_URL + RELEASE_TYPE +"restmenusubcategoriesnonalcobevtogo";
export let restmenusubcategoriesfood=BASE_URL + RELEASE_TYPE +"restmenusubcategoriesfood";
export let restmenucategories=BASE_URL + RELEASE_TYPE +"restmenucategories";
export let restpromocodeadd=BASE_URL + RELEASE_TYPE +"restpromocodeadd";
export let restpromocodedisable=BASE_URL + RELEASE_TYPE +"restpromocodedisable";
export let restpromocodeview=BASE_URL + RELEASE_TYPE +"restpromocodeview";
export let restadminsetngspermsapprove=BASE_URL + RELEASE_TYPE +"restadminsetngspermsapprove";
export let b8erestadminsetngspermsnapvlsview=BASE_URL + RELEASE_TYPE +"b8erestadminsetngspermsnapvlsview";
export let removerecords="https://8nldsf12r0.execute-api.us-west-2.amazonaws.com/test/removerecords";
export let restadminsetngspermsmodify=BASE_URL + RELEASE_TYPE +"restadminsetngspermsmodify";
export let restperss3getuploadurl=BASE_URL + RELEASE_TYPE +"restperss3getuploadurl";
export let restperss3updatefileaclpublic=BASE_URL + RELEASE_TYPE +"restperss3updatefileaclpublic";
export let restlogontaglineupdate=BASE_URL + RELEASE_TYPE +"restlogontaglineupdate";
export let restmenucreatehelp=BASE_URL + RELEASE_TYPE +"restmenucreatehelp";
export let restmenuitemglobalview=BASE_URL + RELEASE_TYPE +"restmenuitemglobalview";
export let restmenucategoryview=BASE_URL + RELEASE_TYPE +"restmenucategoryview";
export let restmenuitem=BASE_URL + RELEASE_TYPE +"restmenuitem";
export let restmenuitemidview=BASE_URL + RELEASE_TYPE +"restmenuitemidview";
export let restmenucreate=BASE_URL + RELEASE_TYPE +"restmenucreate";
export let restmenusortedview=BASE_URL + RELEASE_TYPE +"restmenusortedview";
export let restmenudelete=BASE_URL + RELEASE_TYPE +"restmenudelete";
export let restmenuitemdelete=BASE_URL + RELEASE_TYPE +"restmenuitemdelete";
//export let restaccountfullinfoget=BASE_URL + RELEASE_TYPE +"restaccountfullinfoget";
export let restaurantlistget = BASE_URL + RELEASE_TYPE +"restaurantlistget";
//For Verifying Restaurant ID in signup
export let restadminsetngspermsdeny=BASE_URL + RELEASE_TYPE +"restadminsetngspermsdeny";
export let restperssigninverify=BASE_URL + RELEASE_TYPE +"restperssigninverify";

//For fetching Account info in Account Settings
export let restuserprofileview=BASE_URL + RELEASE_TYPE +"restuserprofileview";
//For updating Account info in Account Settings
export let restuserprofile=BASE_URL + RELEASE_TYPE +"restuserprofile";
//For View All Menu Items
export let restitemssortedview=BASE_URL + RELEASE_TYPE +"restitemssortedview";

//For fetching all Restaurant info
export let restaccountfullinfoget=BASE_URL + RELEASE_TYPE +"restaccountfullinfoget";

//For NetSales
export let b8erestaurantpersnetsalesinfoget=BASE_URL + RELEASE_TYPE +"b8erestaurantpersnetsalesinfoget";

/* For new User signup operations*/
/* For Account Recovery the default release is prod */
export let SIGNUP_BASE_URL = "https://iawaw6c65k.execute-api.us-west-2.amazonaws.com/";
export let SIGNUP_RELEASE_TYPE = "prod/"

//export let ACCOUNT_RECOVERY_BASE_URL = "https://iawaw6c65k.execute-api.us-west-2.amazonaws.com/";
//export let ACCOUNT_RECOVERY_RELEASE_TYPE = "prod/"
//export let accrecoveryrestaurantid=ACCOUNT_RECOVERY_BASE_URL + ACCOUNT_RECOVERY_RELEASE_TYPE +"resturantidget";
//export let accrecoveryloginid=ACCOUNT_RECOVERY_BASE_URL + ACCOUNT_RECOVERY_RELEASE_TYPE +"resturantloginidget";

export let accrecoveryrestaurantid = SIGNUP_BASE_URL + SIGNUP_RELEASE_TYPE + "resturantidget";
export let accrecoveryloginid = SIGNUP_BASE_URL + SIGNUP_RELEASE_TYPE + "resturantloginidget";


////////////////////////////
// Stripe interface   /////
//////////////////////////
// for adding new card
export let subscriptionaddcard = SIGNUP_BASE_URL + SIGNUP_RELEASE_TYPE + "restpersnewcardadd";
// for add and pay at the same time
export let subscriptionaddcardnpay = SIGNUP_BASE_URL + SIGNUP_RELEASE_TYPE + "restperssubsccardpaynow";
// for modifying the card parameters (such as billing address, expiry month and year).
export let subscriptionmodifycard = SIGNUP_BASE_URL + SIGNUP_RELEASE_TYPE + "restperssubscmodifycardinfo";


/* from csms original code - not used enabled for compilation purposes */
export let subscriptioninfoview = BASE_URL + RELEASE_TYPE + "subscriptioninfoview-NA";
//export let subscriptionaddcard = SIGNUP_BASE_URL + RELEASE_TYPE + "subscriptionaddnewcard-NA";
export let subscriptionpaynow = BASE_URL + RELEASE_TYPE + "subscriptionpaynow-NA";
export let subscriptioncancel = BASE_URL + RELEASE_TYPE + "subscriptioncancel-NA";
/* from csms original code - not used */

// ==> end of stripe interfaces

//for package selection
export let resturantsubscpkginfoview=SIGNUP_BASE_URL + SIGNUP_RELEASE_TYPE +"resturantsubscpkginfoview";
//for promocode validation
export let restaurantsubscpromocodecheck=SIGNUP_BASE_URL + SIGNUP_RELEASE_TYPE +"restaurantsubscpromocodecheck";
//for getting Confirmation Code
export let restadminsignup=BASE_URL + RELEASE_TYPE +"restadminsignup";