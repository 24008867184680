
import { Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import './css/flaticon.css'

import { FaStar } from 'react-icons/fa';

  const starStyle = {
    marginRight: '5px', // Adjust the spacing between stars if needed
  };

  const containerStyle = {
    display: 'inline-block',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', // Drop shadow
  };


const swiperOptions = {
    modules: [Autoplay],
    // slidesPerView: 3,
    // spaceBetween: 30,
    centeredSlides: true,
    centeredSlidesBounds: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        575: {
            slidesPerView: 1,
            spaceBetween: 15,
        },
        767: {
            slidesPerView: 2,
            spaceBetween: 15,
        },
        991: {
            slidesPerView: 2,
            spaceBetween: 15,
        },
        1199: {
            slidesPerView: 3,
            spaceBetween: 15,
        },
        1350: {
            slidesPerView: 3,
            spaceBetween: 15,
        },
    }
}


export default function ReviewSlider1() {

    const testimonialData = [
        {
          text:
            'I have recently started using Best8Eats. The ease with which I can place the order and retrieve it at a later stage is the high point for me. I have tried many food apps before. I think Best8Eats has been the best so far.',
          author: 'Customer',
        },
        {
          text:
            'I own a small Mexican restaurant in Sacramento. I have recently registered on Best8Eats. It was a good marketing move. I now get twice the orders, and that too in just over a month of registering.',
          author: 'Restaurant Personnel',
        },
        {
          text:
            'Get food at original prices”- Best8Eats is true to its tagline. There are no hidden costs added to the food prices. I am able to get food at the prices that I see in the restaurant menu. Best8Eats has won my trust.',
          author: 'Customer',
        },
        
        {
          text:
            'The restaurant where I work for as a manager has recently listed itself on Best8Eats. I now have my own login on the app and I can easily monitor the orders that come in. Right from the moment that the order is placed, till it goes out for pick up, I am aware of each and every step. This has reduced the chances of human error and increased our credibility.',
          author: 'Restaurant Personnel',
        },
        {
          text:
            'I am impressed with the customizations that I can do with my food. For every food item, allergen and customization information is clearly mentioned. It makes it simple for people like me, who have food allergies, to customize food. Thank you Best8Eats!',
          author: 'Customer',
        },
        
        {
          text:'It has become so easy to list down our menu on the Best8Eats app. It has also given us the flexibility to add allergen information. Earlier when we used to take orders on the phone, this information would often get mixed up. Now, everything is completely clear. Cant thank Best8Eats enough.',
          author: 'Restaurant Personnel',
        }
      ];

    return (
        <>

            {/* <Swiper {...swiperOptions}>
                <SwiperSlide>

                </SwiperSlide>
            </Swiper> */}
            <Swiper {...swiperOptions} className="" id="testiRes" style={{marginBottom:'2rem',overflow:'visible'}}>
                {/* TESTIMONIAL #1 */}
                {testimonialData.map((testimonial, index) => (
                <SwiperSlide className="bg--white-100 block-shadow revCard" style={{padding:'30px',width:'12rem', height:'22rem'}}>

                    {/* Quote Icon */}

                    <div className="review-ico ico-65"><span className="flaticon-quote" /></div>
                    {/* Text */}
                    <div className="review-txt">
                        {/* Text */}
                        <p style={{color:'black'}}>{testimonial.text}
                        </p>
                        <div style={{textAlign:'left',marginLeft:'0px'}}>
                              <span style={{fontSize:'20px'}}>
                                ⭐️⭐️⭐️⭐️⭐️
                              </span>
                            </div>
                        {/* Author */}
                        <div className="author-data clearfix">
                            {/* Avatar */}
                            <div className="review-avatar">
                                {/* <img src="/images/review-author-1.jpg" alt="review-avatar" /> */}
                                {/* <Avata sx={{ bgcolor: deepPurple[500] }}>OP</Avatar> */}
                                {/* <p style={{background:'gray',padding:'15px',borderRadius:'50%'}}>RP</p> */}
                            </div>
                            {/* Data */}
                            
                            <div className="review-author">
                                <h6 className="s-18 w-800" style={{color:'black'}}> - {testimonial.author}</h6>
                                {/* <p className="p-sm">@scott_boxer</p> */}
                            </div>
                           
                            {/* <div style={containerStyle}>
      {[...Array(5)].map((_, index) => (
        <FaStar
          key={index}
          color={index < 5 ? 'gold' : 'lightgray'}
          size={30}
          style={starStyle}
        />
      ))}
    </div> */}
                        </div> {/* End Author */}
                    </div> {/* End Text */}
                </SwiperSlide> 

))}
                
            </Swiper>
        </>
    )
}
