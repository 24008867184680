import { Col, Row } from 'react-bootstrap'
import './bootstrap.min.css'
import { useNavigate } from 'react-router-dom';

export default function Ct03_1() {

        const navigate = useNavigate();

    
        const handleButtonClick = () => {
            // Navigate to the 'res' route (RestOwner page)
            navigate('/customer');
          };
        const handleSvgClick1 = () => {
          console.log('Clicked SVG 1');
          navigate('/App-Store-Best8Eats')
           window.scrollTo({
       top: 0,
       behavior: 'auto', // Optional: for smooth scrolling
    });

          // Add your logic here for SVG 1 click
        };
      
        const handleSvgClick2 = () => {
          console.log('Clicked SVG 2');
          navigate('/Play-Store-Best8Eats')

          // Add your logic here for SVG 2 click
        };
    return (
        <>
            <section className="pt-100 pb-50 ct-03 content-section division page font--jakarta">
                <div className="container">
                    <div className="row d-flex align-items-center" style={{color:'black'}}>
                        {/* IMAGE BLOCK */}
                        <Col xs={{ span: 12, order: 2 }} sm={{ order: 2, span:12 }} md={{ order: 2 , span:5}} lg={{ order: 1, span:5 }} xl={{ order: 1, span:5 }}>
                            <div className="img-block left-column wow fadeInRight">
                                <img className="img-fluid" src="/img/test1/custFinal.png" alt="content-image" />
                            </div>
                        </Col>
                        {/* TEXT BLOCK */}
                        <Col xs={{ span: 12, order: 1 }} sm={{ order: 1, span:12 }} md={{ order: 1 , span:5}} lg={{ order: 2, span:5 }} xl={{ order: 2, span:5 }}>
                            <div className="txt-block right-column wow fadeInLeft mobileConfigText"  style={{textAlign:'right'}}>
                                {/* Section ID */}
                                <span className="section-id">Customers/Foodies</span>
                                {/* Title */}
                                <h2 className="s-35 w-700" style={{color:'black'}}>1 Click Orders. Restaurant Menu Prices. Order Customization. $0 Cost. </h2>
                                {/* List */}
                             


                                <ul className="simple-list" style={{}}>
                                    <li className="list-item" style={{}}>
                                        <p className="mb-0">Order and pickup food @ restaurant menu prices. No additional charges whatsoever.
                                        </p>
                                    </li>
                                    
                                    <li className="list-item">
                                        <p className="mb-0">Save orders and customizations for your favor8 restaurants</p>
                                    </li>
                                    <li className="list-item">
                                        <p className="mb-0">Digitized one-click orders. No more waiting for your incorrect orders to be fixed.</p>
                                    </li>
                                </ul>
                                {/* <button id='BColor' className="btn btn--theme hover--tra-grey" >JOIN NOW</button> */}
                                <button id='BColor'  style={{minWidth:'300px',borderRadius:'12px'}} onClick={handleButtonClick}>Know More</button>

                                <div>

                                <div>
                                <a href={`${process.env.PUBLIC_URL}/App-Store-Best8Eats`}> 
                                <img
                                        src={'./img/apple.svg'}
                                        alt="SVG 1"
                                        // onClick={handleSvgClick1}
                                        style={{ cursor: 'pointer', marginRight: '30px',marginTop:'-20px',
                                    height:'150px' }}
                                    />
                                    </a>
                                    <a href={`${process.env.PUBLIC_URL}/Play-Store-Best8Eats`}> 

                                    <img
                                        src={'./img/android.svg'}
                                        alt="SVG 2"
                                        onClick={handleSvgClick2}
                                        style={{ cursor: 'pointer',marginTop:'-20px',
                                        height:'150px' }}
                                    />
                                    </a>
                                    </div>                  
                  </div>


                                
                            </div>
                        </Col>	{/* END TEXT BLOCK */}
                    </div>    {/* End row */}
                </div>	   {/* End container */}
            </section>
        </>
    )
}
