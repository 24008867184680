// import React from 'react';
// import { Container, Row, Col, Form, Button, Nav } from 'react-bootstrap';
// import './Footer.css'
// import { NavLink, useNavigate } from 'react-router-dom'; 
// import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
// import { TextField } from '@material-ui/core';

// import { styled } from '@mui/material/styles';
// import MuiTextField from '@mui/material/TextField';
// const Footer = () => {


//     const options = {
//         shouldForwardProp: (prop) => prop !== 'borderColor',
//        };

//        const outlinedSelectors = [
//         '& .MuiOutlinedInput-notchedOutline',
//         '&:hover .MuiOutlinedInput-notchedOutline',
//         '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline',
//        ];

//        const TextField = styled(
//         MuiTextField,
//        )(({ borderColor }) => ({

//         [outlinedSelectors.join(',')]: {
//            borderWidth: 2,
//            borderColor,
//         },
//        }));

//     const Navigate = useNavigate();

//     const handleSubmit = () => {

//         // Navigate('/sign-up')

//         alert("NewsLetter Yet to Set-up")

//     }
//  return (
//     <footer className="footer-section">
//       <div className="footer-top">
//         {/* <Container> */}
//           <Row style={{justifyContent:'space-around'}}>



//         <Col lg={3} md={6} sm={6} style ={{margin:'20px 0',alignItems:'flex-start'}}>
// <div className="widget course-links-widget" style={{alignContent:'top',alignItems:'top',justifyContent:'top',justifyItems:'top',verticalAlign:'top'}}>
//     <a href={`${process.env.PUBLIC_URL}/`} className='links' style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}>
//         <h5 className="" style={{display:'flex',marginBottom:'3rem' }}>Best8Eats</h5>
//     </a>
//     <div style={{color:'white'}}>
//         <a className='links' href={"tel:+1 855-IKABIT-1"} style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}>

//             <h6 style={{marginBottom:'0.75rem'}}>+1 855-IKABIT-1</h6>
//         </a>                       
//         <div>
//             <a className='links' href="https://www.instagram.com/best8eats/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}><FaInstagram size={24} style={{ marginRight: '5px' }} /></a>
//             <a className='links' href="https://www.facebook.com/Best8Eats" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}><FaFacebook size={24} style={{ marginRight: '5px' }} /></a>
//             <a className='links' href="https://twitter.com/_ikabit" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}><FaTwitter size={24} style={{ marginRight: '5px' }} /></a>
//             <a className='links' href="https://www.linkedin.com/showcase/best8eats/about/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}><FaLinkedin size={24} style={{ marginRight: '5px' }} /></a>
//         </div>
//     </div>
// </div>
//         </Col>


//        <Col lg={2} md={6} sm={6} style ={{margin:'20px 0',alignItems:'flex-start'}}>
//             <div></div>
//         </Col> 


//             <Col lg={3} md={6} sm={6} style ={{margin:''}}> 
//             <div className="widget course-links-widget">
//                 <h5 className="widget-title">Quick Links</h5>
//                 <ul className="courses-link-list" style={{alignItems:'left',textAlign:'left',paddingLeft:'0px'}}>
//                 <li><Nav.Link className='links' as={NavLink} to={`${process.env.PUBLIC_URL}/` }>Home</Nav.Link></li>

//                 <li><Nav.Link className='links' as={NavLink} to={`${process.env.PUBLIC_URL}/restaurant-owner`}>Restaurant Owner</Nav.Link></li>

//                 <li><Nav.Link className='links' as={NavLink} to={`${process.env.PUBLIC_URL}/customer`}>Customer</Nav.Link></li>

//                 <li><Nav.Link className='links' as={NavLink} to={`${process.env.PUBLIC_URL}/pricing`}>Pricing</Nav.Link></li>

//                 <li><Nav.Link className='links' as={NavLink} to={`${process.env.PUBLIC_URL}/contact-us`}>Contact Us</Nav.Link></li>

//                 <li><Nav.Link className='links' as={NavLink} to={`${process.env.PUBLIC_URL}/sign-up` }>Sign Up</Nav.Link></li>

// <li><Nav.Link className='links' as={NavLink} to={`${process.env.PUBLIC_URL}/login`}>Sign In</Nav.Link></li>
//                 </ul>


//             </div>
//             </Col>




//         <Col lg={3} md={6} sm={6} style ={{}}>
//         <div className="widget newsletter-widget">




//             <h5 className="widget-title">Newsletter</h5>
// <div className="footer-newsletter">
// <p>Sign Up to Our Newsletter to Get Latest Updates & Services</p>
// <Form className="news-letter-form" >
//     <Form.Group controlId="formBasicEmail" style={{marginBottom:'20px'}}>
//     <Form.Control type="email" placeholder="Your email address" />
//     </Form.Group>
//     <span id='BColor' onClick={handleSubmit} className="" style={{minWidth:'200px',textAlign:'center',borderRadius:'8px',cursor:'pointer',padding:'10px 20px' }}>
//     Send
//     </span>
// </Form>
// </div>
// </div>
//         </Col>

//           </Row>
//         {/* </Container> */}
//       </div>
//       <div className="footer-bottom">
//         <Container>
//           <Row>



//             <Col md={6} sm={6} className="text-sm-left text-center terms-privacy" style={{}}>
//               <span className="copy-right-text">©  Ikabit Inc. 2024 <span style={{margin: '0 2px'}}></span> | <span style={{margin: '0 2px'}}></span> BUILT By <a href="https://ikabit.com/" >Ikabit Inc </a>  </span>
//             </Col>

//             <Col md={6} sm={6}>
//               <ul className="terms-privacy d-flex justify-content-center">
//                 <li><a className='links' href="#">Privacy Policy</a></li>
//               </ul>
//             </Col>

//           </Row>
//         </Container>
//       </div>
//     </footer>
//  );
// };

// export default Footer;




import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
// import Logo from '../../components/logo/Logo';
// import FooterData from '../../data/Footer/footerItem.json';
// import FooterLinkItem from '../../components/Footer/FooterLinkItem.jsx';
// import 'reactjs-popup/dist/index.css';
// import   '../../pages/PopUp/PopUp.css';

import './Footer.css'
import { Form, Nav } from 'react-bootstrap';
import { ScrollUp } from './Utils/ScrollUp';
// import NewsletterForm from '../../components/Newsletter/NewsletterForm';
// import Newsletter from '../Newsletter/Newsletter';

const Footer = () => {

    const Year = new Date();
    let CurrentYear = Year.getFullYear();

    // const loads = () => {

    //     if(window){
    //         alert("yes")

    //     }else
    //     {
    //         alert("no")
    //     }

    // }

    const [email, setEmail] = useState('');
    const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
    const [emailError, setEmailError] = useState('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission
        if (!emailRegex.test(email)) {
            setEmailError('Invalid email format. Please check your input and try again.');
            return;
        }
        // Here you can add your logic to subscribe the user to the newsletter
        // For demonstration, we'll just simulate a successful subscription
        setSubscriptionSuccess(true);
        setTimeout(() => { setSubscriptionSuccess(false) }, 5000)
        setEmail(''); // Reset the email input
        setEmailError(''); // Clear any previous email errors
    };






    return (
        <div className="footer-section section footer-bg-color"    >
            <div className="container">

                {/* adds bottom margin after footer menu */}
                {/* <div className="row mb-lg-14 mb-md-10 mb-6"> */}
                {/* {document.addEventListener("DOMContentLoaded", () => {})} */}



                <div className='row'>

                    <div className="col-xl-7 col-lg-5 col-md-4 col-sm-8 col-12 col-12 mb-6">

                        <div className="footer-widget">


                            <div className="footer-widget-content">
                                <div className="content">


                                    <div className="widget course-links-widget" style={{ alignContent: 'top', alignItems: 'top', justifyContent: 'top', justifyItems: 'top', verticalAlign: 'top' }}>
                                        <a href={`${process.env.PUBLIC_URL}/`} className='linksz' style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}>
                                            <h4 className='linksz' style={{ display: 'flex', color: '#feb300', marginBottom: '0px' }}>Best8Eats</h4>
                                        </a>
                                        <h7 className="" style={{ display: 'flex', marginBottom: '3rem', color: 'white' }}>By Ikabit Inc</h7>

                                        <div style={{ color: 'white' }}>
                                            <a className='links' href="tel:+1855IKABIT1" style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}>
                                                <h6 style={{ marginBottom: '0.75rem' }}>+1 855-IKABIT-1</h6>
                                            </a>

                                            <div className="footer-social-inline" style={{ marginBottom: '2rem' }}>
                                                <a className='links' href="https://www.instagram.com/best8eats/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}><FaInstagram size={24} style={{ marginRight: '5px' }} /></a>
                                                <a className='links' href="https://www.facebook.com/people/Best8Eats/100072657540095/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}><FaFacebook size={24} style={{ marginRight: '5px' }} /></a>
                                                <a className='links' href="https://twitter.com/_ikabit" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}><FaTwitter size={24} style={{ marginRight: '5px' }} /></a>
                                                <a className='links' href="https://www.linkedin.com/showcase/best8eats/about/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}><FaLinkedin size={24} style={{ marginRight: '5px' }} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="footer-social-inline">
                                   
                                   </div> */}
                            </div>
                        </div>
                    </div>



                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6" style={{ marginBottom: '20px' }}>
                        <div className="widget course-links-widget">
                            <h5 className="" style={{ color: 'white' }}>Quick Links</h5>
                            <ul className="courses-link-list" style={{ alignItems: 'left', textAlign: 'left', paddingLeft: '0px' }}>
                                <li><a href={`${process.env.PUBLIC_URL}/`} className='links' id="FooterLink" style={{ textDecoration: 'none' }}>Home</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/restaurant-owner`} style={{ textDecoration: 'none' }} className='links' id="FooterLink">Restaurant Owner</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/customer`} style={{ textDecoration: 'none' }} className='links' id="FooterLink">Customer</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/pricing`} style={{ textDecoration: 'none' }} className='links' id="FooterLink">Pricing</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/contact-us`} className='links' id="FooterLink" style={{ textDecoration: 'none' }}>Contact Us</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/sign-up`} className='links' id="FooterLink" style={{ textDecoration: 'none' }}>Sign Up</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/login`} className='links' id="FooterLink" style={{ textDecoration: 'none' }}>Sign In</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-6">
                        <div className="footer-newsletter" style={{ color: '#fff' }}>
                            <p>Sign Up & Get Latest Updates</p>
                            <Form className="news-letter-form" onSubmit={handleSubmit}>
                                <Form.Group controlId="formBasicEmail" style={{ marginBottom: '20px' }}>
                                    <Form.Control
                                        type="email"
                                        placeholder="Your email address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        isInvalid={!!emailError}
                                    />
                                    {emailError && (
                                        <Form.Control.Feedback type="invalid">
                                            {emailError}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                <button
                                    id='BColor'
                                    type="submit"
                                    className="btn-warning"
                                    style={{ textAlign: 'center', borderRadius: '8px', marginBottom: '10px', cursor: 'pointer', padding: '10px 20px' }}
                                >
                                    Send
                                </button>
                            </Form>
                            {subscriptionSuccess && (
                                <p style={{ color: 'green' }}>Successfully subscribed to the newsletter.</p>
                            )}
                        </div>
                    </div>






                </div>

                <div className="row">

                    <div className="col" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>

                        <p style={{ padding: '5px 0', textAlign: 'left' }} className="copyright" >&copy; Ikabit Inc. {CurrentYear} | Built By <a style={{ color: '#feb300', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href="https://www.ikabit.com/"><strong style={{ color: '#feb300', textDecoration: 'none' }}>Ikabit</strong></a>.</p>
                        {/* <p style={{padding:'5px 0',textAlign:'right'}} className="copyright"><a rel="noopener noreferrer" href="/privacy-policy" >Privacy Policy</a>. 
                       </p> */}

                        <NavLink to={process.env.PUBLIC_URL + "/privacy-policy"} style={{ padding: '5px 0', textAlign: 'right', color: '#feb300', textDecoration: 'none' }} className="copyright" ><a rel="noopener noreferrer" className='linksz' style={{ color: 'gray', textDecoration: 'none' }}> <p style={{ fontSize: '0.95rem', marginBottom: '0px' }} >Privacy Policy.</p></a>
                        </NavLink>


                    </div>

                </div>


            </div>
        </div>
    )
}

export default Footer