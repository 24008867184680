import './css/responsive.css'
import './css/animate.css'
import './css/demo.css'
import './css/lunar.css'

export default function Ct02_21() {
    return (
        <>
            <section className="pt-100 pb-30 ct-02 content-section division ">
                <div className="container">
                    {/* SECTION CONTENT (ROW) */}
                    <div className="row d-flex align-items-center">
                        {/* IMAGE BLOCK */}
                        <div className="col-md-6">
                            <div className="img-block left-column wow fadeInRight">
                                <img className="img-fluid" src={require("./Images/process/5.png")} alt="content-image" style={{maxHeight:'80%', maxWidth:'80%'}}/>
                            </div>
                        </div>
                        {/* TEXT BLOCK */}
                        <div className="col-md-6">
                            <div className="txt-block right-column wow fadeInLeft">
                                {/* CONTENT BOX #1 */}
                                <div className="cbox-2 process-step">
                                    {/* Icon */}
                                    <div className="ico-wrap">
                                        <div className="cbox-2-ico bg--theme color--white">1</div>
                                        <span className="cbox-2-line" />
                                    </div>
                                    {/* Text */}
                                    <div className="cbox-2-txt">
                                        <h5 className="s-22 w-700">Sign Up</h5>
                                        <p>Seamless pickup orders await! Join instantly with our mobile app in under a minute for a quick and effortless experience.
                                        </p>
                                    </div>
                                </div>	{/* END CONTENT BOX #1 */}
                                {/* CONTENT BOX #2 */}
                                <div className="cbox-2 process-step">
                                    {/* Icon */}
                                    <div className="ico-wrap">
                                        <div className="cbox-2-ico bg--theme color--white">2</div>
                                        <span className="cbox-2-line" />
                                    </div>
                                    {/* Text */}
                                    <div className="cbox-2-txt">
                                        <h5 className="s-22 w-700">Add Your favor8 Restaurants</h5>
                                        <p>Build your list of favorites! From the smallest gems to renowned eateries, add them all – every restaurant is now at your fingertips.
                                        </p>
                                    </div>
                                </div>	{/* END CONTENT BOX #2 */}
                                {/* CONTENT BOX #3 */}
                                <div className="cbox-2 process-step">
                                    {/* Icon */}
                                    <div className="ico-wrap">
                                        <div className="cbox-2-ico bg--theme color--white">3</div>
                                    </div>
                                    {/* Text */}
                                    <div className="cbox-2-txt">
                                        <h5 className="s-22 w-700">Easy Ordering</h5>
                                        <p className="mb-0">Craft your perfect order once, save it forever! Enjoy the simplicity of 1-click reordering for your customized delights.
                                        </p>
                                    </div>
                                </div>	{/* END CONTENT BOX #3 */}
                            </div>
                        </div>	{/* END TEXT BLOCK */}
                    </div>	{/* END SECTION CONTENT (ROW) */}
                </div>	   {/* End container */}
            </section>
        </>
    )
}
