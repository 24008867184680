import React, { useEffect, useState, useRef } from 'react';
import 'aos/dist/aos.css';

import a1 from './Images/a1.jpeg';
import a2 from './Images/a2.jpeg';
import a3 from './Images/a3.jpeg';
import a4 from './Images/a4.jpeg';
import a5 from './Images/a5.jpeg';
import a6 from './Images/a6.jpeg';
import a7 from './Images/a7.jpeg';
import a8 from './Images/a8.jpeg';
import a9 from './Images/a9.jpeg';
import a10 from './Images/a10.jpeg';
import a11a from './Images/11_a.jpg';
import a11b from './Images/11_b.jpg';
import a12 from './Images/a12.jpeg';
import a13 from './Images/a13.jpeg';
import a14 from './Images/14.jpg';
import a15 from './Images/a15.jpeg';
import a16 from './Images/a16.jpeg';
import a17 from './Images/a17.jpeg';
import rest from './Images/rest.jpg'
import cust from './Images/cust.jpg'
import ss1 from './Images/ss1.png'
import ss2 from './Images/ss2.png'

import a18 from './Images/18.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import customer from './Images/cust.png';
import restaurant from './Images/rest1.png';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import Marquee from "react-fast-marquee";
import './bootstrap.min.css'
import './download.css'
import './css/responsive.css'
import './css/animate.css'
import './css/demo.css'
import './css/lunar.css'
import { NavLink, useNavigate } from 'react-router-dom';


// import required modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import Testimonial from './Testimonial';
import { Col, Container, Image, Nav, Row } from 'react-bootstrap';
import HeroSlider from './HeroSlider';
import Download from './download';
import Ct03_1 from './Ct03_1';
import Ct03_2 from './Ct03_2';
import Reviews1_1 from './Reviews1_1';
import './bootstrap.min.css'
import Slider from './Slider';
import Footer from './Footer';
import Header from './Header';
import { Button } from 'semantic-ui-react';
import { TextField } from '@material-ui/core';




export default function Home() {

  <style>
    @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Poppins:wght@300&family=Ubuntu&display=swap');
  </style>

  const handleDragStart = (e) => e.preventDefault();

  const imageSources = [
    a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11a, a11b, a12, a13, a14, a15, a16, a17, a18
  ];

  const testimonialData = [
    {
      text:
        'I have recently started using Best8Eats. The ease with which I can place the order and retrieve it at a later stage is the high point for me. I have tried many food apps before. I think Best8Eats has been the best so far.',
      author: 'Customer',
    },
    {
      text:
        'Get food at original prices”- Best8Eats is true to its tagline. There are no hidden costs added to the food prices. I am able to get food at the prices that I see in the restaurant menu. Best8Eats has won my trust.',
      author: 'Customer',
    },
    {
      text:
        'I am impressed with the customizations that I can do with my food. For every food item, allergen and customization information is clearly mentioned. It makes it simple for people like me, who have food allergies, to customize food. Thank you Best8Eats!',
      author: 'Customer',
    },
    {
      text:
        'I own a small Mexican restaurant in Sacramento. I have recently registered on Best8Eats. It was a good marketing move. I now get twice the orders, and that too in just over a month of registering.',
      author: 'Restaurant Personnel',
    },
    {
      text:
        'The restaurant where I work for as a manager has recently listed itself on Best8Eats. I now have my own login on the app and I can easily monitor the orders that come in. Right from the moment that the order is placed, till it goes out for pick up, I am aware of each and every step. This has reduced the chances of human error and increased our credibility.',
      author: 'Restaurant Personnel',
    },
    {
      text: 'It has become so easy to list down our menu on the Best8Eats app. It has also given us the flexibility to add allergen information. Earlier when we used to take orders on the phone, this information would often get mixed up. Now, everything is completely clear. Cant thank Best8Eats enough.',
      author: 'Restaurant Personnel',
    }
  ];


  const colStyle = {
    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(${cust})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover', // This ensures the image covers the entire container
    padding: '30px',
    minHeight: '400px',
    paddingTop: '50px',
    color: 'white', // Text color
  };

  // const responsive = [
  //   {
  //     breakpoint: 0,
  //     slidesPerView: 1, // Set to 1 for mobile
  //   },
  //   {
  //     breakpoint: 568,
  //     slidesPerView: 1, // Set to 1 for mobile
  //   },
  //   {
  //     breakpoint: 1024,
  //     slidesPerView: 3,
  //   },
  // ];

  const autoplayDelays = [
    7000,  // Delay for slide 1
    4000,  // Delay for slide 2
    2000,  // Delay for slide 3
    1000,  // Delay for slide 4
    4000,  // Delay for slide 5
    2000,  // Delay for slide 6
    2000,  // Delay for slide 7
    5000,  // Delay for slide 8
    5000,  // Delay for slide 9
    7000,  // Delay for slide 10
    7000,  // Delay for slide 11
    10000,  // Delay for slide 12
    1000, // Delay for slide 13
    4000,  // Delay for slide 14
    2000,  // Delay for slide 16
    4000,  // Delay for slide 17
    2000,
    3000,
    9000  // Delay for slide 18
    // Delay for slide 19
  ];


  // // State to keep track of the currently active slide
  const [activeSlide, setActiveSlide] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Increment activeSlide in a cyclic manner
  //     setActiveSlide((prevSlide) => (prevSlide + 1) % imageSources.length);
  //     console.log(autoplayDelays[activeSlide])
  //   }, autoplayDelays[activeSlide]);

  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, [activeSlide]);


  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    speed: 7000,

    autoplay: {
      delay: 4000, // set the delay according to your preference
      disableOnInteraction: false,

    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % imageSources.length);
    }, autoplayDelays[activeSlide]);

    return () => {
      clearInterval(interval);
    };
  }, [activeSlide, imageSources]);

  const yellow = "#FFCA01";
  const blue = "#2EA3F2";
  const percent = yellow



  const colStyle1 = {
    background: `white url(${rest}) no-repeat center`,
    padding: '30px',
    paddingTop: '50px',
    position: 'relative',

  };

  const colStyle2 = {
    background: `white url(${rest}) no-repeat center`,
    padding: '30px',
    paddingTop: '50px',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',

  };

  const colStyle3 = {
    background: `white url(${ss1}) no-repeat center`,
    padding: '30px',
    paddingTop: '50px',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',

  };

  const colStyle4 = {
    background: `white url(${ss2}) no-repeat center`,
    minHeight: '400px',
    padding: '30px',
    paddingTop: '50px',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',

  };

  const whiteSectionStyle = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    background: 'white',
    color: 'black',
    padding: '20px',
    boxSizing: 'border-box',
    boxShadow: '0px -5px 10px rgba(0, 0, 0, 1)', // Top box shadow
  };


  const navigate = useNavigate();


  const handleSubmit = () => {

    navigate('/restaurant-owner');
  };


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
 });

 useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
 }, []);

 console.log('Width:', windowSize.width, 'Height:', windowSize.height);

  return (
    <>

      <section className='page font--jakarta' >

        <section style={{ background: '' }} className='HeroBg'>
          <Header />
          <Row className="custom-Row vhHeightSet"
           style={{
            // padding: '1% 5%',
            justifyContent:'center',
            alignContent: 'center',
            
            alignItems: 'center',  // Add this line for vertical centering
            justifyItems: 'center',
            display: 'flex',
            paddingRight: '0px',
            marginRight: '0px',
            height: '90vh',

          }}>
            <Col xs={{ span: 12, order: 1 }} sm={{ order: 1, span: 12 }} md={{ order: 1, span: 5 }} lg={{ order: 1, span: 5 }} xl={{ order: 1, span: 5 }}
              className="">
              <section className=" ct-03 content-section division">
                <div className="container">
                  <div className="row d-flex align-items-center mobileSizedHero marginTopSet" style={{ paddingLeft: '100px', marginTop:'-50px',justifyContent: 'center' }}>

 
                    {/* <div className="txt-block right-column wow fadeInLeft" style={{padding:'0 20px 0px 50px',color:'white'}}>
                                <span className="" style={{fontWeight:'700',textTransform:'uppercase',fontFamily:'Merriweather',textShadow:'2px 2px 2px black'}}>Best8Eats</span>
                                <h2 className="s-35 w-700" style={{margin:'5px 0px',fontFamily:'MerriWeather',color:'black',textShadow:'2px 2px 2px gray'}}>Food Pickup At Restaurant Menu Prices</h2>
                             

                                <p style={{textShadow:'2px 2px 2px black'}}>$0 Extra Charges. 1-Click Orders. Fast, Convenient & Accurate Orders Every Time!</p>
                               
                                <button id='BColor' className="btn btn--theme hover--tra-grey" style={{marginTop:'20px',boxShadow:'2px 2px 2px 2px black'}} >Learn More</button>


                                
                            </div> */}

                    <div className="txt-block right-column wow fadeInLeft" >
                      {/* Section ID */}

                      <span className="section-id aboveHead" style={{ marginBottom: '10px', fontWeight: '700' }}>
                        {/* <Image src={'./img/b8eLogo.png'} height={50}  style={{marginBottom:'20px',marginRight:'5px'}} /> */}
                        Best8Eats
                      </span>

                      {/* Title */}
                      <h2 className="s-35 w-700" style={{ color: 'black' }}>Food Pickup At Restaurant Menu Prices</h2>
                      {/* List */}
                      <span className="list-item">

                        <p className='head2' style={{ fontWeight: '600' }}>$0 Extra Charges. 1-Click Orders. Fast, Convenient & Accurate Orders Every Time!</p>
                      </span>




                      {/* <button id='BColor' className="btn btn--theme hover--tra-grey" >JOIN NOW</button> */}
                      <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/restaurant-owner`}><Button onClick={handleSubmit} id='BBColor' style={{ marginLeft: '0', borderRadius: '12px' }}>Learn More</Button>
</Nav.Link>
                      <div>

                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Col>

            <Col xs={{ span: 12, order: 1 }} sm={{ order: 1, span: 12 }} md={{ order: 1, span: 5 }} lg={{ order: 2, span: 6 }} xl={{ order: 2, span: 6 }}
              className=""  >
              {/* <HeroSlider/> */}
              <Slider/>

            </Col>
          </Row>
        </section>

      </section>

      {/* <div style={{background:'rgb(33,33,33)'}}>

        <div style={{ height: '80px', width: '100%', position: 'relative', background: 'black' }}></div>
        <div className='Hbg '>
          <div className='container'>
            <Swiper
            data-aos="zoom-out" data-aos-duration= '1000'
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              initialSlide={activeSlide}
              disableOnInteraction={true}
              modules={[Autoplay, EffectCoverflow, Pagination]}
              loop={true}
              autoplay={{
                delay: autoplayDelays[activeSlide], // Set autoplay delay for the active slide
                disableOnInteraction: true,
              }}
              onSlideChange={(swiper) => {
                setActiveSlide(swiper.realIndex);
                console.log(activeSlide)
              }}
            >
              {imageSources.map((src, index) => (
                <SwiperSlide key={index}>
                  <img src={src} className='Ihover'  alt={`Image ${index}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <img  className='ball' src='https://verify-getrestaurantapp-v1-0-0.d3bydizwdiwzbp.amplifyapp.com/static/media/12.57f85574.png' alt="" />
      </div> */}
      {/* <hr className="divider" /> */}

      <section >

        <div
          style={{
            background: "black",
            padding: "30px 0",
            color: "white",

            // borderTop: "4px solid black",
            // borderBottom: "4px solid black",
            overflow: "hidden",
            textAlign: 'center'
          }}
        >
          <Swiper modules={[Autoplay, Pagination]} autoplay={true}
            {...swiperParams}>
            <SwiperSlide>
              <h4 style={{marginBottom:'0px', width: '100%', padding: '0 70px', fontWeight: '500', fontSize: '1em', fontFamily: 'Merriweather Sans', color: 'white' }}>
                <span style={{ color: percent }}>93%</span> Restaurants in the US offer take-outs.
              </h4>
            </SwiperSlide>

            <SwiperSlide>
              <h4 style={{marginBottom:'0px', width: '100%', padding: '0 40px', fontWeight: '500', fontSize: '1em', fontFamily: 'Merriweather Sans', color: 'white' }}>
                <span style={{ color: percent }}>90%</span> Customers call a restaurant to place take-away orders.
              </h4>
            </SwiperSlide>

            <SwiperSlide>
              <h4 style={{marginBottom:'0px', width: '100%', padding: '0 40px', fontWeight: '500', fontSize: '1em', fontFamily: 'Merriweather Sans', color: 'white' }}>
                <span style={{ color: percent }}>30%</span> Takeaway orders are wrong.
              </h4>
            </SwiperSlide>

            <SwiperSlide>
              <h4 style={{marginBottom:'0px', width: '100%', padding: '0 40px', fontWeight: '500', fontSize: '1em', fontFamily: 'Merriweather Sans', color: 'white' }}>
                You pay <span style={{ color: percent }}>30%</span> more on your food through other delivery apps.
              </h4>
            </SwiperSlide>

            <SwiperSlide>
              <h4 style={{marginBottom:'0px', width: '100%', padding: '0 40px', fontWeight: '500', fontSize: '1em', fontFamily: 'Merriweather Sans', color: 'white' }}>
                Loud noise at the restaurant doesn’t let them take your correct order.
              </h4>
            </SwiperSlide>

            <SwiperSlide>
              <h4 style={{marginBottom:'0px', width: '100%', padding: '0 40px', fontWeight: '500', fontSize: '1em', fontFamily: 'Merriweather Sans', color: 'white' }}>
                Continuously manning the phone at the restaurant is exhausting.
              </h4>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <hr className="divider" />





      {/* <div style={{background:'#fff'}}>
      <Download/>
      </div> */}



      <div style={{ background: '#F6F6FA' }}>
        <Ct03_2 />
      </div>

      <div style={{ background: '#fff' }}>
        <Ct03_1 />
      </div>

      {/* <section className='container' style={{ display: 'block', position: 'relative', padding: '0px 30px' , perspective: '1000px'}}>
        <div className="lastSection" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div className="row">
            
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12" style={{ padding: '20px 3rem', textAlign: 'justify', minHeight:'10vh', justifyContent:'center',display:'flex',flexDirection:'column' }}>
              <div style={{ minHeight: '100px' }}>
                <h6>Free  yourself from the delivery charges. Save your favorite restaurants and dishes. Find the smallest restaurants in your town. Order in a jiffy. Get fresh food at the tip of your fingers. Join us TODAY!</h6>
              </div>
              <div style={{  }}>
                <button type="button" className="btn btn-dark" style={{ marginRight: '7px',fontSize:'0.7rem', background: 'black' }}>
                  <svg style={{ margin: '5px', paddingBottom: '2px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16">
                    <path d="M11.182.008C11.148-.030 9.923.023 8.857 1.180c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.520.087 2.475-1.258.955-1.345.762-2.391.728-2.430Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.790-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.490.196-1.422.754-2.074 2.237-.652 1.482-.311 3.830-.067 4.560.244.729.625 1.924 1.273 2.796.576.984 1.340 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.790.505-1.217.473-1.282Z" />
                    <path d="M11.182.008C11.148-.030 9.923.023 8.857 1.180c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.520.087 2.475-1.258.955-1.345.762-2.391.728-2.430Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.790-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.490.196-1.422.754-2.074 2.237-.652 1.482-.311 3.830-.067 4.560.244.729.625 1.924 1.273 2.796.576.984 1.340 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.790.505-1.217.473-1.282Z" />
                  </svg>
                  Get it from App Store
                </button>

               
              
                
                  <button type="button" className="btn btn-outline-dark" style={{ marginRight: '10px',fontSize:'0.7rem' }}>
                  <svg style={{ margin: '5px', paddingBottom: '2px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google-play" viewBox="0 0 16 16">
                    <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96 2.694-1.586Zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055l7.294-4.295ZM1 13.396V2.603L6.846 8 1 13.396ZM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27Z" />
                  </svg>
                  Get it from Play Store
                </button>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12" style={{minHeight:'50vh', justifyContent:'center',display:'flex',flexDirection:'column', padding: '20px 5rem', textAlign: 'justify' }}>
              <div style={{ minHeight: '10vh' }}>
                <h6>Increase your customer base. Reach out to the smallest parts of town. Upload and customize your menu. Be there always for your customers. Join us TODAY!</h6>
              </div>
              <div style={{marginTop:'35px'}}>
                <button type="button" className="btn btn-dark" style={{ background: 'black', width: '100px', height: '2.5rem' }}>
                  Signup
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}









      {/* <Container fluid style={{ maxHeight: '100vh',marginTop:'30px' }}>
      <Row data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <Col xs={12} sm={12} md={12} lg={4} xl={5}  style={{margin:'0 20px' }}>
          <div className="custom-card" style={{textAlign:'center',border:'2px solid black',boxShadow: '10px 10px 7px rgba(0, 0, 0, 0.7)',borderRadius:'9px'}}>
          
          <div style={{padding:'30px'}}>
            
          <h4 className="custom-text" style={{paddingTop:'20px'}}>Become our happy customer</h4>
          <Image src={"https://img.icons8.com/external-filled-color-icons-papa-vector/900/external-Happy-Customer-video-game-design-filled-color-icons-papa-vector.png"} fluid className="custom-image" />
          
          </div>

            <div style={{color:'white',background:'gray',padding:'30px'}}>
            <p className='pFont' style={{textDecoration:'none'}}>
            Free yourself from the delivery charges. Save your favourite restaurants and dishes. Find every restaurant in your town. Order in a jiffy. Get fresh food at Menu prices. No hidden costs!
            </p>
            <button className="pill-button">JOIN US NOW </button>

            </div>

            </div>

        </Col>

        <Col xs={12} sm={12} md={12} lg={4} xl={5}  style={{margin:'0 20px' }}>
          <div className="custom-card" style={{textAlign:'center',border:'2px solid black',boxShadow: '10px 10px 7px rgba(0, 0, 0, 0.7)', borderRadius:'9px'}}>
          
          <div style={{padding:'30px'}}>
            
          <h4 className="custom-text" style={{paddingTop:'20px'}}>Get lifelong subscription for your restaurant</h4>
          <Image src={"https://img.icons8.com/external-filled-color-icons-papa-vector/900/external-Happy-Customer-video-game-design-filled-color-icons-papa-vector.png"} fluid className="custom-image" />
          
          </div>

            <div style={{color:'white',background:'gray',padding:'30px'}}>
            <p className='pFont' style={{textDecoration:'none'}}>
            Increase your customer base. Customize your menu and reach out to everyone. We start our BASIC plan at $29.99.   We make sure you are always there for your customers.  
            </p>
            <button className="pill-button">
            SUBSCRIBE NOW
              </button>

            </div>

            </div>

        </Col>

       
      </Row>

      
    </Container> */}



      {/* <Container fluid style={{ maxHeight: '100vh',marginTop:'30px' }}>
      <Row>
       
        <Col data-aos="fade-up" 
      data-aos-anchor-placement="bottom-bottom"  data-aos-duration="50" xs={12} sm={12} md={12} lg={6} xl={3} className="" style={colStyle} >
        </Col>

        <Col  data-aos="fade-up"
      data-aos-anchor-placement="bottom-bottom"  data-aos-duration="400"

   xs={12} sm={12} md={12} lg={6} xl={3} className="" style={colStyle3}>
          <div style={whiteSectionStyle}>
            <p>
            Find every restaurant in your town.Get fresh food at menu prices.

            </p>
            <button className='btn btn-outline-dark'>JOIN US NOW</button>
          </div>
        </Col>

        <Col data-aos="fade-up"
      data-aos-anchor-placement="bottom-bottom"  data-aos-duration="800" xs={12} sm={12} md={12} lg={6} xl={3} className="" style={colStyle2} >
        </Col>


        <Col data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom" data-aos-duration="1000" xs={12} sm={12} md={12} lg={6} xl={3} className="" style={colStyle4}>
          <div style={whiteSectionStyle}>
            <p>
            Customize your menu and reach out to everyone. We start at $29.
            </p>
            <button className='btn btn-outline-dark'>SUBSCRIBE NOW</button>
          </div>
        </Col>

       
      
        
      </Row> 
       </Container>*/}




      <div style={{ background: '#F6F6FA' }}>
        <section>
          <Reviews1_1 />
        </section>
      </div>

      {/* <section  className='bgStamp' style={{display:'block', minHeight:'100vh' }}>


      <div style={{padding:'40px',padding:'100px 0'}}>
        <div style={{position:'absolute',zIndex:'9999'}}>
                <h2 style={{textAlign:'center',padding:'30px',fontWeight:'600',color:'black'}}>What People Are Saying</h2>

          <Testimonial testimonials={testimonialData} />
      </div>
    </div>
    </section> */}


      <section>
        <div className="strip" data-aos="fade-up" data-aos-anchor-placement="top-bottom" style={{ marginTop: '0px', padding: '50px 20px', background: '#FFCA01', color: 'black' }}>
          <div className="row">
            <div className="col" style={{ textAlign: 'center' }}>
              <div style={{ margin: '30px 0', textAlign: 'center', alignItems: 'center', color: 'rgb(0,0,0)', fontWeight: '700' }}>
                <Row style={{ margin: '0 20px' }}>
                  <Col sm={8} style={{ margin: '0 30px' }}><h4 style={{ color: 'black',textAlign:'left' }}>Food is our passion! Your ideas and suggestions empower us to assist you better. We eagerly anticipate hearing from you.</h4>
                  </Col>

                  <Col style={{ textAlign: 'center' }} className="pTop20">
                  <a href={`${process.env.PUBLIC_URL}/contact-us`} style={{ textDecoration: 'none' }} className='links' id="FooterLink"><Button id='BBColor'  className="btn--theme hover--tra-grey " style={{ minWidth: '150px', borderRadius: '12px' }}>
                      Contact Us
                    </Button></a>
                  </Col>

                </Row>
            </div>
          </div>
              </div>
        </div>
      </section>

    </>
  );
}
