// library import section
import React, {useState , useEffect} from 'react';

// import nl2br from 'react-newline-to-break'; 
import TextField from '@mui/material/TextField';

import {Paper,Button , Grid, Card , Checkbox,CardContent,FormControlLabel , Divider ,InputLabel, Select , MenuItem , FormHelperText , Radio, Icon} from '@material-ui/core'
import Container from '@mui/material/Container';
// import restaurantInfoStyle from "../../assets/jss/material-dashboard-react/views/restaurantInfoStyle.js"
// import WebsiteHeader from '../Navbars/WebsiteNavBar'
// import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';

import Typography from '@material-ui/core/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import {globalclasses} from '../globalcsss'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import Footer from '../Website/footer';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PasswordInput from '../PasswordInput';
// import { CognitoUserPool } from 'amazon-cognito-identity-js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
//import MuiPhoneNumber from "material-ui-phone-number";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { userPool} from '../CognitoVariables';
import './SignUpComp.css'
import logo from '../Images/b8eLogo.png'
import Header from '../Header';

// import { fontSize } from '@mui/system';
// import { Email } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  
  //  sectionOneSignUp: {
  //   height: '130vh',
  //   [theme.breakpoints.down('md')]: {
  //     height: '120vh',
  //   },
  //   /* Center and scale the image nicely */
  //   backgroundPosition: 'center',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  //   webkitClipPath: 'polygon(0 0, 1920px 0, 1920px 87%, 0 100%)',
  //   clipPath: 'polygon(0 0, 100% 0, 100% 87%, 0 100%)'
  // },
  // header: {
  //   position: 'relative',
  //   top: '15vh',
  //   textAlign: 'center',
  //   '& h4': {
  //     fontWeight: '600',
  //     paddingBottom: '1.5vh',
  //     color: '#fff'
  //   },
  //   '& h5': {
  //     color: '#fff'
  //   },
  // },
  // headerTitle: {
  //   position: 'relative',
  //   top: '35%'
  // },
  // passwordgrid:{
  //   '@media  (max-width: 768px)':{
  //     marginTop:'-12px'
  //    }
    
  // },
  
  //  headerForm: {
  //   padding: '4ch',
  // },
  // sectionTwoHeaderTitle: {
  //   '& h4': {
  //     fontWeight: '600',
  //   },
  // },
  // sectionTwoSignUp: {
  //   textAlign: 'center',
  // },
 
  // body:{
  // paddingTop:'110px'
  // },
  // footer: {
  //   backgroundColor: '#212121 !important',
  //   marginTop:'9vh'
  // }
 


}))




function SignUpComp(){
 

  // const TextField = styled(MuiTextField)(({ theme }) => ({
  //   '& .MuiOutlinedInput-root': {
  //      '& fieldset': {
  //        borderColor: theme.palette.primary.main, // Default border color
  //      },
  //      '&:hover fieldset': {
  //        borderColor: theme.palette.secondary.main, // Border color on hover
  //      },
  //      '&.Mui-focused fieldset': {
  //        borderColor: theme.palette.error.main, // Border color when focused
  //      },
  //   },
  //  }));
  
    
const [disableSubmit,setdisableSubmit]=useState(true);
  const classes = useStyles();
  const globalclass = globalclasses();
  // State values
  const [signUp, setSignUp] = useState({
    firstName:'',
    lastName:'',
    phone:'',
    email:'',
    confirmEmail:'',
    password:'',
    confirmPassword:''
  })

  

  useEffect(() => {
    if(signUp.firstName!="" &&
      	signUp.lastName !="" &&
        signUp.phone !="" &&
        signUp.email !="" &&
        signUp.confirmEmail !="" &&
        signUp.password !="" &&
        signUp.confirmPassword !=""
        )
      setdisableSubmit(false);
    else
    setdisableSubmit(true);
    }
  );

  const [errorText, setErrorText] = useState({});
  const [errorState, setErrorState] = useState({});

  const handleOperations = e => {
    e.preventDefault();
  };

  const handleInputChange = e => {
    const { name, value } = e.target
    console.log(e.target)

    setSignUp({
      ...signUp,
      [name]: value
    })
  }

  const handleNameChange = e => {
    const { name, value } = e.target
    const newName = e.target.value.replace(
      /[^a-zA-Z\s]/g,
      ""
    );
    console.log(e.target)
    setSignUp({
      ...signUp,
      [name]: newName
    })
  };

  const handlePasswordChange = e => {
    const { name, value } = e.target
    const newName = e.target.value.replace(
      /[" "]/g,
      ""
    );
    console.log(e.target)
    setSignUp({
      ...signUp,
      [name]: newName
    })
  };
  

  
  //submit function
  const  handleSubmit = e => {
   e.preventDefault()
    localStorage.setItem('firstName', signUp.firstName)
    localStorage.setItem('lastName', signUp.lastName)
    localStorage.setItem('phone', signUp.phone)
    localStorage.setItem('email', signUp.email)
    localStorage.setItem('password', signUp.password)
    
    
   
    if (validate()){   //checks for form validation using validation()
      console.log("done")
       const attributeList = [];
       console.log("validateentry",attributeList)
       const userName=signUp.email;
       const password=signUp.password;
  
       const dataGivenName = {
         Name: 'given_name',
         Value: signUp.firstName
       }
       const dataFamilyName = {
         Name: 'family_name',
         Value: signUp.lastName
       }
       const dataPhoneNumber ={
         Name: 'phone_number',
         Value: "+"+signUp.phone
       }
       const dataEmail = {
         Name: 'email',
         Value: signUp.email
       }
  
       const dataUserID = {
         Name: 'custom:userID',
         Value: "dummy"
       }
       const dataRestaurantID = {
         Name: 'custom:restaurantID',
         Value: "dummy"
       }
       const dataBuildStageID = {
         Name: 'custom:buildStageID',
         Value: "dev"
       }
       const dataUserTypeID = {
         Name: 'custom:userTypeID',
         Value: "dummy"
       }
  
       const attributeGivenName = new AmazonCognitoIdentity.CognitoUserAttribute(dataGivenName);
       const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
       const attributeFamilyName = new AmazonCognitoIdentity.CognitoUserAttribute(dataFamilyName);
       const attributePhoneNumber = new AmazonCognitoIdentity.CognitoUserAttribute(dataPhoneNumber);
       const attributeUserID = new AmazonCognitoIdentity.CognitoUserAttribute(dataUserID);
       const attributeRestaurantID = new AmazonCognitoIdentity.CognitoUserAttribute(dataRestaurantID);
       const attributeBuildStageID = new AmazonCognitoIdentity.CognitoUserAttribute(dataBuildStageID);
       const attributeUserTypeID = new AmazonCognitoIdentity.CognitoUserAttribute(dataUserTypeID);
       attributeList.push(attributeGivenName);
       attributeList.push(attributeFamilyName);
       attributeList.push(attributeEmail);
       attributeList.push(attributePhoneNumber);
       attributeList.push(attributeUserID);
       attributeList.push(attributeRestaurantID);
       attributeList.push(attributeBuildStageID);
       attributeList.push(attributeUserTypeID);
  console.log("list",attributeList)
       //Signing up the user using aws cognito
       console.log("un",userName)
       console.log("pd",password)
       console.log("al",attributeList)
      
      userPool.signUp(userName, password, attributeList, null, (err, data) => {
         if (err) {
           console.error("er1",err);
           // Check if the error is due to user confirmation
           if (err.code === "UsernameExistsException") {
             console.error("er2",err.message);
             let errorSubmit = "User already exists. Please use a different email.";
             document.getElementById("errorSubmit").innerHTML = errorSubmit;
             // window.location.assign("phone-number-verification");
           }  else {
             // Handle other error cases or proceed with the rest of your logic
             console.error("er3", err);
             let errorSubmit = err.message;
             document.getElementById("errorSubmit").innerHTML = errorSubmit;
             console.log("User exists");
           }
           } 
           
         else {
           //console.error("er4",err);
           console.log(data);
           window.location.assign("phone-number-verification");
         }
       });
    }  
    //if ends
  }

  // function to check form validation
  const validate = () => {
    let tempText = {}
    let tempState = {}

    tempText.firstName = signUp.firstName ? "" : "Field cannot be Empty"
    tempState.firstName = (tempText.firstName !== "") ? true : false
    if(signUp.firstName !== ""){
      tempText.firstName = (/([a-z][A-Z]*)$/).test(signUp.firstName) ? "":"Firstname is not valid"
      tempState.firstName = (tempText.firstName !== "") ? true : false
    }
    console.log("firstnamr",tempText.firstName)
    
    tempText.lastName = signUp.lastName ? "" : "Field cannot be Empty"
    tempState.lastName = (tempText.lastName !== "") ? true : false
    if(signUp.lastName !== ""){
      tempText.lastName = (/([a-z][A-Z]*)$/).test(signUp.lastName) ? "":"Lastname is not valid"
      tempState.lastName = (tempText.lastName !== "") ? true : false
    }
    if(signUp.phone == ""){ 
      let errorPhone="Field cannot be Empty";
      tempState.phone = (errorPhone == "") ? true : false
      tempText.phone = errorPhone
     // document.getElementById('errorPhone').innerHTML=errorPhone
      
      
    }
    else if(signUp.phone<1999999999){
      let errorPhone="Invalid phone number";
      tempState.phone = (errorPhone == "Invalid phone number") ? true : false
      tempText.phone = errorPhone
      //document.getElementById('errorPhone').innerHTML=errorPhone
      
    }
    else {
      let errorPhone="";
      tempState.phone=false
      tempText.phone = errorPhone
     // document.getElementById('errorPhone').innerHTML=errorPhone
     
       }

    tempText.email = signUp.email ? "" : "Field cannot be empty"
    tempState.email = (tempText.email !== "") ? true : false

    if(signUp.email !== ""){
      tempText.email = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(signUp.email) ? "":"Email is not valid"
      tempState.email = (tempText.email !== "") ? true : false
    }
    
    if(signUp.confirmEmail !== ""){
      if(signUp.confirmEmail !== signUp.email){
        tempText.confirmEmail = "Email Id does not Match"
        tempState.confirmEmail = (tempText.confirmPassword !== "") ? true : false
      }
    }
    else{
      tempText.confirmEmail = signUp.confirmEmail ? "" : "Field cannot be Empty"
      tempState.confirmEmail = (tempText.confirmEmail !== "") ? true : false
    }

    tempText.password = signUp.password ? "" : "Field cannot be empty"
    tempState.password = (tempText.password !== "") ? true : false

    var pText="";
    var pwd=signUp.password;
    if(signUp.password !== ""){
      // tempText.password = (/^(?!.* )(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/).test(signUp.password) ? "": nl2br("Password is not valid\n- Your password must be at least\n- have 8 characters long\n- have 1 uppercase & 1 lowercase character\n- have 1 number\n- have 1 special character")
      tempText.password = (/^(?!.* )(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/).test(signUp.password) ? "": "Your password must be at least 8 characters long, have 1 uppercase & 1 lowercase character, have 1 number and 1 special character."
      // pText+=(/[^A-Z]$/).test(signUp.password) ? "":"\n- have 1 uppercase letter"
      // pText+=(/[^a-z]$/).test(signUp.password) ? "": "\n- have 1 lowercase letter"
      // pText+=(/[^0-9]$/).test(signUp.password) ? "": "\n- have 1 number"
      // alert(pText)

      // tempText.password = (/^(?!.* )(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/).test(signUp.password) ? "": pText
      tempState.password = (tempText.password !== "") ? true : false
    }

    if(signUp.confirmPassword !== ""){
      if(signUp.confirmPassword !== signUp.password){
        tempText.confirmPassword = "Passwords do not Match"
        tempState.confirmPassword = (tempText.confirmPassword !== "") ? true : false
      }
    }
    else{
      tempText.confirmPassword = signUp.confirmPassword ? "" : "Field cannot be Empty"
      tempState.confirmPassword = (tempText.confirmPassword !== "") ? true : false
    }
      
    
    setErrorText({
      ...tempText
    })
    setErrorState({
      ...tempState
    })
    return Object.values(tempText).every(x => x == "")

  }

  // front end design
  return(
    <section>
        <div>
                  <section id="hero-5" className=" bg--scroll  page font--jakarta" style={{paddingTop:'0px'}}>

              <div className={`${'description-wrap'} ${globalclass.body}`} >
                      <Header/>
                      <Container style={{paddingBottom:'2.5rem'}}>   
            <form  noValidate >
            <CssBaseline />
            <Paper elevation={3} className={globalclass.paperalign} style={{textAlign:'center'}}>  
                <Grid container spacing={1} >
                <Grid item xs={12} sm={12} md={12}>
                <img className={`${globalclass.logoSize} lazyloaded`} id='logo' src={logo} data-src={logo}/>
                {/* <img title className=" lazyloaded" alt="" src="https://ikabitss.com/wp-content/uploads/2020/06/combined_logo-circular-320.png" data-src="https://ikabitss.com/wp-content/uploads/2020/06/combined_logo-circular-320.png" /> */}
                </Grid>
                    <Grid  item xs={12} sm={12} md={12}>
                    <Typography variant='h5'  style={{color:'#302BC3',fontWeight:'700'}}>
                          Admin User Information
                    </Typography>
                    
                    <Typography variant= "h4" className="textH4"  style={{marginBottom:'15px',fontSize:'1.5rem'}}>
                          (Use All Premium Features For Free - For 60 Days)
                    </Typography>

                    </Grid>
  
                    <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="h5" style={{textAlign:'center',fontSize:'1.2rem' }} className="textH5" >Owner Login (Restaurant Owners Login - Admin User)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                          <Divider variant='fullWidth' className={globalclass.divider}/>
                    </Grid>
                     <Grid  container spacing={2} >
                    <Grid  item xs={12} sm={6} md={4}  >

    
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="firstName"
                            label="First Name*"
                            name="firstName"
                           
                            value={signUp.firstName}
                            onChange={handleNameChange}
                            error={errorState.firstName}
                            helperText={errorText.firstName}
                            inputProps={{ maxLength: 120 }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >                
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="lastName"
                            label="Last Name*"
                            name="lastName"
                           
                            value={signUp.lastName}
                            onChange={handleNameChange}
                            error={errorState.lastName}
                            helperText={errorText.lastName}
                            
                            inputProps={{ maxLength: 120 }}
                          />
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} className={globalclass.phoneinputgrid} >
                          <PhoneInput
                                     country={"us"}
                                  //   styles={::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                  //   color: pink;
                                  // }}
                                  className={ globalclass.customTextField }
                                    fullWidth
                                    inputProps={{
                                    id:'phoneNumberInput',
                                    // required: true,
                                    placeHolder: 'Phone Number (SMS Capable) *',
                                    autoFocus: false,
                                  }}
                                  name="phone"
                                  // id="flagInput"
                                  value={signUp.phone}
                                  onChange={(phone) => setSignUp({...signUp,['phone']:phone})       }
                                 helperText={errorText.phone}
                          />
                          
                               {errorState.phone  &&  <FormHelperText id="errorPhone" style={globalclass.helpertextphone}
                      >{errorText.phone} </FormHelperText>}
                      
                    </Grid>
                   

                    <Grid item xs={12} sm={6} md={4}  >
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            label="Email ID* (used to login)"
                            name="email"
                            value={signUp.email}
                            onChange={handleInputChange}
                            error={errorState.email}
                            helperText={errorText.email}
                            inputProps={{ maxLength: 120 }}
                          />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="confirmEmail"
                            label="Confirm Email ID*"
                            name="confirmEmail"
                            onCopy={handleOperations}
                            onCut={handleOperations}
                            onPaste={handleOperations}
                            value={signUp.confirmEmail}
                            onChange={handleInputChange}
                            error={errorState.confirmEmail}
                            helperText={errorText.confirmEmail}
                            inputProps={{ maxLength: 120 }}
                          />
                    </Grid>

                    {/* <Grid  className={globalclass.hidegrid} item xs={3} sm={0} md={4}>
                    </Grid> */}

                    <Grid item xs={12} sm={6} md={4}>
                          <PasswordInput
                           label="Password*"
                            name="password"
                            variant="outlined"
                            fullWidth
                            id="password"
                           
                            value={signUp.password}
                            // onChange={handleInputChange}
                            onChange={handlePasswordChange}
                            error={errorState.password}
                            helperText={errorText.password}
                            inputProps={{ maxLength: 120 }}
                          />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >
                          <PasswordInput
                            label="Confirm Password*"
                            name="confirmPassword"
                            variant="outlined"
                            fullWidth
                            id="confirmPassword"
                            onCopy={handleOperations}
                            onCut={handleOperations}
                            onPaste={handleOperations}
                            value={signUp.confirmPassword}
                            onChange={handleInputChange}
                            error={errorState.confirmPassword}
                            helperText={errorText.confirmPassword}
                            inputProps={{ maxLength: 120 }}
                          />
                    </Grid>
                    {/* <Grid item xs={3} sm={6} md={4}>
                    </Grid> */}
                    </Grid>
                    <Grid item xs={12} style={{textAlign:'center'}}>
                                    <FormHelperText id="errorSubmit" className={globalclass.errortextsubmit}> </FormHelperText>
                    </Grid>

                    <Grid item xs={12} >
                          <Button
                            type="button"
                          //  fullWidth
                            variant="contained"
                            color="warning"
                            style={{ backgroundColor: !disableSubmit ? '#FEB300' : '',color: !disableSubmit ? 'black' : '', fontWeight: '600' }}
                            disabled={disableSubmit}
                            className={"button"}  
                            onClick={handleSubmit}
                            id="submit"

                          >
                            Click Next To Continue
                          </Button>
                    </Grid>
                </Grid>
                    </Paper>
                </form>
                </Container>
              </div>
              {/* <div className={globalclass.footer}>
                  <Container>
                    <Footer />
                  </Container>
              </div> */}
        </section>
        </div>
        </section>
  ) 
}export default SignUpComp;