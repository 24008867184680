import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Header from './Header';

const Welcome = () => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        const Uname = localStorage.getItem('Username');
        const extractedUsername = Uname.split('@')[0];

        setUsername(extractedUsername);
    }, []);

    return (
        <div style={{  backgroundColor: '#feb300' }}>
            <Header />


            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:'70vh'}}>

                {username &&
                    <Typography variant="h4" style={{ color: 'black', textAlign: 'center' }}>Hi!! {username}</Typography>
                }
                <Typography variant="h5" style={{ color: 'black' }}>Coming Soon</Typography>
            </div>

        </div>
    );
};

export default Welcome;
