
import CounterUp from "./CounterUp"

import './download.css'
import './bootstrap.min.css'


export default function Stat() {
    return (
        <>
            <div id="statistic-1" className="pt-100 statistic-section division marginMobile" style={{color:'black'}}>
                <div className="container">
                    {/* STATISTIC-1 WRAPPER */} 
                    <div className="statistic-1-wrapper" >
                        <div className="row justify-content-md-center row-cols-1 row-cols-md-3">

                                {/* STATISTIC BLOCK #2 */}
                                <div className="col">
                                <div id="sb-1-2" className="wow fadeInUp">
                                    <div className="statistic-block">
                                        {/* Digit */}
                                        <div className="statistic-block-digit text-center">
                                            <h2 className="s-46 statistic-number" style={{color:'black'}}><CounterUp end={75} /><span style={{color:'#1680FB'}}>%</span></h2>
                                        </div>
                                        {/* Text */}
                                        <div className="statistic-block-txt color--grey">
                                            <p className="p-md">Opt to personally pick up their food orders.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>	{/* END STATISTIC BLOCK #2 */}
                            
                            {/* STATISTIC BLOCK #1 */}
                            <div className="col" >
                                <div id="sb-1-1" className="wow fadeInUp">
                                    <div className="statistic-block">
                                        {/* Digit */}
                                        <div className="statistic-block-digit text-center">
                                            <h2 className="s-46 statistic-number" style={{color:'black'}}><CounterUp end={90} /><span style={{color:'#1680FB'}}>%</span></h2>
                                        </div>
                                        {/* Text */}
                                        <div className="statistic-block-txt color--grey">
                                            <p className="p-md">Regularly choose to dine from a curated list of 8 preferred restaurants.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>	{/* END STATISTIC BLOCK #1 */}
                        
                            {/* STATISTIC BLOCK #3 */}
                            <div className="col">
                                <div id="sb-1-3" className="wow fadeInUp">
                                    <div className="statistic-block">
                                        {/* Digit */}
                                        <div className="statistic-block-digit text-center">
                                            <h2 className="s-46 statistic-number">
                                                {/* <CounterUp end={4} />.<CounterUp end={93} /> */}

                                                <h2 className="s-46 statistic-number" style={{color:'black'}}><CounterUp end={93} /><span style={{color:'#1680FB'}}>%</span></h2>
                                            </h2>
                                        </div>
                                        {/* Text */}
                                        <div className="statistic-block-txt color--grey">
                                            <p className="p-md">Restaurants provide takeout services</p>
                                        </div>
                                    </div>
                                </div>
                            </div>	{/* END STATISTIC BLOCK #3 */}
                        </div>  {/* End row */}
                    </div>	{/* END STATISTIC-1 WRAPPER */}
                </div>	 {/* End container */}

                <div style={{margin:'50px 50px 0'}}>                
                    <hr className="divider" />
                </div>

            </div>
        </>
    )
}
