import React, { useState, useEffect, createContext } from 'react';
import {FormHelperText} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
// import TextField from '@material-ui/core/TextField';
import TextField from '@mui/material/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import PasswordInput from './PasswordInput';
import * as AWS from 'aws-sdk/global';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import {userPool,cognitoUser} from './CognitoVariables';
import * as APIinterfaces from "./ApiInterface";
import { globalclasses } from './globalcsss';
import logo from './Images/b8eLogo.png';
import { getToken } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import { initializeApp } from "firebase/app";
// const [rememberMe, setrememberMe]=React.useState(false);
import { createTheme, ThemeProvider} from '@mui/material/styles';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyC9j0pAKa1UySq61ItgtkS4uliGXXnbyR0",
  authDomain: "sendnotification-3b45c.firebaseapp.com",
  projectId: "sendnotification-3b45c",
  storageBucket: "sendnotification-3b45c.appspot.com",
  messagingSenderId: "879233161514",
  appId: "1:879233161514:web:dd0521b3f4da3bf42a2653",
  measurementId: "G-CQ1Y6LXG8X"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
 // Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);


const theme = createTheme({
  palette: {
    ochre: {
      main: '#E3D026',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
  },
});


const useStyles = makeStyles((theme) => ({



  paper: {
    //marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: ('7px','0px','16px','0px'),
    marginTop:'35px'
  },
  card:{
    padding:theme.spacing(4)
  },
  bottomGrid:{
    textAlign:'center',
  },
  footer: {
    backgroundColor: '#212121 !important',
  }
}));


const StyledTextField = styled(TextField)({
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
       '-webkit-appearance': 'none',
       margin: 0,
    },
    '& input[type="number"]': {
       '-moz-appearance': 'textfield',
    },
   });


export default function Login() {
  const globalclass = globalclasses();
  var idToken="";
  var accessToken="";
  var refreshToken="";
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [companyId, setcompanyId] = React.useState('');
  const [password, setPassword] = useState('');
  const [errorText , setErrorText] = useState({});
  const [errorState , setErrorState] = useState({});
  const [checked, setChecked] = React.useState(true);

  // const { authenticate } = createContext();

  // fetch restaurant id from localstorage
  var restid = localStorage.getItem('restaurantID')
  console.error("restid" + restid)
  // fetch email from localstorage
  var restloginid = localStorage.getItem('email')
  console.error("rest login id" + restloginid)

  useEffect(() => {
    if(checked){
      localStorage.setItem('rememberMe',"true");
      // update the stored variable on signin form (on loading)
      console.log(restid);
      if(companyId===null){
        setcompanyId('');
      }
      if ((restid !=="") && (restid !==null)) {
          setcompanyId(restid);
      }
      if ((restloginid != "") && (restloginid !== null)){
          setEmail(restloginid);
      }

    }    
    else{
      localStorage.setItem('rememberMe',"false");
      // update the stored variable on signin form (on loading)
      // update the stored variable on signin form (on loading)
      if ((restid !=="") && (restid !== null)) {
          setcompanyId(restid);
      }
      
      if ((restloginid !=="") && (restloginid !== null)){
          setEmail(restloginid);
      }
    }
    },[checked]
  );

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validate = () =>{
    let tempText =[]    
    let tempState = []
    if(email==""){
      tempText.email="Field cannot be Empty"
      tempState.email = (tempText.email !== "") ? true : false    
    }
    else{
    tempText.email = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email) ? "":  "Email is not valid"
    tempState.email = (tempText.email !== "") ? true : false    
    }

    tempText.password = password ? "": "Field cannot be Empty"
    tempState.password = (tempText.password !== "") ? true :false
    // tempText.companyId = companyId ? "": "Field cannot be Empty"
    // tempState.companyId = (tempText.companyId !== "") ? true :false

    if(companyId == "" || companyId===null){ 
      tempText.companyId = "Field cannot be Empty"
      tempState.companyId = (tempText.companyId !== "") ? true : false
    }
    else{
      
       tempText.companyId = (/([1-9][0-9]{7})/).test(companyId) ? "" : "Invalid Restaurant ID"
      

      // if((/([1-9][0-9]{7})/).test(companyId))
      //   {
      //     tempText.companyId = ""        
      //   }
  
      //   else{
      //     tempText.companyId = "Invalid Restaurant ID"
      //   }
      // }
      
      tempState.companyId = (tempText.companyId !== "") ? true : false
    }

    setErrorText({
      ...tempText
    })
    setErrorState({
      ...tempState
    })

    return Object.values(tempText).every(x => x == "" )  // return true if all the fields are empty
  }



var Username=email;

// var userData = {
//     Username:email,
//     Pool: userPool,
//   };


  const handleSubmit = e =>{
    document.getElementById("errorLogin").innerHTML=""
    // e.preventDefault()
    if(validate())
    {
      localStorage.setItem('email',email);
      localStorage.setItem('password',password);
      localStorage.setItem('Username',Username);
      localStorage.setItem('Password',password);
      localStorage.setItem('cognitoUserLocal',cognitoUser);
      localStorage.setItem('restaurantID',companyId);

      //console.error("Username - email is ", email);
      //console.error("Username - pw is ", password);
      var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: email,
        Password: password,
      });

    // alert(cognitoUser.getUsername())
    var userData = {
         	    Username: email,
                    Pool: userPool,
                   };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    localStorage.setItem('cognitoUserLocal',cognitoUser);
    //console.error("cognito local user is set ", cognitoUser);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          accessToken=result.getAccessToken().getJwtToken();
          idToken=result.getIdToken().getJwtToken();
          refreshToken=result.getRefreshToken().getToken();
        
            // console.log('access token + ' + accessToken);
            // console.log('id token + ' + idToken);
            // console.log('refresh token + ' + refreshToken);
            // window.location.href = '/user/dashboard';
            localStorage.setItem('accessToken',accessToken)
            localStorage.setItem('idToken',idToken)
            localStorage.setItem('refreshToken',refreshToken)
            localStorage.setItem('loginStatus', true)
            // cognitoUser.getSession((e)=> console.log(e || 'session acquired' ))
            // cognitoUser.getSession((e)=> alert(e || 'session acquired' ))
            verifyRestaurantID(); 
            requestPermission();        
          },
        onFailure: function(err) {
          let errorLogin=err.message;
          localStorage.setItem('loginStatus', false)
          document.getElementById("errorLogin").innerHTML=errorLogin
          
          // console.log(err);
        },
    });
    }
   }

   

  async function requestPermission() {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    // Generate Token
    const token = await getToken(messaging, {
      vapidKey:
        "BH2B5fOqtwCkaD7B9d_tcR_vgiJXARJyOqUXO4o2uj3LPtJcg8JwS0KcjKSwNQBuffQIyiA0qjgtBsBep7yB7K4",
    });
    console.log("Token Gen", token);
  //   var token = token;
    localStorage.setItem("token", token);
  //   setToken(token)
    
    // Send this token  to server ( db)
  } else if (permission === "denied") {
    alert("You denied for the notification");
  }
}
   
const formatInput = (e) => {
     // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
     let checkIfNum;
     if (e.key !== undefined) {
       // Check if it's a "e", ".", "+" or "-"
       checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-" ;
     }
     else if (e.keyCode !== undefined) {
       // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
       checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
     }
     return checkIfNum && e.preventDefault();
   }

function fetchBackend() {
  const restloginid = localStorage.getItem('email');
  const restid = localStorage.getItem('restaurantID');
  const toSenddata = {
      "userEmailID": restloginid,
      "restaurantID": restid
  };

  console.log(JSON.stringify(toSenddata));

  fetch(APIinterfaces.restaurantlistget, {
      method: 'POST',
      mode: 'cors',
      headers: {
          "Content-type": "application/json",
      },
      body: JSON.stringify(toSenddata)
  }).then(response => {
      if (response.ok) {
          return response.json();
      } else {
          throw new Error('Failed to fetch data');
      }
  }).then(json => {
      console.log("json", json);

      try {
          const allEmpty = json.every(isEmptyObject);

          if (allEmpty) {
              console.log("All objects in the array are empty.");
            //   window.location.assign("/user/restaurant-info");
            window.location.assign("/welcome");

          } else {
              console.log("At least one object in the array is not empty.");
            //   window.location.assign("/user/dashboard");
              window.location.assign("/welcome");
          }
      } catch (error) {
          console.error('Error processing response:', error);
      }
  }).catch(error => {
      console.error('Fetch error:', error);
      // Handle fetch errors gracefully, show error message to the user, or retry.
  });
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 1 && Object.keys(obj)[0] === "nil" && Object.keys(obj["nil"]).length === 0;
}
 
function verifyRestaurantID()
   {
      var email=localStorage.getItem("email");
      var toSenddata={
        "userEmailID": email,
        "restaurantID": companyId
      }
      console.log(JSON.stringify(toSenddata))
      fetch(APIinterfaces.restperssigninverify,{
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-type": "application/json",
      },
      body:JSON.stringify(toSenddata)
    }).then(response=>{
      console.log(response)
      if(response.status === 200){
          return response.json()
      }
      else if(response.status === 400){
          console.log("Error 400")
          console.log("Something went wrong please try again.")
          let errorLogin="Something went wrong please try again.";
          document.getElementById("errorLogin").innerHTML=errorLogin
          return "";
      }
        else if(response.status === 401){
          console.log("Error 401")
          console.log("Data Not Found.")
          let errorLogin="Data Not Found.";
          document.getElementById("errorLogin").innerHTML=errorLogin
          return "";
          }
          else if(response.status === 404){
              console.log("Error 404")
              return "";
            }
            else if(response.status === 423){
              let errorLogin="Invalid Restaurant ID";
              document.getElementById("errorLogin").innerHTML=errorLogin
              return "";
            }
      })
      .then((json) => {
      
              localStorage.setItem("userPerms",JSON.stringify(json));
              
              console.log("json",localStorage.getItem('userPerms'));
            if(json.userStatus=='active')
            fetchBackend()
             //window.location.assign("/user/dashboard");
      })
  }
  console.log()
//Checking restaurant ID ends
const navigate = useNavigate();

const handleClick = () => {
  console.log('Clicked SVG 1');
  navigate('/Need-help')

  // Add your logic here for SVG 1 click
};


const handleRestaurantIDChange = (evt) => {
  const re = /^[0-9\b]+$/;
  const { name, value } = evt.target
  if (evt.target.value == "" || re.test(evt.target.value)) {
    setcompanyId(value)    
  }
}
  //  const handleRestaurantIDChange = (evt) => {
  //   // const re = /^[0-9\b]+$/;
  //   // if (evt.target.value == "" || re.test(evt.target.value)) {
  //   //     // this.setState({ companyid: evt.target.value, });
  //   //     setcompanyId(evt.target.value);
  //   // }
  //   const re = /^[0-9a-zA-Z\b]+$/;
  //   const { name, value } = evt.target
  //   if (evt.target.value == "" || re.test(evt.target.value)) {
  //       // this.setState({ companyid: evt.target.value, });
  //       // setcompanyId(evt.target.value);
  //       setcompanyId(value);
  //   }
  // }
  //  const handleRestaurantIDChange = e => {
  //   const { name, value } = e.target
  //   const newID = e.target.value.replace(
  //     /[" "\.a-zA-Z]/g,
  //     ""
  //   );
  //   console.log(e.target)
  //   setcompanyId(newID)
  // };
  console.log(companyId,"companyId")

  return (
    <div>
      


    
    <div id='login' className={globalclass} >    
    <Header />

    <Container component="main" maxWidth="xs" className='pb-50' >
      <Card className={classes.card}>
      <div className={classes.paper}>
      <Grid item xs={12} sm={12} md={12}> 
       <img className={`${globalclass.logoSize} lazyloaded`} id='logo' src={logo} data-src={logo}/>
       </Grid>
       <Typography component="h1" variant="h5" style={{color:'#302BC3',fontWeight:'700'}}>
          Best8Eats
        </Typography>
        <Typography component="h3" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container style={{marginTop:'-13px'}}>
        <Grid item xs={12} sm={12} md={12}> 
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            error={errorState.email}
           // helperText={errorText.email}
           className={classes.customTextField}

            autoComplete="email"
            autoFocus
          />
           <FormHelperText id="errorEmail_login" style={{ color: '#f44336',textAlign:'center',margin:'1%',marginBottom:'1%'}}>{errorText.email}</FormHelperText>
           </Grid>
           <Grid item xs={12} sm={12} md={12}> 
           
          <PasswordInput  
          style={{marginTop:'1vh'}}
            label="Password*"
            name="password"
            id="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            error={errorState.password}
            //helperText={errorText.password}
          />
           <FormHelperText id="errorPassword_login" style={{ color: '#f44336',textAlign:'center',margin:'1%',marginBottom:'1%'}}>{errorText.password}</FormHelperText>
          {console.log(companyId)}
          </Grid>
          <Grid item xs={12} sm={12} md={12}> 
           <StyledTextField
            type="number"
            variant="outlined"
            margin="normal"
            // required
            fullWidth
            id="company-id"
            label="Restaurant ID*"
            name="company-id"
            value={companyId}
            // onChange={event => setcompanyId(event.target.value)}
            onChange={handleRestaurantIDChange}
            error={errorState.companyId}
            //helperText={errorText.companyId}    
            autoComplete="current-password"
            onInput={e => e.target.value = (e.target.value > 8) ? e.target.value = e.target.value.slice(0, 8) : e.target.value}
           onKeyDown={formatInput}  
            />
             <FormHelperText id="errorCompanyId_login" style={{ color: '#f44336',textAlign:'center',margin:'1%',marginBottom:'1%'}}> {errorText.companyId}</FormHelperText>
             </Grid>
             </Grid>
          <FormControlLabel
            
            control={<Checkbox id="checkRemember" value="remember" color="primary"  />}
            label="Remember me"
            id="rememberMe"
            checked={checked}
            varient="inline"
            onChange={handleCheckChange}
          />

        <FormHelperText id="errorLogin" style={{ color: '#f44336',textAlign:'center',margin:'1%',marginBottom:'1%'}}></FormHelperText>


          <Button
            type="button"
            fullWidth
            variant="contained"
            style={{ backgroundColor:'#FEB300',color:'black' }}
            className={classes.submit}
            onClick={handleSubmit}
            id="submit"
          >
            Login
          </Button>
          <Grid container style={{marginTop:'22px',textAlign:'center',color:'#302BCF'}} >
            <Grid item xs={12}>
              <Link className={globalclass.whitebackgroundLink}  onClick={handleClick} underline="hover"  id="need-help-sign-in">
              Need Help Signing In ?
              </Link>
            </Grid>
            <Grid item xs={12} >
              <Link className={globalclass.whitebackgroundLink} onClick={handleClick} underline="hover" id="help-page">
              Help Page
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      </Card>
    </Container>
    </div>
   
    </div>
  );
}