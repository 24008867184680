import { InputAdornment, withStyles } from '@material-ui/core';
// import TextField from '@material-ui/TextField';
import TextField from '@mui/material/TextField';

import { Visibility,VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  eye: {
    cursor: 'pointer',
  },
});

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordIsMasked: true,
    };
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  render() {
    const { classes } = this.props;
    const { passwordIsMasked } = this.state;

    return (
      <TextField
        type={passwordIsMasked ? 'password' : 'text'}
        fullWidth
        variant="outlined"
        {...this.props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
            <IconButton
                  aria-label="toggle password visibility"
                  className={classes.eye}
                    onClick={this.togglePasswordMask}
                >
                    {passwordIsMasked ? <Visibility /> : <VisibilityOff />}
                </IconButton>              
            </InputAdornment>
          ),
        }}
        //style={{marginTop:'1vh'}}
      />
    );
  }
}

PasswordInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired,
};

PasswordInput = withStyles(styles)(PasswordInput);

export default PasswordInput;