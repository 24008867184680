import { makeStyles } from '@material-ui/core/styles';
import FoodImage from './Images/11_a.jpg';

const globalclasses = makeStyles(theme => ({
  label: {
    color: 'blue'
},
  loginCheckbox:{
    textAlign:'center',
  marginLeft:'-138px !important',
  marginTop:'-8px !important',
  '@media (max-width: 1280px)': {
    marginLeft:'-160px !important',
  },
  '@media (width: 540px)': {
    marginLeft:'-184px !important',
  },
  '@media (max-width: 395px)': {
    marginLeft:'-145px !important',
  },
  '@media (max-width: 376px)': {
    marginLeft:'-130px !important',
  },
  },
  logoSize:{
   width:"70px !important"
  },
  paperwidth:{
    padding: '30px',
    [theme.breakpoints.up('sm')]: {
      width:'100%'
       },
    [theme.breakpoints.up('md')]: {
     width:'84%',
     marginLeft:'85px'
      },
    [theme.breakpoints.up('lg')]: {
        width:'100%'
         }
  },
 paperalign:{
    width:'100%',
    padding: '30px'
  },

  packageSelection:{
    width:'100%',
    package1:{
 [theme.breakpoints.up('sm')]: {
             marginLeft:'0px',
             marginRight:'0px'
},
[theme.breakpoints.up('md')]: {
  marginLeft:'0px',
  marginRight:'0px'
},
[theme.breakpoints.up('lg')]: {
  marginLeft:'0px',
  marginRight:'0px'
}
    },
    
    package3:{
      [theme.breakpoints.up('sm')]: {
        marginLeft:'0px',
        marginRight:'0px'
},
[theme.breakpoints.up('md')]: {
marginLeft:'0px',
marginRight:'0px'
},
[theme.breakpoints.up('lg')]: {
marginLeft:'0px',
marginRight:'0px'
}
    },
    
  },
//   body:{
//     paddingTop:'128px',
//     paddingBottom:'66px',
//     '@media (max-width: 425px)': {
//       paddingTop:'122px'
//     },
//     '@media (width: 540px)': {
//       paddingTop:'122px '
//     },
//     },
    bodyLogin:{
      paddingTop:'127px',
      paddingBottom:'62px',
      
     
      // [theme.breakpoints.down('md')]: {
      //   paddingTop:'12vh'
      //  },
       '@media (width: 540px)': {
        paddingTop:'16.5vh '
      },
      '@media (max-width: 425px)': {
        paddingTop:'118px'
      },
      
      
      backgroundImage:`url(${FoodImage})`,
     // height:'100vh',
      },
  customTextField: {
    "& input::placeholder": {
      fontSize: "5px"
    }
  },
  hidegrid: {
  [theme.breakpoints.up('sm')]: {
    display: "none"                   // secondary
  }
},
footer: {
  backgroundColor: '#212121 !important',
  
},
text3:{
  marginTop:'10px !important',
   color:'#212121',
   textAlign:'left',
   },
   divider:{
    
    padding:'1px !important',
    marginTop:'-15px !important',
    marginBottom:'25px !important',
    '@media (max-width: 915px)': {
     padding:'0.8px !important'
    },
    '@media (max-width: 425px)': {
      padding:'0.6px !important'
     },
   },
   phoneinputgrid:{
    paddingRight:"20px !important"
   },
   helpertextphone:{
    color: '#f44336',
    textAlign:'center',
    position:'relative',
    left:'-100px'
   },
   errortextsubmit:{
    color: '#f44336',
    textAlign:'center'
   },
   iconContainer: {
    "&:hover $icon": {
        color: 'red',
    }
},
icon: {
    color: 'blue',
},
link:{
  color: 'blue !important'
},
flexwrap:{
  display:'inline-flex',
  columnGap:'8px',
  rowGap:'8px',
  padding:'8px 8px 8px 8px',
   marginRight:'8px',
   marginLeft:'8px',
   marginTop:'16px',
  //paddingRight:"16px",
  flexWrap:'wrap',
  // border:'1px solid #E0E0E0'
},
alcdivlast:{
  display:'flex',
  columnGap:'8px',
  width: 'min-content',
 },
verticalalignspan:{
  fontSize: '15px',
  display:'inline-flex',
  alignItems:'center',
},
tablecell:{
  fontWeight:'bolder !important',
  [theme.breakpoints.down('xl')]: {
    fontSize:'20px !important',
    lineHeight:'20px !important',
    textAlign:'center !important',
    whiteSpace:"nowrap"
    },
    [theme.breakpoints.down('lg')]: {
      fontSize:'19px !important',
      lineHeight:'20px !important',
      textAlign:'center !important',
      whiteSpace:"nowrap"
      },
      [theme.breakpoints.down('md')]: {
        fontSize:'17px !important',
        lineHeight:'20px !important',
        textAlign:'center !important',
        whiteSpace:"nowrap"
        },
        [theme.breakpoints.down('sm')]: {
          fontSize:'15px !important',
          lineHeight:'20px !important',
          textAlign:'center !important',
          whiteSpace:"nowrap"
          }
},
tablecell2:{
  [theme.breakpoints.down('xl')]: {
  fontSize:'20px !important',
  lineHeight:'20px !important',
  textAlign:'center !important',
  whiteSpace:"nowrap"
  },
  [theme.breakpoints.down('lg')]: {
    fontSize:'19px !important',
    lineHeight:'20px !important',
    textAlign:'center !important',
    whiteSpace:"nowrap"
    },
    [theme.breakpoints.down('md')]: {
      fontSize:'17px !important',
      lineHeight:'20px !important',
      textAlign:'center !important',
      whiteSpace:"nowrap"
      },
      [theme.breakpoints.down('sm')]: {
        fontSize:'15px !important',
        lineHeight:'20px !important',
        textAlign:'center !important',
        whiteSpace:"nowrap"
        }
},
marginforlg:{
  '@media (max-width: 1496px)': {
    marginRight:'80px !important'
  },
  '@media (max-width: 1440px)': {
    marginRight:'36px !important'
  },
   [theme.breakpoints.down('md')]: {
    marginRight:'0px !important'
   }
},
promocodepb:{
  [theme.breakpoints.down('lg')]: {
    width:'250px'
   },
  [theme.breakpoints.down('lg')]: {
    width:'200px'
   },
   [theme.breakpoints.down('md')]: {
     width:'150px'
   },
   [theme.breakpoints.down('sm')]: {
     width:'100px'
   }
},
alprice:{
  [theme.breakpoints.down('xl')]: {
    width:'150px'
  },
  [theme.breakpoints.down('lg')]: {
   width:'125px'
  },
  [theme.breakpoints.down('md')]: {
    width:'125px'
  },
  [theme.breakpoints.down('sm')]: {
    width:'125px'
  }
},
alcalories:{
  [theme.breakpoints.down('xl')]: {
    width:'150px'
  },
  [theme.breakpoints.down('lg')]: {
    width:'125px'
  },
  [theme.breakpoints.down('md')]: {
    width:'125px'
  },
  [theme.breakpoints.down('sm')]: {
    width:'125px'
  }
},
alcsize:{
  [theme.breakpoints.down('xl')]: {
    width:'75px'
  },
    [theme.breakpoints.down('lg')]: {
      width:'90px'
    },
    [theme.breakpoints.down('md')]: {
      width:'90px'
    },
    [theme.breakpoints.down('sm')]: {
      width:'90px'
    }
},

  //////////////////////////// phonenumber-verification  /////////////////////////////
 responsiveotp:{
  [theme.breakpoints.up('xs')]: {
         height:'100%',
         margin:'0% 1% !important',
         textAlign:'center',
         width:'5%'            // secondary
  },
  [theme.breakpoints.up('sm')]: {
    height:'100%',
    margin:'0% 1% !important',
    textAlign:'center',
    width:'7%'            // secondary
},
[theme.breakpoints.up('md')]: {
  height:'100%',
  margin:'0% 1% !important',
         textAlign:'center',
  width:'5%'            // secondary
},
[theme.breakpoints.up('lg')]: {
  height:'100%',
  margin:'0% 1% !important',
         textAlign:'center',
  width:'4%'            // secondary
}
 },

 responsivebutton:{
  [theme.breakpoints.up('xs')]: {
    width:'100px'            // secondary
  },
  [theme.breakpoints.up('sm')]: {
    width:'150px'          // secondary
},
[theme.breakpoints.up('md')]: {
  width:'200px'            // secondary
},
 },responsivetext:{
  [theme.breakpoints.up('xs')]: {
    fontSize:'100px'            // secondary
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: "10px"     // secondary
},
[theme.breakpoints.up('md')]: {
  width:'200px'            // secondary
},
 },
  pipe:{
    display:'inline',
    color:'#FEB300'
  },
    footerlink:{
      color:'#63a4c2 !important',
      display:'inline'
    },
    whitebackgroundLink:{
      color:'#302bc3 !important',
    },
    boldHeading:{
      textAlign:'left !important',
      marginLeft:'-26px !important',
      marginTop:'5vh !important',
      fontWeight:'bold !important',
      paddingBottom:'20px !important'
    },
    holidaytextgrid:{
      width:'auto',
      //marginTop:'t'
      justifyContent:'center',
      alignContent:'center'
    },
    holiday:{
      position:'relative',
      top:'15%',
      left:'3%',
      textAlign:'left',
      paddingRight:'30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px !important',
      }
    },
    holidaydatetypograhy:{
      position:'relative',
      top:'15%',
      left:'3%',
      textAlign:'left',
      paddingRight:'30px !important',
      [theme.breakpoints.down('lg')]: {
        left:'-6%',
      }

    },
    holidaycontainer:{
      paddingBottom: '15px',
      marginLeft:'-266px'
    },
    holidaydateinput:{
      position:'relative',
      top:'15%',
      width:"auto",
      textAlign:'left',
      fontSize:'150%',
      borderLeft:'none',
      borderTop:'none',
      borderRight:'none'
    },
    holidaygrid:{
      textAlign:'right',
      paddingRight:'24px'
    },
    bottomcard:{
      width: '60%', 
      margin: '2vh auto',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      }
    },
    bottombuttongrid:{
      textAlign: "right",
       marginRight: "5vh", 
       [theme.breakpoints.down('sm')]: {
        marginRight: "3vh"
       }
  },
  createthispromocodefor:{
    boxShadow: '0px 0px 0px -1px rgb(0 0 0 / 20%), 0px 0px 5px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.down('xl')]: {
      width:'25%'
      },
      [theme.breakpoints.down('lg')]: {
        width:'35%'
        },
        [theme.breakpoints.down('md')]: {
          width:'45%'
          },
          [theme.breakpoints.down('sm')]: {
            width:'50%'
            }
  },
  promocodeusability:{
    textAlign:'left',
    marginTop:'-12px !important',
    [theme.breakpoints.down('md')]: {
      marginTop:'-12px !important',
      }
  },
promocodename:{
  [theme.breakpoints.down('xl')]: {
  textAlign:'left',
  minWidth:'30% !important'
  },
  [theme.breakpoints.down('lg')]: {
    textAlign:'left',
    minWidth:'45% !important'
    },
    [theme.breakpoints.down('md')]: {
      textAlign:'left',
      minWidth:'55% !important'
      },
      [theme.breakpoints.down('sm')]: {
        textAlign:'left',
        minWidth:'74% !important'
        }
},
centmanuitem:{
  [theme.breakpoints.down('sm')]: {
width:'116% !important'
  }
},
aligncostbyportion:{
width:'111% !important'
},
CardHeaderpromocode:{
   paddingBottom:'8vh !important'
},
imageviewallmenuitem:{
  marginLeft:'10%',
  [theme.breakpoints.down('sm')]: {
    marginLeft:'0%'
     }
},
needhelpgrid:{
  [theme.breakpoints.down('sm')]: {
    marginLeft:'-39px !important'
     }
  
},
alignpromodetails:{
  [theme.breakpoints.down('sm')]: {
    fontSize:'15px !important'
     }
},
buttonneedhelpmenuitem:{
  border:'none',
  position: 'relative',
  top: '25%', 
  transform: 'translateY(-8px)',
  [theme.breakpoints.down('sm')]: {
    fontSize:'smaller !important',
    
    }
},
fivemainmeniitems:{
  textAlign:'left',
  paddingLeft:'15px',
  marginTop:'auto !important',
  marginBottom:'auto !important'
},
    gridcheckorradio:{
      textAlign:'left',
      marginTop:'-15px !important'
    },
    gridnonboldheading:{
      paddingBottom: '4px',
    },
    grid:{
      paddingBottom: '15px',
    },
    boldHeadingnomargin:{
      textAlign:'left !important',
      marginLeft:'-26px !important',
      //marginTop:'5vh !important',
      fontWeight:'bold !important',
      paddingBottom:'20px'
    },
  noboldHeading:{
    textAlign:'center'
  }
}))


export {globalclasses};
/* .button{
    background: #feb300;
    border: none;
    color: #000;
    font-weight: bold;
    margin-top: 1vh;
    width: auto;
    margin-bottom: 3vh;
  }
  
 @media (max-width: 768px) {
    .emptygrid { display: none; }
  }
 
  
     }   */

     