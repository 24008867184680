import ReviewSlider1 from "./ReviewSlider1"
import './bootstrap.min.css'
import './download.css'
import { useNavigate } from 'react-router-dom';



export default function Reviews1_1() {

    const navigate = useNavigate();

    const handleButtonClick = () => {
       
        navigate('/contact-us') 
      };


    return (
        <>
            <section id="reviews-1 page font--jakarta" className="pt-100  pb-100 shape--gr-whitesmoke reviews-section" >
                <div className="container">
                    {/* SECTION TITLE */}
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-70">
                                {/* Title */}
                                <h2 className="s-35 w-700 ">Here's What Our Amazing Customers Are Saying!</h2>
                                {/* Text */}
                                {/* <p className="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p> */}
                            </div>
                        </div>
                    </div>
                    {/* TESTIMONIALS CONTENT */}
                    <div className="row" >
                        <div className="col">
                            <ReviewSlider1 />
                        </div>
                    </div> {/* END TESTIMONIALS CONTENT */}
                </div> {/* End container */}
                <div className="" style={{textAlign:'center'}}>
                <a href={`${process.env.PUBLIC_URL}/contact-us`} style={{ textDecoration: 'none' }} ><button id="BBColor"  style={{borderRadius:'12px'}} className="btn--theme hover--tra-grey">Share Your Stories With Us</button>
                </a>
                </div>
            </section>
        </>
    )
}
