// // import React from 'react';
// // import SwiperCore from 'swiper/core';
// // import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// // import 'swiper/css/bundle';
// // import 'swiper/css';
// // import 'swiper/css/effect-creative';


// // // import required modules
// // import { EffectCreative } from 'swiper/modules';

// // import { Swiper, SwiperSlide } from 'swiper/react';
// // // import 'swiper/swiper.scss';
// // // import 'swiper/components/pagination/pagination.scss';

// // // Install Swiper modules
// // // SwiperCore.use([Autoplay, Pagination]);


// // import a1 from './Images/a1.jpeg';
// // import a2 from './Images/a2.jpeg';
// // import a3 from './Images/a3.jpeg';
// // import a4 from './Images/a4.jpeg';
// // import a5 from './Images/a5.jpeg';
// // import a6 from './Images/a6.jpeg';
// // import a7 from './Images/a7.jpeg';
// // import a8 from './Images/a8.jpeg';
// // import a9 from './Images/a9.jpeg';
// // import a10 from './Images/a10.jpeg';
// // import a11a from './Images/11_a.jpg';
// // import a11b from './Images/11_b.jpg';
// // import a12 from './Images/a12.jpeg';
// // import a13 from './Images/a13.jpeg';
// // import a14 from './Images/14.jpg';
// // import a15 from './Images/a15.jpeg';
// // import a16 from './Images/a16.jpeg';
// // import a17 from './Images/a17.jpeg';
// // import a18 from './Images/a18.jpeg';
// // // import a19 from './Images/a19.jpeg';
// // import { Image } from 'react-bootstrap';
// // import TV from './Images/TvFinal.png';

// // const Slider = () => {

// //     const imageSources = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11a, a11b, a12, a13, a14, a15, a16, a17,a18];


// //     const tvStyle = {
// //         position: 'relative',
// //         display: 'inline-block',
// //         overflow: 'hidden',
// //         backgroundColor:'#F4F4F4',
// //         // height: '100%',
// //         // maxWidth: '100%',
// //         border: '20px solid black',
// //         borderRadius: '12px', 
// //         boxShadow: '5px 5px 10px 5px rgba(0, 0, 0, 0.5)', 

// //       };

// //       const bottomBorderStyle = {
// //         position: 'absolute',
// //         bottom: '-20px', 
// //         left: '0',
// //         right: '0',
// //         height: '50px',
// //         width:'120%',
// //         backgroundColor: 'black',
// //         display: 'flex',
// //         justifyContent: 'center',
// //         alignItems: 'center',
// //         color: 'white',
// //         fontWeight: 'bold',
// //       };

// //       const imageWrapperStyle = {
// //         maxWidth: '100%',
// //         height: '100%',
// //         // objectFit: 'contain',
// //       };

// //       const imageStyle = {
// //         width: '100%',
// //         height: '100%',
// //         // padding:'20px 4px',
// //         objectFit: 'fill',
// //         filter: 'grayscale(0.1) brightness(0.9) contrast(1.2) blur(0.5px)', 

// //       };

// //       const noiseOverlayStyle = {
// //         position: 'absolute',
// //         top: 0,
// //         left: 0,
// //         width: '100%',
// //         height: '100%',
// //         opacity:'0.1',
// //         // backgroundImage: 'url("https://img.freepik.com/premium-vector/seamless-stippled-texture-noise-repeated-pattern-dots-grain-repeating-background-particles-drops_497399-737.jpg")',
// //         backgroundImage: 'url("https://upload.wikimedia.org/wikipedia/commons/9/9a/512x512_Dissolve_Noise_Texture.png")',
// //         pointerEvents: 'none', 
// //       };

// //       const sliderContainerStyle = {
// //         position: 'relative',
// //         width: '100%',
// //         height: '100vh',
// //         display: 'flex',
// //         padding:'100px',
// //         alignItems: 'center',  // Add this line for vertical centering
// //         justifyContent: 'center', // Add this line for horizontal centering

// //       };

// //       const tvImageStyle = {
// //         position:'absolute',
// //         width: '54%',
// //         height:'73%',
// //         left:'7rem',
// //         zIndex:0,
// //         marginTop:'11rem',
// //         objectFit: 'contain',
// //       };

// //   return (

// //     <div style={sliderContainerStyle}>
// //     {/* <div
// //       style={{
// //         position: 'absolute',
// //         zIndex: '12',
// //         top: '0',
// //         left: '0',
// //         width: '100%',
// //         height: '100%',
// //         overflow: 'hidden',
// //       }}
// //     >
// //       <img src={TV} alt="" style={{ width: '100%', objectFit: 'fill' }} />
// //     </div> */}
// //       <img src={TV} alt="" style={{ height:'100%',width:'100%', position:'absolute', zIndex:'99999' }}  />

// //     <Swiper
// //       spaceBetween={0}
// //       grabCursor={true}
// //       slidesPerView={1}
// //       autoplay={{ delay: 5000 }}
// //     //   pagination={{ clickable: true }}
// //       loop={true}
// //       className="mySwiper"
// //       style={tvImageStyle}
// //     //   navigation={true}
// //       creativeEffect={{
// //         prev: {
// //           shadow: true,
// //           translate: [0, 0, -400],
// //         },
// //         next: {
// //           translate: ['100%', 0, 0],
// //         },
// //       }}
// //       modules={[Navigation, Pagination, Scrollbar, A11y,EffectCreative,Autoplay]}

// //     >

// //       {imageSources.map((image, index) => (
// //         <>

// //         <SwiperSlide key={index}>
// //         {/* <div style={imageWrapperStyle}> */}
// //         <div  key={index} >

// //             <Image   src={image} style={imageStyle} />
// //                 {/* <div style={noiseOverlayStyle}></div> */}
// // {/* 
// //             <div style={bottomBorderStyle}>
// //             </div> */}
// //           </div>


// //         </SwiperSlide>
// //             {/* <span style={{position:'absolute',bottom:'0',left:'0',fontFamily:'monospace', color:'white',fontWeight:'600',zIndex:'10'}}>Sony</span> */}

// //         </>
// //       ))}
// //     </Swiper>

// //     </div>

// //   );
// // };

// // export default Slider;




// import React, { useState } from 'react';
// import SwiperCore from 'swiper/core';
// import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// import 'swiper/css/bundle';
// import 'swiper/css';
// import 'swiper/css/effect-creative';
// import './Slider.css'

// // import required modules
// import { EffectCreative } from 'swiper/modules';

// import { Swiper, SwiperSlide } from 'swiper/react';
// // import 'swiper/swiper.scss';
// // import 'swiper/components/pagination/pagination.scss';

// // Install Swiper modules
// // SwiperCore.use([Autoplay, Pagination]);


// import a1 from './Images/a1.jpeg';
// import a2 from './Images/a2.jpeg';
// import a3 from './Images/a3.jpeg';
// import a4 from './Images/a4.jpeg';
// import a5 from './Images/a5.jpeg';
// import a6 from './Images/a6.jpeg';
// import a7 from './Images/a7.jpeg';
// import a8 from './Images/a8.jpeg';
// import a9 from './Images/a9.jpeg';
// import a10 from './Images/a10.jpeg';
// import a11a from './Images/11_a.jpg';
// import a11b from './Images/11_b.jpg';
// import a12 from './Images/a12.jpeg';
// import a13 from './Images/a13.jpeg';
// import a14 from './Images/14.jpg';
// import a15 from './Images/a15.jpeg';
// import a16 from './Images/a16.jpeg';
// import a17 from './Images/a17.jpeg';
// import a18 from './Images/a18.jpeg';
// // import a19 from './Images/a19.jpeg';
// import { Image } from 'react-bootstrap';
// import TV from './Images/TvFinal.png';
// import { useEffect } from 'react';


// // Install Swiper modules
// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);




// const bottomBorderStyle = {
//           position: 'absolute',
//           bottom: '-20px', 
//           left: '0',
//           right: '0',
//           height: '50px',
//           width:'120%',
//           backgroundColor: 'black',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           color: 'white',
//           fontWeight: 'bold',
//         };


// const ipad_frame = {
//   border: '20px solid black',
//   borderRadius: '20px', // Rounded corners
//   width: '100%', // Width of an iPad in portrait mode
//   // height: '100%', // Height of an iPad in portrait mode
//   boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // Shadow for a realistic look
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//  };




// const Slider = () => {
// const imageSources = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11a, a11b, a12, a13, a14, a15, a16, a17,a18];


//  // Responsive breakpoints for Swiper
//  const breakpoints = {
//     640: {
//       slidesPerView: 1,
//       spaceBetween: 20,
//     },
//     768: {
//       slidesPerView: 1,
//       spaceBetween: 40,
//     },
//     1024: {
//       slidesPerView: 1,
//       spaceBetween: 50,
//     },
//  };

//  const autoplayDelays = [
//   7000, 4000, 2000, 1000, 4000, 2000, 2000, 5000, 5000, 7000, 7000, 10000, 1000, 4000, 2000, 4000, 2000, 3000, 9000
// ];



//  // JSX structure remains the same




// const tvImageStyle = {
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: '100%',
//   zIndex: 2, // Lower z-index to ensure it's behind the slider

// };

// const sliderContainerStyle = {
//   position: 'relative',
//   width: '100%',
//   height: '100vh',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   overflow: 'hidden',
// };

// const tvImageStyle = {
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: '100%',
//   zIndex: 99999999999999, // Higher z-index to ensure it's on top of the slider
//   backgroundImage: `url(${TV})`,
//   backgroundSize:'',
//   backgroundPosition: 'center',
//   overflow:'visible'
// };

// const sliderStyle = {
//   top:'10px',
//   left:'-70px',
//   width: '73%', // Adjust the width as needed
//   height: '60%', // Adjusted height to be half of the current size
//   position: 'relative',
//   zIndex: 1, // Lower z-index to ensure it's behind the TV image
// };


//  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Assuming 768px as the breakpoint for mobile devices

//  useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//  }, []);

//  return (
//     // <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
//     //   {/* <img src={TV} alt="TV Frame" style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', zIndex: 1 }} /> */}
//     //   <Swiper
//     //   style={{position: 'absolute',width: '50%', height: '50%'}}
//     //     spaceBetween={20}
//     //     slidesPerView={1}
//     //     navigation

//     //     // pagination={{ clickable: true }}
//     //     scrollbar={{ draggable: true }}
//     //     onSwiper={(swiper) => console.log(swiper)}
//     //     onSlideChange={() => console.log('slide change')}
//     //     breakpoints={breakpoints}
//     //   >
//     //     {imageSources.map((image, index) => (
//     //       <SwiperSlide key={index}>
//     //         <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'contain' ,alignContent:'center',justifyContent:'center'}} />
//     //       </SwiperSlide>
//     //     ))}
//     //   </Swiper>
//     // </div>




//   //   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:'70px 0' }}>
//   //   <div style={ipad_frame}>
//   //   <Swiper
//   //     spaceBetween={20}
//   //     slidesPerView={1}
//   //     // style={{border:'10px solid black'}}
//   //     // navigation
//   //     // pagination={{ clickable: true }}
//   //     scrollbar={{ draggable: true }}
//   //     onSwiper={(swiper) => console.log(swiper)}
//   //     onSlideChange={() => console.log('slide change')}
//   //     breakpoints={breakpoints}
//   //   >
//   //     {imageSources.map((image, index) => (
//   //       <SwiperSlide key={index}>
//   //         <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
//   //       </SwiperSlide>
//   //     ))}
//   //   </Swiper>
//   // </div>
//   // </div>


// <div>
//       {!isMobile ? (
//  <div style={sliderContainerStyle}>
//       <div style={tvImageStyle} />
//       <Swiper
//         style={sliderStyle}
//         spaceBetween={20}
//         slidesPerView={1}
//         autoplay={{
//           delay: 5000,
//           disableOnInteraction: false,
//         }}
//         // navigation
//         // pagination={{ clickable: true }}
//         scrollbar={{ draggable: true }}
//       >
//         {imageSources.map((image, index) => (
//           <SwiperSlide key={index}>
//             <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>

// ): (
//   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:'20px 0' }}>
//   <div style={ipad_frame}>
//   <Swiper
//     spaceBetween={20}
//     slidesPerView={1}
//     // style={{border:'10px solid black'}}
//     // navigation
//     // pagination={{ clickable: true }}
//     autoplay={{
//       delay: 5000,
//       disableOnInteraction: false,
//     }}
//     scrollbar={{ draggable: true }}
//     onSwiper={(swiper) => console.log(swiper)}
//     onSlideChange={() => console.log('slide change')}
//     breakpoints={breakpoints}
//   >
//     {imageSources.map((image, index) => (
//       <SwiperSlide key={index}>
//         <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
//       </SwiperSlide>
//     ))}
//   </Swiper>
// </div>
// </div>
// ) }
// </div>


//  );
// };

// export default Slider;


import { React, useState, useEffect, useRef } from 'react';
import SwiperCore from 'swiper/core';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/effect-creative';


// import required modules
import { EffectCreative } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.scss';
// import 'swiper/components/pagination/pagination.scss';

// Install Swiper modules
// SwiperCore.use([Autoplay, Pagination]);


import a1 from './Images/a1.jpeg';
import a2 from './Images/a2.jpeg';
import a3 from './Images/a3.jpeg';
import a4 from './Images/a4.jpeg';
import a5 from './Images/a5.jpeg';
import a6 from './Images/a6.jpeg';
import a7 from './Images/a7.jpeg';
import a8 from './Images/a8.jpeg';
import a9 from './Images/a9.jpeg';
import a10 from './Images/a10.jpeg';
import a11a from './Images/11_a.jpg';
import a11b from './Images/11_b.jpg';
import a12 from './Images/a12.jpeg';
import a13 from './Images/a13.jpeg';
import a14 from './Images/14.jpg';
import a15 from './Images/a15.jpeg';
import a16 from './Images/a16.jpeg';
import a17 from './Images/a17.jpeg';
import a18 from './Images/a18.jpeg';
import { Image } from 'react-bootstrap';
import TV from './Images/TvLatest.png';

const Slider = () => {

  const imageSources = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11a, a11b, a12, a13, a14, a15, a16, a17,a18];



  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Assuming 768px as the breakpoint for mobile devices

  const swiperRef = useRef(null);

  useEffect(() => {

    const swiper = swiperRef.current.swiper;
    if (swiper) {
      // Now you can safely access swiper instance
      // For example, to stop autoplay on the last slide
      swiper.on('slideChange', () => {
        if (swiper.activeIndex === swiper.slides.length - 1) {
          swiper.autoplay.stop();
        }
      });
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

   

  }, []);

  


  // const handleSlideChange = () => {
  //    const swiper = swiperRef.current.swiper;
  //    if (swiper.activeIndex === swiper.slides.length - 1) {
  //      swiper.autoplay.stop();
  //    }
  // };



  const tvStyle = {
    position: 'relative',
    display: 'inline-block',
    overflow: 'hidden',
    backgroundColor: '#F4F4F4',
    // height: '100%',
    // maxWidth: '100%',
    border: '10px solid black',
    borderRadius: '10px',
    boxShadow: '5px 5px 10px 5px rgba(0, 0, 0, 0.5)',

  };

  const bottomBorderStyle = {
    position: 'absolute',
    bottom: '-20px',
    left: '0',
    right: '0',
    height: '50px',
    width: '120%',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
  };

  const imageWrapperStyle = {
    maxWidth: '100%',
    height: '100%',
    // objectFit: 'contain',
  };


  const swiperContainerStyle = {
    width: '355px', // Adjust based on your TV image dimensions
    maxheight: '330px', // Adjust based on your TV image dimensions
    left: -5,
    top: 155
  };

  const swiperSlideStyle = {
    width: '100%',
    height: '100%',
  };

  const swiperSlideImgStyle = {
    // width: '100%',
    // height: '100%',
    // backgroundColor: 'black',
    // border: '25px solid black',
    objectFit: 'cover', // Ensures images cover the slide area without distortion
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensures images cover the slide area without distortion
    position: 'relative',
    zIndex: '11',
    filter: 'grayscale(0.5) brightness(0.9) contrast(1.2) blur(0.5px)',
  };


  const noiseOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '90%',
    opacity: '0.2',
    // backgroundImage: 'url("https://img.freepik.com/premium-vector/seamless-stippled-texture-noise-repeated-pattern-dots-grain-repeating-background-particles-drops_497399-737.jpg")',
    // backgroundImage: 'url("https://upload.wikimedia.org/wikipedia/commons/9/9a/512x512_Dissolve_Noise_Texture.png")',
    pointerEvents: 'none',
  };


  //Mobile view Css
  const sliderContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const tvImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 99999999999999, // Higher z-index to ensure it's on top of the slider
    backgroundImage: `url(${TV})`,
    backgroundSize: '',
    backgroundPosition: 'center',
    overflow: 'visible'
  };

  const sliderStyle = {
    top: '10px',
    left: '-70px',
    width: '73%', // Adjust the width as needed
    height: '60%', // Adjusted height to be half of the current size
    position: 'relative',
    zIndex: 1, // Lower z-index to ensure it's behind the TV image
  };


  const ipad_frame = {
    border: '20px solid black',
    borderRadius: '20px', // Rounded corners
    width: '100%', // Width of an iPad in portrait mode
    // height: '100%', // Height of an iPad in portrait mode
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // Shadow for a realistic look
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };


  const breakpoints = {
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 1,
      spaceBetween: 50,
    },
  };


  return (

    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>

      {isMobile ? (
        <div className="mTop30" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px',marginTop:'60px',paddingLeft:'40px' }}>
          <div style={ipad_frame}>
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              // style={{border:'10px solid black'}}
              // navigation
              // pagination={{ clickable: true }}
        

              autoplay={{ delay: 5000 }}
              //   pagination={{ clickable: true }}
              loop={false}
          
              ref={swiperRef}
              modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative, Autoplay]}

              
              scrollbar={{ draggable: false }}
              onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={handleSlideChange}
              breakpoints={breakpoints}
              
            >
              {imageSources.map((image, index) => (
                <SwiperSlide key={index}>
                  <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

      ) : (
        <>      <div
          style={{
            position: 'absolute',
            zIndex: '12',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
        >
          <img src={TV} alt="" id="TVResize" style={{ width: '80%', height: '80%', position: 'absolute', top: '-50px', left: '15%' }} />
        </div>

          <Swiper
            spaceBetween={0}
            grabCursor={true}
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
            loop={false}
            className="mySwiper swiperContainerStyle"
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ['100%', 0, 0],
              },

            }}
            ref={swiperRef}

            modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative, Autoplay]}
            style={swiperContainerStyle}
            

          >

            {imageSources.map((image, index) => (
              <>

                <SwiperSlide key={index} className='swiperSlideStyle' style={swiperSlideStyle}>
                  {/* <div style={imageWrapperStyle}> */}
                  <div >

                    <Image src={image} style={swiperSlideImgStyle} />
                    {/* <div style={noiseOverlayStyle}></div> */}
                    {/* 
            <div style={bottomBorderStyle}>
            </div> */}
                  </div>


                </SwiperSlide>
                {/* <span style={{position:'absolute',bottom:'0',left:'0',fontFamily:'monospace', color:'white',fontWeight:'600',zIndex:'10'}}>Sony</span> */}

              </>
            ))}
          </Swiper>
        </>

      )}

    </div>


  );
};

export default Slider;


