import React from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

var Username=localStorage.getItem('email');
var Password=localStorage.getItem('password');
var phoneno=localStorage.getItem('phone');
var AWS=require("aws-sdk");

var authenticationData = {
    Username,
    Password
};


var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
);


var poolData = {
    UserPoolId: 'us-west-2_4APHLLPrQ',
    ClientId: 'dd5b3ddo18n1v8m3jamsc76am'
};

var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

var userData = {
    Username,
    Pool: userPool,
};

var t_username=localStorage.getItem('email');
if(t_username != null){
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: t_username,
        Pool: userPool
    });
}

export {cognitoUser};
export {authenticationDetails};
export {authenticationData};
export {poolData};
export {userPool};
export {userData};
export {Username};
export {Password};
export {phoneno};
export {AWS};