import { Col } from 'react-bootstrap';
import './bootstrap.min.css'
import { useNavigate } from 'react-router-dom';


export default function Ct03_2() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // Navigate to the 'res' route (RestOwner page)
        navigate('/restaurant-owner');
      };

      const handleButtonClickSignup = () => {
        // Navigate to the 'res' route (RestOwner page)
        navigate('/sign-up');
      };

    return (
        <>
            <section className="pt-100 pb-100 ct-03 content-section division page font--jakarta">
    <div className="container">
        <div className="row d-flex align-items-center" style={{color:'black'}}>
            {/* TEXT BLOCK */}
            <Col xs={{ span: 12, order: 2 }} sm={{ order: 2, span:12 }} md={{ order: 2 , span:5}} lg={{ order: 1, span:5 }} xl={{ order: 1, span:5 }}>
                <div className="txt-block right-column wow fadeInLeft">
                    {/* Section ID */}
                    <span className="section-id">Restaurant Owner</span>
                    {/* Title */}
                    <h2 className="s-35 w-700" style={{color:'black'}}>Small Monthly Subscriptions Plans</h2>
                    {/* List */}
                    <ul className="simple-list">
                        <li className="list-item">
                            <p className="mb-0">Maximize your profits without compromising on budget-friendly menu prices. We never take a cut out of your check.</p>
                        </li>
                        <li className="list-item">
                            <p className="mb-0">Digital ordering guarantees accuracy, saving valuable time and money that would otherwise be spent rectifying incorrect orders.</p>
                        </li>
                        <li className="list-item">
                            <p className="mb-0">At last, a comprehensive solution for small restaurants, offering access to tools that were previously exclusive to large chain restaurants with custom apps.</p>
                        </li>
                    </ul>
                    <a id="signupButton" href={`${process.env.PUBLIC_URL}/sign-up`}> <button id='BColor' style={{borderRadius:'12px'}}>60 Days Free. Sign Up Now!!</button>
                    </a>
                    <button id='BBColor' style={{borderRadius:'12px',marginTop:'10px',marginRight:'-18px'}} onClick={handleButtonClick}>Know More</button>
                </div>
            </Col>
            {/* IMAGE BLOCK */}
            <Col xs={{ span: 12, order: 1 }} sm={{ order: 1, span:12 }} md={{ order: 1 , span:5}} lg={{ order: 2, span:5 }} xl={{ order: 2, span:5 }}>
                <div className="img-block left-column wow fadeInRight">
                    <img className="img-fluid" src="/img/test1/resFinal.png" alt="content-image" />
                </div>
            </Col>
        </div>
    </div>
</section>
        </>
    )
}
